import {
	SET_USER,
	SET_LIKES,
	SET_APPROVED,
	POST_APPROVED,
	DELETE_APPROVED,
	SET_AUTHENTICATED,
	SET_UNAUTHENTICATED,
	LOADING_USER,
	LIKE_NOTE,
	UNLIKE_NOTE,
	MARK_NOTIFICATIONS_READ
} from '../types';

const initialState = {
	authenticated: false,
	loading: false,
	credentials: {},
	likes: [],
	notifications: [],
	approved: []
};

export default function (state = initialState, action){
	switch (action.type) {
		case SET_AUTHENTICATED:
			return {
				...state,
				authenticated: true
			};
		case SET_UNAUTHENTICATED:
			return initialState;
		case SET_USER:
			return {
				...state,
				authenticated: true,
				loading: false,
				...action.payload
			};
		case SET_LIKES:
			return {
				...state,
				likes: action.payload,
				loading: false
			};
		case SET_APPROVED:
			return {
				...state,
				approved: action.payload,
				loading: false
			};
		case POST_APPROVED:
			return {
				...state,
				approved: [
					{
						approvedEmail: action.payload.approvedEmail,
						approvedHandle: action.payload.approvedHandle,
						approvedImageUrl: action.payload.approvedImageUrl,
						approverEmail: action.payload.approverEmail,
						approverHandle: action.payload.approverHandle,
						approverImageUrl: action.payload.approverImageUrl
					},
					...state.notes
				]
			};
		case DELETE_APPROVED:
			let deleteIndex = state.notes.findIndex((approved) => approved.approvalId === action.payload);
			state.approved.splice(deleteIndex, 1);
			return {
				...state
			};
		case LOADING_USER:
			return {
				...state,
				loading: true
			};
		case LIKE_NOTE:
			return {
				...state,
				likes: [
					...state.likes,
					{
						userHandle: state.credentials.handle,
						noteId: action.payload.noteId
					}
				]
			};
		case UNLIKE_NOTE:
			return {
				...state,
				likes: state.likes.filter((like) => like.noteId !== action.payload.noteId)
			};
		case MARK_NOTIFICATIONS_READ:
			state.notifications.forEach((notification) => (notification.read = true));
			return {
				...state
			};
		default:
			return state;
	}
}
