import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import NoImg from '../images/no-img.jpg';

// import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
// import LocationOn from '@material-ui/icons/LocationOn';
// import LinkIcon from '@material-ui/icons/Link';
// import CalendarToday from '@material-ui/icons/CalendarToday';

const styles = (theme) => ({
	...theme.spreadThis,
	handle: {
		height: 20,
		width: '60%',
		backgroundColor: 'rgba(0,0,0,0.1)',
		margin: '20px auto 30px auto'
	},
	fullLine: {
		height: 8,
		width: '100%',
		backgroundColor: 'rgba(0,0,0,0.4)',
		margin: '0 auto 5px auto'
	},
	halfLine: {
		height: 10,
		width: '50%',
		backgroundColor: 'rgba(0,0,0,0.4)',
		margin: '0 auto 15px auto'
	},
	profileSkeleton: {
		'& .image-wrapper': {
			textAlign: 'center',
			position: 'relative',
			'& button': {
				position: 'absolute',
				top: '80%',
				left: '70%'
			}
		},
		'& .profile-image': {
			width: 200,
			height: 200,
			objectFit: 'cover',
			maxWidth: '100%',
			borderRadius: '50%',
			border: '3px solid #eeeeee'
		},
		'& .profile-handle': {
			color: 'rgba(0,0,0,0.5)',
			fontSize: '2.2rem',
			fontWeight: '200',
			letterSpacing: '-.05em'
		},
		'& .profile-details': {
			textAlign: 'center',
			width: '100%',
			'& span, svg': {
				verticalAlign: 'middle'
			}
		},
		'& hr': {
			border: 'none',
			margin: '0 0 10px 0'
		},
		'& svg.button': {
			'&:hover': {
				cursor: 'pointer'
			}
		}
	}
});

const ProfileSkeleton = (props) => {
	const { classes } = props;
	return (
		// <Paper className={classes.paper}>
		<div className={classes.profileSkeleton} style={{ margin: '58px auto 40px auto' }}>
			<div className="image-wrapper">
				<img src={NoImg} alt="profile" className="profile-image" />
			</div>
			<div className="profile-details">
				<div className={classes.handle} />
			</div>
		</div>
		// </Paper>
	);
};

ProfileSkeleton.propTypes = {
	classes: PropTypes.object.isRequired
};
export default withStyles(styles)(ProfileSkeleton);
