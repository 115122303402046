import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MyButton from '../../util/MyButton';
import { deleteNote, getMyNotes } from '../../redux/actions/dataActions';

import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
	...theme.spreadThis,
	deleteButton: {
		position: 'absolute',
		bottom: '0px',
		right: '-5px'
	},
	deletePrompt: {
		letterSpacing: '-.03em',
		fontSize: '0.85em',
		align: 'center'
	}
});

class DeleteNote extends Component {
	state = {
		open: false
	};
	handleOpen = () => {
		this.setState({ open: true });
	};
	handleClose = () => {
		this.setState({ open: false });
	};
	deleteNote = () => {
		this.props.deleteNote(this.props.noteId);
		this.setState({ open: false });
		this.props.getMyNotes();
	};
	render () {
		const { classes } = this.props;
		return (
			<Fragment>
				<MyButton tip="delete note" onClick={this.handleOpen} btnClassName={classes.deleteButton}>
					<DeleteOutline color="secondary" />
				</MyButton>
				<Dialog open={this.state.open} onClose={this.handleClose} fullWidth maxWidth="sm">
					<DialogTitle>
						<Typography className={classes.deletePrompt}>
							Are you sure you want to delete your note?
						</Typography>
					</DialogTitle>
					<DialogActions>
						<Button onClick={this.handleClose} color="primary">
							Cancel
						</Button>
						<Button onClick={this.deleteNote} color="secondary">
							Delete
						</Button>
					</DialogActions>
				</Dialog>
			</Fragment>
		);
	}
}

DeleteNote.propTypes = {
	deleteNote: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	noteId: PropTypes.string.isRequired,
	getMyNotes: PropTypes.func.isRequired
};

export default connect(null, { deleteNote, getMyNotes })(withStyles(styles)(DeleteNote));
