import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import BenchImage from '../../images/splashbench.png';
import FishingImage from '../../images/splashboat.png';
import VisionImage from '../../images/splashchair.png';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const splashSteps = [
	{
		header: 'start your end of life plan',
		body: 'a fast and easy way to sort out your end of life wishes',
		imgPath: BenchImage
	},
	{
		header: 'just 25 questions to answer',
		body: 'leave your loved ones prepared for when you are gone',
		imgPath: VisionImage
	},
	{
		header: `design your unique 'after'`,
		body: 'a more imaginative approach to these important questions',
		imgPath: FishingImage
	}
];

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: '100vw',
		flexGrow: 1
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		height: 50,
		paddingLeft: theme.spacing(4),
		backgroundColor: theme.palette.background.default
	},
	img: {
		// maxWidth: '100%',
		// maxHeight: 800,
		maxHeight: '500px',
		display: 'block',
		overflow: 'visible',
		margin: '20px auto 15px auto',
		[theme.breakpoints.down('xs')]: {
			width: '80%',
			margin: '0 auto 15px auto'
		}
	},
	featureHeader: {
		[theme.breakpoints.up('xs')]: {
			fontSize: '3.4em',
			letterSpacing: '-.07em'
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.4em',
			letterSpacing: '-.05em'
		}
	},
	featureBody: {
		[theme.breakpoints.up('xs')]: {
			fontSize: '1.2em',
			width: '70%',
			margin: '0px auto 20px auto',
			lineHeight: '1.2em',
			letterSpacing: '-.03em',
			color: 'rgba(0,0,0,.5)'
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '.65em',
			width: '70%',
			margin: '0px auto 10px auto',
			lineHeight: '1.2em',
			letterSpacing: '-.03em',
			color: 'rgba(0,0,0,.5)'
		}
	},
	descriptionsAndDots: {
		margin: '5px auto 15px auto'
	}
}));

function SwipeableTextMobileStepper (){
	const classes = useStyles();
	const theme = useTheme();
	const [
		activeStep,
		setActiveStep
	] = React.useState(0);
	const maxSteps = splashSteps.length;

	const handleStepChange = (step) => {
		setActiveStep(step);
	};

	return (
		// <Hidden xsDown implementation="css">
		<div className={classes.root}>
			<Grid container justify="center" className={classes.descriptionsAndDots}>
				<AutoPlaySwipeableViews
					axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={activeStep}
					onChangeIndex={handleStepChange}
					enableMouseEvents
				>
					{splashSteps.map((step, index) => (
						<div key={step.header}>
							{Math.abs(activeStep - index) <= 2 ? (
								<img className={classes.img} src={step.imgPath} alt={step.label} />
							) : null}
						</div>
					))}
				</AutoPlaySwipeableViews>
				<Grid item xs={12}>
					<Typography variant="body1" className={classes.featureHeader}>
						{splashSteps[activeStep].header}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="body1" className={classes.featureBody}>
						{splashSteps[activeStep].body}
					</Typography>
				</Grid>
				<Grid item>
					<MobileStepper
						steps={maxSteps}
						position="static"
						variant="dots"
						activeStep={activeStep}
						style={{ backgroundColor: 'rgba(0,0,0,0)', align: 'center', margin: 'auto' }}
					/>
				</Grid>
			</Grid>
		</div>
		// </Hidden>
	);
}

export default SwipeableTextMobileStepper;
