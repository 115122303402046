import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
// import axios from 'axios';
// import dayjs from 'dayjs';
import { connect } from 'react-redux';
import {
	logoutUser,
	uploadImage,
	getApprovedUsers,
	postApproved,
	deleteApproved,
	getUserData
} from '../../redux/actions/userActions';
import { getMyNotes, viewUserData, getAllUsers, selectTransition } from '../../redux/actions/dataActions';
import PropTypes from 'prop-types';
// import CircleSearch from './CircleSearch';
// import EditDetails from './EditDetails';
// import ProfileSkeleton from '../../util/ProfileSkeleton.js';
import Grid from '@material-ui/core/Grid';
import ApprovedMeCard from '../../components/note/ApprovedMeCard';
import NotecardCircle from '../../components/note/NotecardCircle';
import NotecardPersonal from '../../components/note/NotecardPersonal';
import NoteSkeleton from '../../util/NoteSkeleton.js';
// import MyButton from '../../util/MyButton';
// import Notifications from '../layout/Notifications.js';

import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
// import Card from '@material-ui/core/Card';
// import MuiLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
// import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import NoImg from '../../images/no-img.jpg';
// import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ExpansionPanel from '@material-ui/core/ExpansionPanel';
// import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
// import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import careIcon from '../../images/eol/careIcon.png';
import bodyIcon from '../../images/eol/bodyIcon.png';
import ceremonyIcon from '../../images/eol/ceremonyIcon.png';
import treasuresIcon from '../../images/eol/treasuresIcon.png';
import legacyIcon from '../../images/eol/legacyIcon.png';
// import careCover from '../../images/eol/careCover.png';
// import bodyCover from '../../images/eol/bodyCover.png';
// import ceremonyCover from '../../images/eol/ceremonyCover.png';
// import treasuresCover from '../../images/eol/treasuresCover.png';
// import legacyCover from '../../images/eol/legacyCover.png';
// import spPhoto from '../../images/eol/spphoto.jpg';

const styles = (theme) => ({
	...theme.spreadThis,
	container: {
		[theme.breakpoints.up('xs')]: {
			width: `calc(100% - ${drawerWidth}px -80px)`,
			// marginRight: drawerWidth,
			marginLeft: 440,
			// maxWidth: 1024,
			minWidth: 420
		},
		// [theme.breakpoints.down('sm')]: {
		// 	width: `calc(100% - 80px)`,
		// 	marginLeft: 80
		// },
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			marginLeft: 0,
			marginTop: 60,
			minWidth: 0
		}
	},
	containerDrawerMinimized: {
		[theme.breakpoints.up('xs')]: {
			width: `calc(100% - ${drawerWidth}px -80px)`,
			// marginRight: drawerWidth,
			marginLeft: 135
			// maxWidth: 1024
		},
		// [theme.breakpoints.down('sm')]: {
		// 	width: `calc(100% - 80px)`,
		// 	marginLeft: 80
		// },
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			marginTop: 60,
			marginLeft: 0
		}
	},
	chapterHeader: {
		margin: '10px 0 10px 20px',
		fontSize: '1.4rem'
	},
	expansionPanel: {
		backgroundColor: 'rgba(240,240,240,0.95)',
		padding: 0,
		margin: '0 20px 0 0 ',
		border: '.5px solid white'
	},
	chapterCard: {
		height: '50px',
		width: '50px',
		objectFit: 'cover',
		borderRadius: '50%',
		border: '2px solid white'
	},
	starterpackphoto: {
		width: '105%',
		// height: '280px',
		borderRadius: 5,
		margin: '50px auto 0px auto',
		boxShadow: '1px 2px 5px rgba(0,0,0,.2)',
		objectFit: 'cover'
	},
	profileSwitcher: {
		'& :hover': {
			cursor: 'pointer'
		}
	},
	title: {
		fontSize: '1.8rem',
		// color: 'white',
		textAlign: 'center',
		letterSpacing: '-0.05em',
		lineHeight: '1.1em',
		maxWidth: '100%',
		margin: '20px 0 0 0'
	},
	avatars: {
		width: 50,
		height: 50,
		objectFit: 'cover',
		borderRadius: '50%',
		border: '2px solid #ffffff',
		display: 'flex',
		margin: '10px 20px 10px 20px'
	},
	avatarsBig: {
		width: 120,
		height: 120,
		objectFit: 'cover',
		borderRadius: '50%',
		border: '3px solid #eee',
		display: 'flex',
		margin: '10px 30px 10px 30px'
	},
	drawer: {
		[theme.breakpoints.up('md')]: {
			width: drawerWidth,
			flexShrink: 0
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
			flexShrink: 0
		},
		overflowX: 'hidden'
	},
	drawerPaperClosed: {
		width: 55,
		marginLeft: 80,
		// display: 'flex',
		background: 'linear-gradient(105deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)',
		overflowX: 'hidden'
	},
	drawerPaper: {
		width: 360,
		marginLeft: 80,
		// display: 'flex',
		background: 'linear-gradient(105deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)',
		overflowX: 'hidden'
	},
	drawerPaperMobile: {
		[theme.breakpoints.up('xs')]: {
			width: `calc(100% - 80px)`,
			marginLeft: 80
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			marginTop: 100,
			marginLeft: 0
		},
		height: '100%',
		// display: 'flex',
		background: 'linear-gradient(105deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)',
		overflowX: 'hidden'
	},
	drawerResponsive: {
		[theme.breakpoints.up('xs')]: {
			width: `calc(100% - 80px)`,
			height: 40,
			marginLeft: 440
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			height: 40,
			marginLeft: 0,
			top: 60
		},
		'& :hover': {
			cursor: 'pointer'
		}
	},
	explainer: {
		[theme.breakpoints.up('xs')]: {
			fontSize: '.9em',
			margin: '0 0px 3px 0px',
			lineHeight: 1.2
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '.7em',
			margin: '0 0px 5px 0px',
			lineHeight: 1.2
		}
	},
	header: {
		fontSize: '3.4rem',
		// color: 'white',
		textAlign: 'center',
		letterSpacing: '-0.07em',
		lineHeight: '1.1em',
		maxWidth: '100%',
		margin: '120px 20px 20px 0'
	},
	textInput: {
		width: '100%',
		letterSpacing: '-0.03em',
		margin: '20px auto 0 auto',
		minWidth: '320px',
		border: 'none',
		outline: 'none',
		fontSize: '.85em'
	}
});

const drawerWidth = 360;

class Profile extends Component {
	static defaultProps = {
		chaptersEOL: [
			{ name: 'care', low: 0, high: 5, icon: careIcon },
			{ name: 'body', low: 5, high: 10, icon: bodyIcon },
			{ name: 'ceremony', low: 10, high: 15, icon: ceremonyIcon },
			{ name: 'treasures', low: 15, high: 20, icon: treasuresIcon },
			{ name: 'legacy', low: 20, high: 25, icon: legacyIcon }
		]
	};

	constructor (props) {
		super(props);
		this.state = {
			activeProfile: 'me',
			approved: [],
			approvedMe: [],
			circleEditing: false,
			inviteUser: false,
			searchBody: '',
			drawerOpen: true,
			mobileOpen: false
		};
		// this.props.getUserData();
		this.props.getMyNotes();
		this.props.getApprovedUsers();
	}

	componentDidMount () {
		// this.props.getAllUsers();
		// this.props.getUserApprovedMe();
		// this.props.getAllUsers();
		// this.props.selectTransition(this.props.match.params.transition);
	}

	componentWillReceiveProps (nextProps) {
		if (!nextProps.UI.loading) {
			this.updateApproved();
		}
	}

	updateApproved = () => {
		let updatedApproved = [];
		let updatedApprovedMe = [];
		this.props.user.approved.map((approval) => {
			if (approval.approverHandle === this.props.user.credentials.handle) {
				updatedApproved.push({
					handle: approval.approvedHandle,
					img: approval.approvedImageUrl,
					approvalId: approval.approvalId,
					email: approval.email
				});
			}
			else if (approval.approvedHandle === this.props.user.credentials.handle) {
				updatedApprovedMe.push({
					handle: approval.approverHandle,
					img: approval.approverImageUrl
				});
			}
			return null;
		});
		this.setState({ approved: updatedApproved, approvedMe: updatedApprovedMe });
	};

	handleImageChange = (event) => {
		const image = event.target.files[0];
		const formData = new FormData();
		formData.append('image', image, image.name);
		this.props.uploadImage(formData);
	};

	handleEditPicture = () => {
		const fileInput = document.getElementById('imageInput');
		fileInput.click();
	};

	handleLogout = () => {
		this.props.logoutUser();
	};

	handleEditCircle = () => {
		this.setState({ circleEditing: !this.state.circleEditing, inviteUser: false });
		this.props.getAllUsers();
	};

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	handleSubmitSearch = (event) => {
		event.preventDefault();
		let alreadyApproved = this.state.approved.some((obj) => obj.email === this.state.searchBody);
		if (alreadyApproved === true) {
			alert('This person is already in your circle');
			this.setState({ searchBody: '' });
		}
		else {
			let exists = this.props.data.allUsers.some((obj) => obj.email === this.state.searchBody);
			// let emailTemplate = `mailto:${this.state
			// 	.searchbody}?subject = An Invitation&body = Hi, I wanted to invite you to sign up for an account with 'After' so I can share with you my thoughts about my end of life wishes. Sign up just takes a minute. www.afterplanner.com. Thanks!`;
			if (exists === false) {
				this.setState({ inviteUser: true, circleEditing: false });
			}
			else {
				let email = this.state.searchBody;
				let handle = '';
				let imageUrl = '';
				for (var i = 0; i < this.props.data.allUsers.length; i++) {
					if (this.props.data.allUsers[i].email === email) {
						handle = this.props.data.allUsers[i].handle;
						imageUrl = this.props.data.allUsers[i].imageUrl;
					}
				}
				const approvedData = {
					approvedEmail: email,
					approvedHandle: handle,
					approvedImageUrl: imageUrl,
					approverEmail: this.props.user.credentials.email,
					approverHandle: this.props.user.credentials.handle,
					approverImageUrl: this.props.user.credentials.imageUrl
				};
				this.props.postApproved(approvedData);
				this.setState({ circleEditing: false });
				this.props.getApprovedUsers();
			}
		}
	};

	handleSwitchActiveProfile = (newProfile) => {
		this.props.viewUserData(newProfile);
		this.setState({ activeProfile: newProfile });
		this.props.history.push(`/eol/users/${newProfile}`);
	};

	handleDrawerToggle = () => {
		this.setState({ mobileOpen: !this.state.mobileOpen });
	};

	handleRemove = (approvalId) => {
		this.props.deleteApproved(approvalId);
		this.props.getApprovedUsers();
	};

	render () {
		const {
			classes,
			user: { credentials: { handle }, loading, authenticated },
			data: { myNotes, notes, otherProfile }
		} = this.props;

		if (
			this.props.user.approved.some((obj) => obj.approverHandle === handle || obj.approvedHandle === handle) &&
			this.state.approved === [] &&
			this.state.approvedMe === []
		) {
			this.updateApproved();
		}

		let approvedMarkup = this.props.UI.loading ? (
			<CircularProgress size={20} className={classes.progress} />
		) : (
			this.state.approved.map((a, i) => {
				return (
					<Grid item xs key={i}>
						<img src={a.img} alt={a.handle} className={classes.avatarsBig} style={{ margin: 'auto' }} />
						<Typography
							variant="body2"
							align="center"
							style={{ marginTop: '5px', color: '#000', fontSize: '1.3em' }}
						>
							{a.handle}
						</Typography>
						<Button
							onClick={() => this.handleRemove(a.approvalId)}
							variant="contained"
							className={classes.noteButton}
							style={{
								fontSize: '.85em',
								background:
									'linear-gradient(165deg,rgba(255,255,255, 1) 0%, rgba(225,225,225, 1) 100%)',
								color: '#aaa',
								letterSpacing: '-0.03em',
								borderRadius: '5px',
								border: 'none',
								margin: '20px auto 10px auto',
								padding: '2px auto 12px auto',
								width: '100%'
							}}
						>
							remove
						</Button>
					</Grid>
				);
			})
		);
		// : (
		// 	this.state.approved.map((a, i) => {
		// 		return (
		// 			<Grid justify="center" item key={i}>
		// 				<img src={a.img} alt={a.handle} className={classes.avatars} style={{ margin: 'auto' }} />
		// 				<Typography variant="body2" align="center" style={{ marginTop: '-2px', color: '#aaa' }}>
		// 					{a.handle}
		// 				</Typography>
		// 			</Grid>
		// 		);
		// 	})
		// );

		let approvedMeMarkup = this.state.approvedMe.map((a, i) => {
			if (a.handle !== this.state.activeProfile) {
				return (
					<div
						onClick={() => this.handleSwitchActiveProfile(a.handle)}
						className={classes.profileSwitcher}
						key={i}
						style={{ marginRight: 10 }}
					>
						<ApprovedMeCard className={classes.profileSwitcher} handle={a.handle} img={a.img} />
					</div>
				);
			}
			else return null;
		});

		let profileMarkup =
			!loading && !this.props.data.loading ? authenticated ? this.state.activeProfile === 'me' ? (
				<Fragment>
					<Grid container item xs spacing={1}>
						<Grid item xs={12}>
							{!this.state.approvedMe ? (
								<Typography
									className={classes.explainer}
									style={{
										color: 'rgba(0,0,0,1)',
										textAlign: 'left',
										width: '100%',
										margin: '30px 0 30px 0'
									}}
								>
									once someone includes you in their circle, their name will show up here and you will
									be able to view their wishes (including the ones that are not public)
								</Typography>
							) : (
								<Typography
									className={classes.explainer}
									style={{
										color: 'rgba(0,0,0,1)',
										textAlign: 'center',
										width: '100%',
										margin: '30px 0 30px 0'
									}}
								>
									people who've included you in their circles
								</Typography>
							)}
							{approvedMeMarkup}
						</Grid>
					</Grid>
				</Fragment>
			) : (
				<Fragment>
					<Grid container item xs>
						<div style={{ margin: '0px auto 0 auto', display: 'flex' }}>
							<Button
								onClick={() => this.handleSwitchActiveProfile('me')}
								style={{
									margin: '0 auto 0 auto',
									color: '#fff',
									letterSpacing: '.3em',
									fontSize: '.9em'
								}}
							>
								{!loading ? (
									'return to my circle'
								) : (
									<CircularProgress size={20} className={classes.progress} />
								)}
							</Button>
						</div>
						<Divider
							style={{
								color: 'rgba(0,0,0,.5)',
								width: '120%',
								position: 'absolute',
								top: 140
							}}
						/>
						<Grid item xs={12} className={classes.profileTop}>
							<div className={classes.profile}>
								<div className="image-wrapper" onClick={this.handleEditPicture}>
									<img src={otherProfile.imageUrl} alt="profile" className="profile-image" />
								</div>
								<hr />
								<div className="profile-details">
									<Typography
										component={Link}
										to={`/eol/users/${otherProfile.handle}`}
										color="primary"
										className="profile-handle"
										variant="h5"
										style={{ color: '#fff' }}
									>
										{otherProfile.handle}
									</Typography>
									<hr />
								</div>
							</div>
						</Grid>

						<Grid item xs={12}>
							{approvedMeMarkup}
						</Grid>
					</Grid>
				</Fragment>
			) : (
				<Paper className={classes.paper}>
					<Typography variant="body2" align="center">
						No profile found, please log in
					</Typography>
					<div className={classes.buttons}>
						<Button
							variant="contained"
							style={{
								background:
									'linear-gradient(165deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)'
							}}
							component={Link}
							to="/login"
						>
							Login
						</Button>
						<Button
							variant="contained"
							style={{
								background: 'linear-gradient(105deg,rgba(15, 135, 151, 1) 0%, #01508d 100%'
							}}
							component={Link}
							to="/signup"
						>
							Sign Up
						</Button>
					</div>
				</Paper>
			) : null;
		// <ProfileSkeleton />

		const circleEditMarkup = (
			<form onSubmit={this.handleSubmitSearch}>
				<TextField
					label="add people by email address..."
					className={classes.textInput}
					variant="outlined"
					name="searchBody"
					type="text"
					onChange={this.handleChange}
					value={this.state.searchBody}
					margin="dense"
				/>
				<br />
				<Button
					onClick={this.handleSubmitSearch}
					variant="contained"
					className={classes.noteButton}
					style={{
						fontSize: '.85em',
						color: '#ffffff',
						letterSpacing: '-0.03em',
						borderRadius: '5px',
						border: 'none',
						margin: '10px auto 10px auto',
						padding: '2px auto 12px auto',
						width: '100%',
						background: 'linear-gradient(165deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)'
					}}
				>
					add to my circle
				</Button>
			</form>
		);

		let wishesTitleMarkup =
			this.state.activeProfile === 'me' ? (
				<Fragment>
					<Grid item xs={12} style={{ margin: 'auto 20px auto 20px' }}>
						<Typography variant="body2" className={classes.header} style={{ margin: '80px 0 -10px 0' }}>
							my circle
						</Typography>
						<br />
						<Typography variant="body2" align="center" style={{ margin: '0px 0 50px 0' }}>
							People in your circle can see all your responses, even private ones.
							{/* <span onClick={this.handleEditCircle}>Edit your circle.</span> */}
						</Typography>
					</Grid>
					<Grid
						container
						spacing={2}
						justify="center"
						item
						xs={12}
						style={{ margin: '0 0 10px 0', width: '100%' }}
					>
						<Grid item xs={1} />
						{approvedMarkup}
						{this.props.user.loading || this.props.UI.loading ? null : (
							<Fragment>
								<Grid item xs>
									<img
										src={NoImg}
										alt="add to your circle"
										className={classes.avatarsBig}
										style={{ margin: 'auto' }}
									/>
									<Typography
										variant="body2"
										align="center"
										style={{ marginTop: '5px', color: '#aaa', fontSize: '1.3em' }}
									>
										...
									</Typography>
									<Button
										onClick={this.handleEditCircle}
										variant="contained"
										className={classes.noteButton}
										style={
											this.state.circleEditing || this.state.inviteUser ? (
												{
													fontSize: '.85em',
													background:
														'linear-gradient(165deg,rgba(255,255,255, 1) 0%, rgba(225,225,225, 1) 100%)',
													color: '#aaa',
													letterSpacing: '-0.03em',
													borderRadius: '5px',
													border: 'none',
													margin: '20px auto 10px auto',
													padding: '2px auto 12px auto',
													width: '100%'
												}
											) : (
												{
													fontSize: '.85em',
													background:
														'linear-gradient(165deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)',
													color: '#ffffff',
													letterSpacing: '-0.03em',
													borderRadius: '5px',
													border: 'none',
													margin: '20px auto 10px auto',
													padding: '2px auto 12px auto',
													width: '100%'
												}
											)
										}
									>
										add
									</Button>
								</Grid>
								<Grid item xs={1} />
							</Fragment>
						)}
						<Grid item xs={12}>
							{this.state.circleEditing ? circleEditMarkup : null}
						</Grid>
						<Grid item xs={12}>
							{this.state.inviteUser ? (
								<Fragment>
									<Typography variant="body2" align="center" style={{ margin: '20px 0 0px 0' }}>
										There is no account linked to the email address:{' '}
										<span style={{ fontWeight: 600 }}>{this.state.searchBody}</span>
									</Typography>
									<br />
									<a
										href={`mailto:${this.state
											.searchBody}?subject=An%20Invitation&body=Hi, I wanted to invite you to sign up for an account with 'After' so I can share with you my thoughts about my end of life wishes. Sign up just takes a minute. https://www.afterplanner.com. Thanks!`}
										onClick={() => this.setState({ inviteUser: false })}
									>
										<Button
											variant="contained"
											className={classes.noteButton}
											style={{
												fontSize: '.85em',
												background:
													'linear-gradient(165deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)',
												color: '#ffffff',
												letterSpacing: '-0.03em',
												borderRadius: '5px',
												border: 'none',
												margin: '5px auto 10px auto',
												padding: '2px auto 12px auto',
												width: '100%'
											}}
										>
											invite them
										</Button>
									</a>
								</Fragment>
							) : null}
						</Grid>
					</Grid>
				</Fragment>
			) : (
				<Typography variant="body2" className={classes.header} style={{ margin: '80px 0 60px 0' }}>{`${this
					.state.activeProfile}'s wishes`}</Typography>
			);

		const notesMarkup = this.props.chaptersEOL.map((chapters, i) => {
			let chapter = chapters.name;
			let low = chapters.low;
			let high = chapters.high;
			let icon = chapters.icon;

			return (
				<Fragment key={i}>
					{this.state.activeProfile === 'me' ? myNotes.some(
						(obj) => obj.linkedCardId > low && obj.linkedCardId <= high
					) ? (
						<Grid container alignItems="center" style={{ margin: '20px 0 10px 0' }}>
							{/* <Grid item xs={2}>
							<img alt={`${chapter} card`} src={imgSrc} className={classes.chapterCard} />
						</Grid> */}
							<Grid container item xs spacing={2} alignItems="center">
								<Grid item>
									<img
										src={icon}
										alt={chapter}
										style={{ height: '30px', width: '30px', transform: 'translateX(20px)' }}
									/>
								</Grid>
								<Grid item>
									<Typography variant="h5" className={classes.chapterHeader}>
										{chapter}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					) : null : notes === null ? null : notes.some(
						(obj) => obj.linkedCardId > low && obj.linkedCardId <= high
					) ? (
						<Grid container alignItems="center">
							{/* <Grid item xs={2}>
							<img alt={`${chapter} card`} src={imgSrc} className={classes.chapterCard} />
						</Grid> */}
							<Grid container item xs spacing={2} alignItems="center">
								<Grid item>
									<img
										src={icon}
										alt={chapter}
										style={{ height: '30px', width: '30px', transform: 'translateX(20px)' }}
									/>
								</Grid>
								<Grid item>
									<Typography variant="h5" className={classes.chapterHeader}>
										{chapter}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					) : null}

					<Grid container style={{ margin: '0 20px 0 20px' }}>
						{loading ? (
							<Grid item xs style={{ marginTop: 40 }}>
								<NoteSkeleton />
							</Grid>
						) : this.state.activeProfile === 'me' ? myNotes === null ? (
							<p>no notes found</p>
						) : (
							myNotes.map((note) => {
								if (note.linkedCardId > low && note.linkedCardId <= high) {
									return (
										<Grid item xs={12} key={note.noteId}>
											<NotecardPersonal
												key={note.noteId}
												note={note}
												linkedCardId={note.linkedCardId}
											/>
										</Grid>
									);
								}
								else return null;
							})
						) : this.state.activeProfile !== 'me' ? notes === null ? (
							<p>no notes found</p>
						) : (
							notes.map((note) => {
								if (note.linkedCardId > low && note.linkedCardId <= high) {
									return (
										<Grid item xs={12} key={note.noteId}>
											<NotecardCircle
												key={note.noteId}
												note={note}
												linkedCardId={note.linkedCardId}
											/>
										</Grid>
									);
								}
								else return null;
							})
						) : null}
					</Grid>
				</Fragment>
			);
		});

		let profileSelectionDrawerMarkup = (
			<nav className={classes.drawer}>
				<Hidden xsDown implementation="css">
					<Drawer
						transitionDuration={0}
						variant="persistent"
						anchor="left"
						open={this.state.drawerOpen}
						// onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaper
						}}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}
					>
						<Grid
							container
							item
							xs={12}
							spacing={1}
							justify="center"
							alignItems="flex-start"
							style={{ padding: '0' }}
						>
							<Grid container spacing={1} justify="center" style={{ margin: '0 auto 0 auto' }}>
								<Grid
									container
									item
									xs={12}
									spacing={1}
									justify="center"
									alignItems="stretch"
									style={{
										margin: '7px 0 auto 20px',
										width: '100%'
									}}
								>
									<Grid
										item
										xs={12}
										style={{
											margin: '0 0 10px 0'
										}}
									>
										<Typography
											className={classes.explainer}
											style={{
												color: 'rgba(0,0,0,.5)',
												textAlign: 'left',
												width: 280
											}}
										>
											<span style={{ fontWeight: '600', color: 'rgba(0,0,0,1)' }}>
												Instructions:{' '}
											</span>When someone adds you to their circle, you are able to see all their
											responses, even the ones that are private. Add people to your circle so they
											can support you to complete your wishes!
										</Typography>
									</Grid>
									<Divider
										style={{
											color: 'rgba(0,0,0,.5)',
											width: '120%',
											position: 'absolute',
											top: 86
										}}
									/>
									<ChevronLeftIcon
										style={{ position: 'absolute', top: 10, right: 10, color: '#fff' }}
										onClick={() => this.setState({ drawerOpen: false })}
									/>
								</Grid>
								{profileMarkup}
							</Grid>
						</Grid>
					</Drawer>
					<Drawer
						transitionDuration={0}
						variant="persistent"
						anchor="left"
						open={!this.state.drawerOpen}
						onClick={() => this.setState({ drawerOpen: true })}
						// onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaperClosed
						}}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}
					>
						<Grid
							container
							item
							xs={12}
							spacing={1}
							justify="center"
							alignItems="flex-start"
							style={{ padding: '0' }}
						>
							<Grid container spacing={1} justify="center" style={{ margin: '0 auto 0 auto' }}>
								<Grid
									container
									item
									xs={12}
									spacing={1}
									justify="center"
									alignItems="stretch"
									style={{
										margin: '10px 0 auto 20px',
										width: '100%'
									}}
								>
									<Grid
										item
										xs={12}
										style={{
											margin: '0 0 10px 0'
										}}
									/>
									<ChevronRightIcon
										color="primary"
										style={{ position: 'absolute', top: 10, right: 15 }}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Drawer>
				</Hidden>
				<Hidden smUp implementation="css">
					<AppBar
						position="fixed"
						color="primary"
						className={classes.drawerResponsive}
						onClick={this.handleDrawerToggle}
					>
						<BottomNavigation
							className={classes.root}
							style={{
								background:
									'linear-gradient(165deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)'
							}}
						>
							{!this.state.mobileOpen ? (
								<BottomNavigationAction label="CHANGE PROFILE" showLabel style={{ color: '#fff' }} />
							) : (
								<BottomNavigationAction label="BACK" showLabel style={{ color: '#fff' }} />
							)}
						</BottomNavigation>
					</AppBar>
					<Drawer
						// container={container}
						transitionDuration={0}
						variant="temporary"
						anchor="top"
						open={this.state.mobileOpen}
						onClose={this.handleDrawerToggle}
						classes={{
							paper: classes.drawerPaperMobile
						}}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}
					>
						<Grid
							container
							item
							xs={12}
							spacing={1}
							justify="center"
							alignItems="flex-start"
							style={{ padding: '0' }}
						>
							<Grid container spacing={1} justify="center" style={{ margin: '0 auto 0 auto' }}>
								<Grid
									container
									item
									xs={12}
									spacing={1}
									justify="center"
									alignItems="stretch"
									style={{
										margin: '7px 0 auto 20px',
										width: '100%'
									}}
								>
									<Grid
										item
										xs={12}
										style={{
											margin: '0 0 10px 0'
										}}
									>
										<Typography
											className={classes.explainer}
											style={{
												color: 'rgba(0,0,0,.5)',
												textAlign: 'left',
												width: 280
											}}
										>
											<span style={{ fontWeight: '600', color: 'rgba(0,0,0,1)' }}>
												Instructions:{' '}
											</span>When someone adds you to their circle, you are able to see all their
											responses, even the ones that are private. Add people to your circle so they
											can support you to complete your wishes!
										</Typography>
									</Grid>
									<Divider
										style={{
											color: 'rgba(0,0,0,.5)',
											width: '120%',
											position: 'absolute',
											top: 64
										}}
									/>
								</Grid>
								{profileMarkup}
							</Grid>
						</Grid>
					</Drawer>
				</Hidden>
			</nav>
		);

		return (
			<div className={this.state.drawerOpen ? classes.container : classes.containerDrawerMinimized}>
				{profileSelectionDrawerMarkup}
				<Grid container justify="center" spacing={2} style={{ maxWidth: '780px', margin: 'auto' }}>
					<Grid container justify="center" item xs={12}>
						{wishesTitleMarkup}
						{this.state.activeProfile === 'me' ? null : notesMarkup}
					</Grid>
					<div style={{ height: 80 }} />
				</Grid>
			</div>
		);
	}
}

const mapStatetoProps = (state) => ({
	user: state.user,
	data: state.data,
	UI: state.UI
});

const mapActionsToProps = {
	logoutUser,
	uploadImage,
	getMyNotes,
	getApprovedUsers,
	deleteApproved,
	viewUserData,
	getUserData,
	getAllUsers,
	selectTransition,
	postApproved
};

Profile.propTypes = {
	getAllUsers: PropTypes.func.isRequired,
	getUserData: PropTypes.func.isRequired,
	getApprovedUsers: PropTypes.func.isRequired,
	viewUserData: PropTypes.func.isRequired,
	selectTransition: PropTypes.func.isRequired,
	postApproved: PropTypes.func.isRequired,
	deleteApproved: PropTypes.func.isRequired,
	logoutUser: PropTypes.func.isRequired,
	getMyNotes: PropTypes.func.isRequired,
	uploadImage: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired
};

export default connect(mapStatetoProps, mapActionsToProps)(withStyles(styles)(Profile));
