import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { likeNote, unlikeNote } from '../../redux/actions/dataActions';
import MyButton from '../../util/MyButton';
import PropTypes from 'prop-types';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';

class LikeButton extends Component {
	likedNote = () => {
		if (this.props.user.likes && this.props.user.likes.find((like) => like.noteId === this.props.noteId))
			return true;
		else return false;
	};
	likeNote = () => {
		this.props.likeNote(this.props.noteId);
	};
	unlikeNote = () => {
		this.props.unlikeNote(this.props.noteId);
	};

	render () {
		const { authenticated } = this.props.user;
		const likeButton = !authenticated ? (
			<Link to="/login">
				<MyButton tip="like">
					<FavoriteBorder color="primary" fontSize="small" />
				</MyButton>
			</Link>
		) : this.likedNote() ? (
			<MyButton tip="unlike" onClick={this.unlikeNote}>
				<FavoriteIcon color="primary" fontSize="small" />
			</MyButton>
		) : (
			<MyButton tip="like" onClick={this.likeNote}>
				<FavoriteBorder color="primary" fontSize="small" />
			</MyButton>
		);
		return likeButton;
	}
}

LikeButton.propTypes = {
	user: PropTypes.object.isRequired,
	noteId: PropTypes.string.isRequired,
	likeNote: PropTypes.func.isRequired,
	unlikeNote: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	user: state.user
});

const mapActionsToProps = {
	likeNote,
	unlikeNote
};

export default connect(mapStateToProps, mapActionsToProps)(LikeButton);
