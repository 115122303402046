import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const styles = (theme) => ({
	...theme.spreadThis,
	card: {
		display: 'flex',
		marginBottom: 10,
		width: '100%',
		height: 120
	},
	cardContent: {
		width: '100%',
		flexDirection: 'column',
		padding: 10
	},
	// cover: {
	// 	minWidth: 200,
	// 	objectFit: 'cover'
	// },
	handle: {
		width: 60,
		height: 10,
		backgroundColor: 'rgba(0,0,0,0.4)',
		marginTop: 10,
		marginBottom: 10
	},
	date: {
		width: 30,
		height: 5,
		backgroundColor: 'rgba(0,0,0,0.2)',
		marginBottom: 20
	},
	fullLine: {
		width: '100%',
		height: 5,
		backgroundColor: 'rgba(0,0,0,0.4)',
		marginBottom: 8
	},
	halfLine: {
		width: '30%',
		height: 5,
		backgroundColor: 'rgba(0,0,0,0.4)'
	}
});

const NoteSkeleton = (props) => {
	const { classes } = props;
	const content = Array.from({ length: 5 }).map((item, index) => (
		<Card className={classes.card} key={index}>
			{/* <CardMedia className={classes.cover} image={NoImg} /> */}
			<CardContent className={classes.cardContent}>
				<div className={classes.handle} />
				<div className={classes.date} />
				<div className={classes.fullLine} />
				<div className={classes.fullLine} />
				<div className={classes.halfLine} />
			</CardContent>
		</Card>
	));
	return <Fragment>{content}</Fragment>;
};

NoteSkeleton.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NoteSkeleton);
