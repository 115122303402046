import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const AuthRoute = ({ component: Component, authenticated, transition, ...rest }) => (
	<Route
		{...rest}
		render={(props) => (authenticated === true ? <Redirect to={`/eol/mywishes`} /> : <Component {...props} />)}
	/>
);

const mapStateToProps = (state) => ({
	authenticated: state.user.authenticated,
	transition: state.UI.transition
});

AuthRoute.propTypes = {
	user: PropTypes.object,
	UI: PropTypes.object
};

export default connect(mapStateToProps)(AuthRoute);
