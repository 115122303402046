import React, { Component, Fragment } from 'react';
import axios from 'axios';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	getNotes,
	getMyNotes,
	postNote,
	updateNote,
	getMyInstructions,
	postInstructions,
	updateInstructions,
	clearErrors,
	selectTransition
} from '../redux/actions/dataActions';
import { getUserData } from '../redux/actions/userActions';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
// import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import MaterialTable from 'material-table';
// import NotecardPersonal from '../components/note/NotecardPersonal';

const styles = (theme) => ({
	...theme.spreadThis,
	header: {
		fontSize: '3.4rem',
		// color: 'white',
		textAlign: 'left',
		letterSpacing: '-0.07em',
		lineHeight: '1.1em',
		maxWidth: '100%',
		margin: '120px 20px 20px 0'
	},
	prompt: {
		fontSize: '1.8rem',
		// color: 'white',
		textAlign: 'left',
		letterSpacing: '-0.05em',
		lineHeight: '1.1em',
		maxWidth: '100%',
		margin: '0px 20px 20px 0'
	},
	subprompt: {
		margin: '0 20px 0px 0',
		color: '#0f8797'
	},
	textInput: {
		width: '100%',
		letterSpacing: '-0.03em',
		border: 'none',
		outline: 'none'
	},
	instructionsForm: {
		[theme.breakpoints.up('xs')]: {
			width: `calc(100% - 40px)`,
			margin: '0 20px 0 20px'
		},
		[theme.breakpoints.down('xs')]: {
			width: `calc(100% - 40px)`,
			margin: '0 20px 0 20px'
		}
	},
	saveButtonOpen: {
		[theme.breakpoints.up('xs')]: {
			width: `calc(100% - 540px)`,
			bottom: '30px',
			maxWidth: '740px',
			minWidth: '340px'
		},
		[theme.breakpoints.down('xs')]: {
			width: `calc(100% - 62px)`,
			bottom: '30px'
		}
	},
	saveButton: {
		[theme.breakpoints.up('xs')]: {
			width: `calc(100% - 236px)`,
			bottom: '30px',
			maxWidth: '740px',
			minWidth: '340px'
		},
		[theme.breakpoints.down('xs')]: {
			width: `calc(100% - 62px)`,
			bottom: '30px'
		}
	},
	radioGroup: {
		justifyContent: 'center',
		margin: '20px auto',
		color: '#aaa'
	},
	divider: {
		margin: '40px auto 40px auto'
	},
	dividerAfterAutosize: {
		margin: '0px auto 40px auto'
	}
});

export function MaterialTableDemo (props){
	const [
		state,
		setState
	] = React.useState({
		columns: [
			{ title: 'What it is', field: 'item' },
			{ title: 'Who will get it', field: 'toWhom' },
			{ title: 'My hopes for it after I’m gone', field: 'hopes' }
		],
		data: [
			{ item: '', toWhom: '', hopes: '' },
			{ item: '', toWhom: '', hopes: '' }
		]
	});

	return (
		<MaterialTable
			title=""
			columns={state.columns}
			data={state.data}
			editable={{
				onRowAdd: (newData) =>
					new Promise((resolve) => {
						setTimeout(() => {
							resolve();
							setState((prevState) => {
								const data = [
									...prevState.data
								];
								data.push(newData);
								props.updateTable(data);
								return { ...prevState, data };
							});
						}, 600);
					}),
				onRowUpdate: (newData, oldData) =>
					new Promise((resolve) => {
						setTimeout(() => {
							resolve();
							if (oldData) {
								setState((prevState) => {
									const data = [
										...prevState.data
									];
									data[data.indexOf(oldData)] = newData;
									props.updateTable(data);
									return { ...prevState, data };
								});
							}
						}, 600);
					}),
				onRowDelete: (oldData) =>
					new Promise((resolve) => {
						setTimeout(() => {
							resolve();
							setState((prevState) => {
								const data = [
									...prevState.data
								];
								data.splice(data.indexOf(oldData), 1);
								props.updateTable(data);
								return { ...prevState, data };
							});
						}, 600);
					})
			}}
		/>
	);
}

class Instructions extends Component {
	constructor (props) {
		super(props);
		this.state = {
			isEditing: false,
			existingInstructions: false,
			existingInstructionsId: null,
			items: [],
			agentFirstChoice: '',
			agentBackUp: '',
			agentBackUpBackUp: '',
			agentSupport: '',
			HCDmedicalCare: false,
			HCDlifeSustaining: false,
			HCDpainMedication: false,
			HCDinterpret: false,
			HCDhireHelp: false,
			HCDrelocate: false,
			HCDorgans: false,
			HCDmedicalRecords: false,
			HCDlegalAction: false,
			HCDbenefits: false,
			tastesNails: false,
			tastesLips: false,
			tastesFeverish: false,
			tastesBaths: false,
			tastesTeeth: false,
			tastesHair: false,
			tastesSoiled: false,
			tastesMusic: false,
			tastesCompany: false,
			tastesPictures: false,
			tastesHandholding: false,
			tastesPrayer: false,
			tastesAddMore: '',
			directivesPain: '',
			directivesResuscitate: '',
			directivesIV: '',
			directivesTerminal: '',
			directivesComa: '',
			directivesBrainDamage: '',
			directivesLastDays: '',
			directivesWithhold: '',
			guardianFirstChoice: '',
			guardianBackUp: '',
			bodySetting: '',
			bodyRemains: '',
			bodyPlaces: '',
			bodyFirstDays: '',
			bodyFirstChoice: '',
			bodyBackUp: '',
			bodyBackUpBackUp: '',
			bodyOrgans: '',
			bodySpecificOrgans: '',
			bodyAutopsy: '',
			ceremonyType: '',
			ceremonyVision: '',
			ceremonyAttendees: '',
			ceremonyStories: '',
			ceremonyTraditions: '',
			treasuresExecutorFirstChoice: '',
			treasuresExecutorBackUp: '',
			treasuresExecutorBackUpBackUp: '',
			treasuresGodparentsFirstChoice: '',
			treasuresGodparentsBackUp: '',
			treasuresGodparentsNotes: '',
			treasuresPetparentsFirstChoice: '',
			treasuresPetparentsBackUp: '',
			treasuresPetparentsNotes: '',
			treasuresLegalName: '',
			treasuresMaidenName: '',
			treasuresBirthday: '',
			treasuresSSN: '',
			treasuresLicense: '',
			treasuresPassport: '',
			treasuresHomeAddress: '',
			treasuresBirthplace: '',
			treasuresSpouse: '',
			treasuresFormerSpouse: '',
			treasuresChildren: '',
			treasuresGrandkids: '',
			treasuresPets: '',
			treasuresParent1Name: '',
			treasuresParent1Birthplace: '',
			treasuresParent2Name: '',
			treasuresParent2Birthplace: '',
			treasuresSiblings: '',
			treasuresPhysician: '',
			treasuresPhysiciansInfo: '',
			treasuresAttorney: '',
			treasuresAttorneysInfo: '',
			treasuresAccountant: '',
			treasuresAccountantsInfo: '',
			treasuresFinancialAdvisor: '',
			treasuresFinancialAdvisorsInfo: '',
			treasuresStuff: '',
			treasuresDistribution: '',
			treasuresExcluded: '',
			treasuresAssets: '',
			treasuresLiabilities: '',
			treasuresOnline: '',
			treasuresLocationLicense: '',
			treasuresLocationPassport: '',
			treasuresLocationBirthCertificate: '',
			treasuresLocationSocialSecurityCard: '',
			treasuresLocationMarriageCertificate: '',
			treasuresLocationDivorcePapers: '',
			treasuresLocationAddressBook: '',
			treasuresLocationOnlinePasswords: '',
			treasuresLocationWill: '',
			treasuresLocationAdvanceDirectives: '',
			treasuresLocationSafe: '',
			legacyLetters: '',
			legacyScholarship: ''
		};
		this.props.getMyInstructions();
	}
	componentDidMount () {
		this.updateInstructions();
	}
	async updateInstructions () {
		try {
			let myInstructionsRes = await axios.get('/myinstructions');
			console.log(myInstructionsRes);
			this.setState({
				items: myInstructionsRes.data[0].items,
				agentFirstChoice: myInstructionsRes.data[0].agentFirstChoice,
				agentBackUp: myInstructionsRes.data[0].agentBackUp,
				agentBackUpBackUp: myInstructionsRes.data[0].agentBackUpBackUp,
				agentSupport: myInstructionsRes.data[0].agentSupport,
				HCDmedicalCare: myInstructionsRes.data[0].HCDmedicalCare,
				HCDlifeSustaining: myInstructionsRes.data[0].HCDlifeSustaining,
				HCDpainMedication: myInstructionsRes.data[0].HCDpainMedication,
				HCDinterpret: myInstructionsRes.data[0].HCDinterpret,
				HCDhireHelp: myInstructionsRes.data[0].HCDhireHelp,
				HCDrelocate: myInstructionsRes.data[0].HCDrelocate,
				HCDorgans: myInstructionsRes.data[0].HCDorgans,
				HCDmedicalRecords: myInstructionsRes.data[0].HCDmedicalRecords,
				HCDlegalAction: myInstructionsRes.data[0].HCDlegalAction,
				HCDbenefits: myInstructionsRes.data[0].HCDbenefits,
				tastesNails: myInstructionsRes.data[0].tastesNails,
				tastesLips: myInstructionsRes.data[0].tastesLips,
				tastesFeverish: myInstructionsRes.data[0].tastesFeverish,
				tastesBaths: myInstructionsRes.data[0].tastesBaths,
				tastesTeeth: myInstructionsRes.data[0].tastesTeeth,
				tastesHair: myInstructionsRes.data[0].tastesHair,
				tastesSoiled: myInstructionsRes.data[0].tastesSoiled,
				tastesMusic: myInstructionsRes.data[0].tastesMusic,
				tastesCompany: myInstructionsRes.data[0].tastesCompany,
				tastesPictures: myInstructionsRes.data[0].tastesPictures,
				tastesHandholding: myInstructionsRes.data[0].tastesHandholding,
				tastesPrayer: myInstructionsRes.data[0].tastesPrayer,
				tastesAddMore: myInstructionsRes.data[0].tastesAddMore,
				directivesPain: myInstructionsRes.data[0].directivesPain,
				directivesResuscitate: myInstructionsRes.data[0].directivesResuscitate,
				directivesIV: myInstructionsRes.data[0].directivesIV,
				directivesTerminal: myInstructionsRes.data[0].directivesTerminal,
				directivesComa: myInstructionsRes.data[0].directivesComa,
				directivesBrainDamage: myInstructionsRes.data[0].directivesBrainDamage,
				directivesLastDays: myInstructionsRes.data[0].directivesLastDays,
				directivesWithhold: myInstructionsRes.data[0].directivesWithhold,
				guardianFirstChoice: myInstructionsRes.data[0].guardianFirstChoice,
				guardianBackUp: myInstructionsRes.data[0].guardianBackUp,
				bodySetting: myInstructionsRes.data[0].bodySetting,
				bodyRemains: myInstructionsRes.data[0].bodyRemains,
				bodyPlaces: myInstructionsRes.data[0].bodyPlaces,
				bodyFirstDays: myInstructionsRes.data[0].bodyFirstDays,
				bodyFirstChoice: myInstructionsRes.data[0].bodyFirstChoice,
				bodyBackUp: myInstructionsRes.data[0].bodyBackUp,
				bodyBackUpBackUp: myInstructionsRes.data[0].bodyBackUpBackUp,
				bodyOrgans: myInstructionsRes.data[0].bodyOrgans,
				bodySpecificOrgans: myInstructionsRes.data[0].bodySpecificOrgans,
				bodyAutopsy: myInstructionsRes.data[0].bodyAutopsy,
				ceremonyType: myInstructionsRes.data[0].ceremonyType,
				ceremonyVision: myInstructionsRes.data[0].ceremonyVision,
				ceremonyAttendees: myInstructionsRes.data[0].ceremonyAttendees,
				ceremonyStories: myInstructionsRes.data[0].ceremonyStories,
				ceremonyTraditions: myInstructionsRes.data[0].ceremonyTraditions,
				treasuresExecutorFirstChoice: myInstructionsRes.data[0].treasuresExecutorFirstChoice,
				treasuresExecutorBackUp: myInstructionsRes.data[0].treasuresExecutorBackUp,
				treasuresExecutorBackUpBackUp: myInstructionsRes.data[0].treasuresExecutorBackUpBackUp,
				treasuresGodparentsFirstChoice: myInstructionsRes.data[0].treasuresGodparentsFirstChoice,
				treasuresGodparentsBackUp: myInstructionsRes.data[0].treasuresGodparentsBackUp,
				treasuresGodparentsNotes: myInstructionsRes.data[0].treasuresGodparentsNotes,
				treasuresPetparentsFirstChoice: myInstructionsRes.data[0].treasuresPetparentsFirstChoice,
				treasuresPetparentsBackUp: myInstructionsRes.data[0].treasuresPetparentsBackUp,
				treasuresPetparentsNotes: myInstructionsRes.data[0].treasuresPetparentsNotes,
				treasuresLegalName: myInstructionsRes.data[0].treasuresLegalName,
				treasuresMaidenName: myInstructionsRes.data[0].treasuresMaidenName,
				treasuresBirthday: myInstructionsRes.data[0].treasuresBirthday,
				treasuresSSN: myInstructionsRes.data[0].treasuresSSN,
				treasuresLicense: myInstructionsRes.data[0].treasuresLicense,
				treasuresPassport: myInstructionsRes.data[0].treasuresPassport,
				treasuresHomeAddress: myInstructionsRes.data[0].treasuresHomeAddress,
				treasuresBirthplace: myInstructionsRes.data[0].treasuresBirthplace,
				treasuresSpouse: myInstructionsRes.data[0].treasuresSpouse,
				treasuresFormerSpouse: myInstructionsRes.data[0].treasuresFormerSpouse,
				treasuresChildren: myInstructionsRes.data[0].treasuresChildren,
				treasuresGrandkids: myInstructionsRes.data[0].treasuresGrandkids,
				treasuresPets: myInstructionsRes.data[0].treasuresPets,
				treasuresParent1Name: myInstructionsRes.data[0].treasuresParent1Name,
				treasuresParent1Birthplace: myInstructionsRes.data[0].treasuresParent1Birthplace,
				treasuresParent2Name: myInstructionsRes.data[0].treasuresParent2Name,
				treasuresParent2Birthplace: myInstructionsRes.data[0].treasuresParent2Birthplace,
				treasuresSiblings: myInstructionsRes.data[0].treasuresSiblings,
				treasuresPhysician: myInstructionsRes.data[0].treasuresPhysician,
				treasuresPhysiciansInfo: myInstructionsRes.data[0].treasuresPhysiciansInfo,
				treasuresAttorney: myInstructionsRes.data[0].treasuresAttorney,
				treasuresAttorneysInfo: myInstructionsRes.data[0].treasuresAttorneysInfo,
				treasuresAccountant: myInstructionsRes.data[0].treasuresAccountant,
				treasuresAccountantsInfo: myInstructionsRes.data[0].treasuresAccountantsInfo,
				treasuresFinancialAdvisor: myInstructionsRes.data[0].treasuresFinancialAdvisor,
				treasuresFinancialAdvisorsInfo: myInstructionsRes.data[0].treasuresFinancialAdvisorsInfo,
				treasuresStuff: myInstructionsRes.data[0].treasuresStuff,
				treasuresDistribution: myInstructionsRes.data[0].treasuresDistribution,
				treasuresExcluded: myInstructionsRes.data[0].treasuresExcluded,
				treasuresAssets: myInstructionsRes.data[0].treasuresAssets,
				treasuresLiabilities: myInstructionsRes.data[0].treasuresLiabilities,
				treasuresOnline: myInstructionsRes.data[0].treasuresOnline,
				treasuresLocationLicense: myInstructionsRes.data[0].treasuresLocationLicense,
				treasuresLocationPassport: myInstructionsRes.data[0].treasuresLocationPassport,
				treasuresLocationBirthCertificate: myInstructionsRes.data[0].treasuresLocationBirthCertificate,
				treasuresLocationSocialSecurityCard: myInstructionsRes.data[0].treasuresLocationSocialSecurityCard,
				treasuresLocationMarriageCertificate: myInstructionsRes.data[0].treasuresLocationMarriageCertificate,
				treasuresLocationDivorcePapers: myInstructionsRes.data[0].treasuresLocationDivorcePapers,
				treasuresLocationAddressBook: myInstructionsRes.data[0].treasuresLocationAddressBook,
				treasuresLocationOnlinePasswords: myInstructionsRes.data[0].treasuresLocationOnlinePasswords,
				treasuresLocationWill: myInstructionsRes.data[0].treasuresLocationWill,
				treasuresLocationAdvanceDirectives: myInstructionsRes.data[0].treasuresLocationAdvanceDirectives,
				treasuresLocationSafe: myInstructionsRes.data[0].treasuresLocationSafe,
				legacyLetters: myInstructionsRes.data[0].legacyLetters,
				legacyScholarship: myInstructionsRes.data[0].legacyScholarship
			});
		} catch (err) {
			// alert(err);
		}
	}
	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};
	handleToggle = (event) => {
		this.setState({ ...this.state, [event.target.name]: event.target.checked });
	};
	handleRadioChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};
	updateTable = (data) => {
		this.setState({ items: data });
	};
	handleSubmit = (event) => {
		event.preventDefault();
		const instructionsData = {
			items: this.state.items,
			agentFirstChoice: this.state.agentFirstChoice,
			agentBackUp: this.state.agentBackUp,
			agentBackUpBackUp: this.state.agentBackUpBackUp,
			agentSupport: this.state.agentSupport,
			HCDmedicalCare: this.state.HCDmedicalCare,
			HCDlifeSustaining: this.state.HCDlifeSustaining,
			HCDpainMedication: this.state.HCDpainMedication,
			HCDinterpret: this.state.HCDinterpret,
			HCDhireHelp: this.state.HCDhireHelp,
			HCDrelocate: this.state.HCDrelocate,
			HCDorgans: this.state.HCDorgans,
			HCDmedicalRecords: this.state.HCDmedicalRecords,
			HCDlegalAction: this.state.HCDlegalAction,
			HCDbenefits: this.state.HCDbenefits,
			tastesNails: this.state.tastesNails,
			tastesLips: this.state.tastesLips,
			tastesFeverish: this.state.tastesFeverish,
			tastesBaths: this.state.tastesBaths,
			tastesTeeth: this.state.tastesTeeth,
			tastesHair: this.state.tastesHair,
			tastesSoiled: this.state.tastesSoiled,
			tastesMusic: this.state.tastesMusic,
			tastesCompany: this.state.tastesCompany,
			tastesPictures: this.state.tastesPictures,
			tastesHandholding: this.state.tastesHandholding,
			tastesPrayer: this.state.tastesPrayer,
			tastesAddMore: this.state.tastesAddMore,
			directivesPain: this.state.directivesPain,
			directivesResuscitate: this.state.directivesResuscitate,
			directivesIV: this.state.directivesIV,
			directivesTerminal: this.state.directivesTerminal,
			directivesComa: this.state.directivesComa,
			directivesBrainDamage: this.state.directivesBrainDamage,
			directivesLastDays: this.state.directivesLastDays,
			directivesWithhold: this.state.directivesWithhold,
			guardianFirstChoice: this.state.guardianFirstChoice,
			guardianBackUp: this.state.guardianBackUp,
			bodySetting: this.state.bodySetting,
			bodyRemains: this.state.bodyRemains,
			bodyPlaces: this.state.bodyPlaces,
			bodyFirstDays: this.state.bodyFirstDays,
			bodyFirstChoice: this.state.bodyFirstChoice,
			bodyBackUp: this.state.bodyBackUp,
			bodyBackUpBackUp: this.state.bodyBackUpBackUp,
			bodyOrgans: this.state.bodyOrgans,
			bodySpecificOrgans: this.state.bodySpecificOrgans,
			bodyAutopsy: this.state.bodyAutopsy,
			ceremonyType: this.state.ceremonyType,
			ceremonyVision: this.state.ceremonyVision,
			ceremonyAttendees: this.state.ceremonyAttendees,
			ceremonyStories: this.state.ceremonyStories,
			ceremonyTraditions: this.state.ceremonyTraditions,
			treasuresExecutorFirstChoice: this.state.treasuresExecutorFirstChoice,
			treasuresExecutorBackUp: this.state.treasuresExecutorBackUp,
			treasuresExecutorBackUpBackUp: this.state.treasuresExecutorBackUpBackUp,
			treasuresGodparentsFirstChoice: this.state.treasuresGodparentsFirstChoice,
			treasuresGodparentsBackUp: this.state.treasuresGodparentsBackUp,
			treasuresGodparentsNotes: this.state.treasuresGodparentsNotes,
			treasuresPetparentsFirstChoice: this.state.treasuresPetparentsFirstChoice,
			treasuresPetparentsBackUp: this.state.treasuresPetparentsBackUp,
			treasuresPetparentsNotes: this.state.treasuresPetparentsNotes,
			treasuresLegalName: this.state.treasuresLegalName,
			treasuresMaidenName: this.state.treasuresMaidenName,
			treasuresBirthday: this.state.treasuresBirthday,
			treasuresSSN: this.state.treasuresSSN,
			treasuresLicense: this.state.treasuresLicense,
			treasuresPassport: this.state.treasuresPassport,
			treasuresHomeAddress: this.state.treasuresHomeAddress,
			treasuresBirthplace: this.state.treasuresBirthplace,
			treasuresSpouse: this.state.treasuresSpouse,
			treasuresFormerSpouse: this.state.treasuresFormerSpouse,
			treasuresChildren: this.state.treasuresChildren,
			treasuresGrandkids: this.state.treasuresGrandkids,
			treasuresPets: this.state.treasuresPets,
			treasuresParent1Name: this.state.treasuresParent1Name,
			treasuresParent1Birthplace: this.state.treasuresParent1Birthplace,
			treasuresParent2Name: this.state.treasuresParent2Name,
			treasuresParent2Birthplace: this.state.treasuresParent2Birthplace,
			treasuresSiblings: this.state.treasuresSiblings,
			treasuresPhysician: this.state.treasuresPhysician,
			treasuresPhysiciansInfo: this.state.treasuresPhysiciansInfo,
			treasuresAttorney: this.state.treasuresAttorney,
			treasuresAttorneysInfo: this.state.treasuresAttorneysInfo,
			treasuresAccountant: this.state.treasuresAccountant,
			treasuresAccountantsInfo: this.state.treasuresAccountantsInfo,
			treasuresFinancialAdvisor: this.state.treasuresFinancialAdvisor,
			treasuresFinancialAdvisorsInfo: this.state.treasuresFinancialAdvisorsInfo,
			treasuresStuff: this.state.treasuresStuff,
			treasuresDistribution: this.state.treasuresDistribution,
			treasuresExcluded: this.state.treasuresExcluded,
			treasuresAssets: this.state.treasuresAssets,
			treasuresLiabilities: this.state.treasuresLiabilities,
			treasuresOnline: this.state.treasuresOnline,
			treasuresLocationLicense: this.state.treasuresLocationLicense,
			treasuresLocationPassport: this.state.treasuresLocationPassport,
			treasuresLocationBirthCertificate: this.state.treasuresLocationBirthCertificate,
			treasuresLocationSocialSecurityCard: this.state.treasuresLocationSocialSecurityCard,
			treasuresLocationMarriageCertificate: this.state.treasuresLocationMarriageCertificate,
			treasuresLocationDivorcePapers: this.state.treasuresLocationDivorcePapers,
			treasuresLocationAddressBook: this.state.treasuresLocationAddressBook,
			treasuresLocationOnlinePasswords: this.state.treasuresLocationOnlinePasswords,
			treasuresLocationWill: this.state.treasuresLocationWill,
			treasuresLocationAdvanceDirectives: this.state.treasuresLocationAdvanceDirectives,
			treasuresLocationSafe: this.state.treasuresLocationSafe,
			legacyLetters: this.state.legacyLetters,
			legacyScholarship: this.state.legacyScholarship
		};
		let existingId = '';
		if (this.props.data.myInstructions.length > 0) {
			existingId = this.props.data.myInstructions[0].instructionsId;
		}
		if (existingId === '') {
			this.props.postInstructions(instructionsData);
		}
		else {
			console.log(existingId);
			this.props.updateInstructions(instructionsData, existingId);
		}
		this.setState({ isEditing: false, existingNote: true });
	};
	setItems = (newState) => {
		this.setState({ items: newState });
	};

	render () {
		const { classes, activeCardId } = this.props;

		// let tableMarkup = (
		// 	<MaterialTable
		// 		title=""
		// 		columns={this.state.items.columns}
		// 		data={this.state.items.data}
		// 		editable={{
		// 			onRowAdd: (newData) =>
		// 				new Promise((resolve) => {
		// 					setTimeout(() => {
		// 						resolve();
		// 						this.setItems((prevState) => {
		// 							const data = [
		// 								...prevState.data
		// 							];
		// 							data.push(newData);
		// 							return { ...prevState, data };
		// 						});
		// 					}, 600);
		// 				}),
		// 			onRowUpdate: (newData, oldData) =>
		// 				new Promise((resolve) => {
		// 					setTimeout(() => {
		// 						resolve();
		// 						if (oldData) {
		// 							this.setItems((prevState) => {
		// 								const data = [
		// 									...prevState.data
		// 								];
		// 								data[data.indexOf(oldData)] = newData;
		// 								return { ...prevState, data };
		// 							});
		// 						}
		// 					}, 600);
		// 				}),
		// 			onRowDelete: (oldData) =>
		// 				new Promise((resolve) => {
		// 					setTimeout(() => {
		// 						resolve();
		// 						this.setItems((prevState) => {
		// 							const data = [
		// 								...prevState.data
		// 							];
		// 							data.splice(data.indexOf(oldData), 1);
		// 							return { ...prevState, data };
		// 						});
		// 					}, 600);
		// 				})
		// 		}}
		// 	/>
		// );

		let instructionsMarkup =
			this.props.user.loading || this.props.data.loading ? (
				<div style={{ height: '55px', margin: 'auto' }}>
					<Paper
						style={{
							height: 20,
							width: 20,
							backgroundColor: 'white',
							borderRadius: '50%',
							margin: '50px auto 15px auto'
						}}
						className={classes.loading}
					>
						<CircularProgress size={20} style={{ color: 'rgba(0,0,0,0.3)' }} />
					</Paper>
				</div>
			) : activeCardId === 26 ? (
				<form className={classes.instructionsForm}>
					<Button
						onClick={this.handleSubmit}
						variant="contained"
						className={this.props.cardDrawerOpen ? classes.saveButtonOpen : classes.saveButton}
						style={{
							position: 'fixed',
							padding: 'auto 50px auto 50px',
							fontSize: '.85em',
							background:
								'linear-gradient(165deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)',
							color: '#ffffff',
							borderRadius: '1em',
							zIndex: 10,
							marginTop: 20
						}}
					>
						Save
					</Button>
					<Typography className={classes.header}>Care Instructions</Typography>
					<Typography className={classes.subprompt}>
						Time to translate all those thoughts into something more definitive. Save often!
					</Typography>
					<Divider className={classes.divider} />
					<Typography className={classes.prompt}>
						Who will you ask to be your ‘Agent’? This gives them the authority to make healthcare decisions
						on your behalf if you’re unable to.
					</Typography>
					<Typography className={classes.subprompt}>
						In most states, the Agent’s authority takes effect only when your attending doctor and a trusted
						second opinion have agreed.
					</Typography>
					{this.props.data.myNotes.map((note, i) => {
						let relevantCardId = 5;
						if (note.linkedCardId === relevantCardId) {
							return (
								<Typography style={{ margin: '10px auto 20px auto', color: '#aaa' }} key={i}>
									Previously you wrote: <span style={{ color: '#000' }}>"{note.body}"</span>
								</Typography>
							);
						}
						else return null;
						// <NotecardPersonal note={note} linkedCardId={relevantCardId} />;
					})}
					<TextField
						label="first choice"
						name="agentFirstChoice"
						value={this.state.agentFirstChoice}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="back up"
						name="agentBackUp"
						value={this.state.agentBackUp}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="back up back up"
						name="agentBackUpBackUp"
						value={this.state.agentBackUpBackUp}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<Typography className={classes.subprompt} style={{ marginTop: 20 }}>
						The back ups will be called upon in the event that your first choice: <br /> 1. is unable or
						unwilling to take it on <br /> 2. is divorced or legally separated from you <br /> 3. has passed
						away
					</Typography>
					<Divider className={classes.divider} />
					<Typography className={classes.prompt}>
						Who else should be consulted or informed when it comes to major medical decisions? Who should
						not?
					</Typography>
					<Typography className={classes.subprompt}>
						There should only be one Agent, but that doesn’t necessarily mean they should be making these
						decisions without input from others.
					</Typography>
					<TextareaAutosize
						label=""
						name="agentSupport"
						value={this.state.agentSupport}
						type="text"
						rowsMin="4"
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
						style={{ marginTop: 20 }}
						// defaultValue={this.state.body}
						// multiline
						// error={errors.body ? true : false}
						// helperText={errors.body}
						// fullWidth
					/>
					<Divider className={classes.dividerAfterAutosize} />
					<Typography className={classes.prompt}>
						Check off which kinds of health care decisions you will grant your Agent the authority to make.
					</Typography>
					{this.props.data.myNotes.map((note, i) => {
						let relevantCardId = 4;
						if (note.linkedCardId === relevantCardId) {
							return (
								<Typography style={{ margin: '10px auto 20px auto', color: '#aaa' }} key={i}>
									Previously you wrote: <span style={{ color: '#000' }}>"{note.body}"</span>
								</Typography>
							);
						}
						else return null;
					})}
					<FormControl component="fieldset" className={classes.formControl}>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.HCDmedicalCare}
										onChange={this.handleToggle}
										name="HCDmedicalCare"
										size="small"
										style={{ color: '#0f8797', marginRight: 20 }}
									/>
								}
								label="Start, stop, or withhold medical care or services diagnostic tests, medicine, surgery..."
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.HCDlifeSustaining}
										onChange={this.handleToggle}
										name="HCDlifeSustaining"
										size="small"
										style={{ color: '#0f8797', marginRight: 20 }}
									/>
								}
								label="Start, stop, or withhold life sustaining treatments or artificially supplied food and water (IV)."
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.HCDpainMedication}
										onChange={this.handleToggle}
										name="HCDpainMedication"
										size="small"
										style={{ color: '#0f8797', marginRight: 20 }}
									/>
								}
								label="Start, stop, or withhold medication or procedures intended to relieve pain."
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.HCDinterpret}
										onChange={this.handleToggle}
										name="HCDinterpret"
										size="small"
										style={{ color: '#0f8797', marginRight: 20 }}
									/>
								}
								label="Interpret the instructions or wishes that I’ve shared, based on their knowledge of me."
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.HCDhireHelp}
										onChange={this.handleToggle}
										name="HCDhireHelp"
										size="small"
										style={{ color: '#0f8797', marginRight: 20 }}
									/>
								}
								label="Hire or fire health care workers or admit me into assisted living, hospital, hospice, or nursing home."
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.HCDrelocate}
										onChange={this.handleToggle}
										name="HCDrelocate"
										size="small"
										style={{ color: '#0f8797', marginRight: 20 }}
									/>
								}
								label="Move me to another state or country to get specialized medical attention."
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.HCDorgans}
										onChange={this.handleToggle}
										name="HCDorgans"
										size="small"
										style={{ color: '#0f8797', marginRight: 20 }}
									/>
								}
								label="Approve the donation of useable organs or tissues."
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.HCDmedicalRecords}
										onChange={this.handleToggle}
										name="HCDmedicalRecords"
										size="small"
										style={{ color: '#0f8797', marginRight: 20 }}
									/>
								}
								label="See and release medical records and personal files, including signing documents on my behalf."
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.HCDlegalAction}
										onChange={this.handleToggle}
										name="HCDlegalAction"
										size="small"
										style={{ color: '#0f8797', marginRight: 20 }}
									/>
								}
								label="Take legal action on my behalf in order to carry out my wishes."
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.HCDbenefits}
										onChange={this.handleToggle}
										name="HCDbenefits"
										size="small"
										style={{ color: '#0f8797', marginRight: 20 }}
									/>
								}
								label="Apply for Medicare, Medicaid, or another benefits program or insurance, and access any personal and financial records needed in order to complete the application."
							/>
						</FormGroup>
					</FormControl>
					<Divider className={classes.divider} />
					<Typography className={classes.prompt}>
						Check off which kinds of care you want to receive as you approach the end of your life.
					</Typography>
					{this.props.data.myNotes.map((note, i) => {
						let relevantCardId = 1;
						if (note.linkedCardId === relevantCardId) {
							return (
								<Typography style={{ margin: '10px auto 20px auto', color: '#aaa' }} key={i}>
									Previously you wrote: <span style={{ color: '#000' }}>"{note.body}"</span>
								</Typography>
							);
						}
						else return null;
					})}
					<FormControl component="fieldset" className={classes.formControl}>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.tastesNails}
										onChange={this.handleToggle}
										name="tastesNails"
										size="small"
										style={{ color: '#0f8797', marginRight: 20 }}
									/>
								}
								label="I want my nails clipped and kept clean."
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.tastesLips}
										onChange={this.handleToggle}
										name="tastesLips"
										size="small"
										style={{ color: '#0f8797', marginRight: 20 }}
									/>
								}
								label="I want lip balm or water when my lips get dry."
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.tastesFeverish}
										onChange={this.handleToggle}
										name="tastesFeverish"
										size="small"
										style={{ color: '#0f8797', marginRight: 20 }}
									/>
								}
								label="I want a cool moist cloth when I’m feverish."
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.tastesBaths}
										onChange={this.handleToggle}
										name="tastesBaths"
										size="small"
										style={{ color: '#0f8797', marginRight: 20 }}
									/>
								}
								label="I want warm baths often to stay fresh and clean."
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.tastesTeeth}
										onChange={this.handleToggle}
										name="tastesTeeth"
										size="small"
										style={{ color: '#0f8797', marginRight: 20 }}
									/>
								}
								label="I want my teeth brushed regularly."
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.tastesHair}
										onChange={this.handleToggle}
										name="tastesHair"
										size="small"
										style={{ color: '#0f8797', marginRight: 20 }}
									/>
								}
								label="I want my hair brushed and/or face shaved."
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.tastesSoiled}
										onChange={this.handleToggle}
										name="tastesSoiled"
										size="small"
										style={{ color: '#0f8797', marginRight: 20 }}
									/>
								}
								label="I want to be kept clean if I soil myself."
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.tastesMusic}
										onChange={this.handleToggle}
										name="tastesMusic"
										size="small"
										style={{ color: '#0f8797', marginRight: 20 }}
									/>
								}
								label="I want my favorite music playing when possible."
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.tastesCompany}
										onChange={this.handleToggle}
										name="tastesCompany"
										size="small"
										style={{ color: '#0f8797', marginRight: 20 }}
									/>
								}
								label="I want to have company as often as possible."
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.tastesPictures}
										onChange={this.handleToggle}
										name="tastesPictures"
										size="small"
										style={{ color: '#0f8797', marginRight: 20 }}
									/>
								}
								label="I want pictures of my loved ones displayed nearby."
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.tastesHandholding}
										onChange={this.handleToggle}
										name="tastesHandholding"
										size="small"
										style={{ color: '#0f8797', marginRight: 20 }}
									/>
								}
								label="I want to have my hand held and to be talked to."
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.tastesPrayer}
										onChange={this.handleToggle}
										name="tastesPrayer"
										size="small"
										style={{ color: '#0f8797', marginRight: 20 }}
									/>
								}
								label="I want my faith community to pray for/with me."
							/>
						</FormGroup>
					</FormControl>
					<Typography style={{ marginTop: 20 }}>Add your own</Typography>
					<Typography className={classes.subprompt}>
						(makeup, clothing, lighting, noise level, smells, foods/drinks, moods, lotions, entertainment,
						pain relief, colors...)
					</Typography>
					<TextareaAutosize
						label=""
						name="tastesAddMore"
						value={this.state.tastesAddMore}
						type="text"
						rowsMin="4"
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
						style={{ marginTop: 20 }}
						// defaultValue={this.state.body}
						// multiline
						// error={errors.body ? true : false}
						// helperText={errors.body}
						// fullWidth
					/>
					<Divider className={classes.dividerAfterAutosize} />
					<Typography className={classes.prompt}>Medical Instructions</Typography>
					{this.props.data.myNotes.map((note, i) => {
						let relevantCardId = 3;
						if (note.linkedCardId === relevantCardId) {
							return (
								<Typography style={{ margin: '10px auto 20px auto', color: '#aaa' }} key={i}>
									Previously you wrote: <span style={{ color: '#000' }}>"{note.body}"</span>
								</Typography>
							);
						}
						else return null;
					})}
					<FormControl component="fieldset" className={classes.formControl}>
						<Typography className={classes.subprompt}>Pain medication</Typography>
						<Typography>
							Would you pain medication even if it could make you feel drowsy or weaker than usual?
						</Typography>
						<RadioGroup
							name="directivesPain"
							value={this.state.directivesPain}
							onChange={this.handleRadioChange}
							row
							className={classes.radioGroup}
						>
							<FormControlLabel value="yes" control={<Radio />} label="yes" labelPlacement="top" />
							<FormControlLabel
								value="trial"
								control={<Radio />}
								label="on a trial basis"
								labelPlacement="top"
							/>
							<FormControlLabel value="no" control={<Radio />} label="no" labelPlacement="top" />
						</RadioGroup>
						<Typography className={classes.subprompt}>Resuscitation</Typography>
						<Typography>
							Would you want emergency responders to resuscitate you (CPR etc.) if they found you not
							breathing or without a heartbeat?
						</Typography>
						<RadioGroup
							name="directivesResuscitate"
							value={this.state.directivesResuscitate}
							onChange={this.handleRadioChange}
							row
							className={classes.radioGroup}
						>
							<FormControlLabel value="yes" control={<Radio />} label="yes" labelPlacement="top" />
							<FormControlLabel
								value="trial"
								control={<Radio />}
								label="on a trial basis"
								labelPlacement="top"
							/>
							<FormControlLabel value="no" control={<Radio />} label="no" labelPlacement="top" />
						</RadioGroup>
						<Typography className={classes.subprompt}>Artificially provided nutrients (IV)</Typography>
						<Typography>
							Would you want food and hydration through a tube or IV if you can’t eat/drink on your own?
						</Typography>
						<RadioGroup
							name="directivesIV"
							value={this.state.directivesIV}
							onChange={this.handleRadioChange}
							row
							className={classes.radioGroup}
						>
							<FormControlLabel value="yes" control={<Radio />} label="yes" labelPlacement="top" />
							<FormControlLabel
								value="trial"
								control={<Radio />}
								label="on a trial basis"
								labelPlacement="top"
							/>
							<FormControlLabel value="no" control={<Radio />} label="no" labelPlacement="top" />
						</RadioGroup>
						<Typography className={classes.subprompt}>Terminal illness</Typography>
						<Typography>
							Would you want life-sustaining drugs, machines, or procedures even if they’re unable to cure
							your illness?
						</Typography>
						<RadioGroup
							name="directivesTerminal"
							value={this.state.directivesTerminal}
							onChange={this.handleRadioChange}
							row
							className={classes.radioGroup}
						>
							<FormControlLabel value="yes" control={<Radio />} label="yes" labelPlacement="top" />
							<FormControlLabel
								value="trial"
								control={<Radio />}
								label="on a trial basis"
								labelPlacement="top"
							/>
							<FormControlLabel value="no" control={<Radio />} label="no" labelPlacement="top" />
						</RadioGroup>
						<Typography className={classes.subprompt}>Permanently unconscious</Typography>
						<Typography>
							Would you want life-sustaining drugs, machines, or procedures even if they’re unable to
							bring you back?
						</Typography>
						<RadioGroup
							name="directivesComa"
							value={this.state.directivesComa}
							onChange={this.handleRadioChange}
							row
							className={classes.radioGroup}
						>
							<FormControlLabel value="yes" control={<Radio />} label="yes" labelPlacement="top" />
							<FormControlLabel
								value="trial"
								control={<Radio />}
								label="on a trial basis"
								labelPlacement="top"
							/>
							<FormControlLabel value="no" control={<Radio />} label="no" labelPlacement="top" />
						</RadioGroup>
						<Typography className={classes.subprompt}>Permanent brain damage</Typography>
						<Typography>
							Would you want life-sustaining drugs, machines, or procedures even if they’re unable to
							repair the damage?
						</Typography>
						<RadioGroup
							name="directivesBrainDamage"
							value={this.state.directivesBrainDamage}
							onChange={this.handleRadioChange}
							row
							className={classes.radioGroup}
						>
							<FormControlLabel value="yes" control={<Radio />} label="yes" labelPlacement="top" />
							<FormControlLabel
								value="trial"
								control={<Radio />}
								label="on a trial basis"
								labelPlacement="top"
							/>
							<FormControlLabel value="no" control={<Radio />} label="no" labelPlacement="top" />
						</RadioGroup>
						<Typography className={classes.subprompt}>Close to the end</Typography>
						<Typography>
							Would you want life-sustaining drugs, machines, or procedures even if it only marginally
							extends your life?
						</Typography>
						<RadioGroup
							name="directivesLastDays"
							value={this.state.directivesLastDays}
							onChange={this.handleRadioChange}
							row
							className={classes.radioGroup}
						>
							<FormControlLabel value="yes" control={<Radio />} label="yes" labelPlacement="top" />
							<FormControlLabel
								value="trial"
								control={<Radio />}
								label="on a trial basis"
								labelPlacement="top"
							/>
							<FormControlLabel value="no" control={<Radio />} label="no" labelPlacement="top" />
						</RadioGroup>
						<Typography className={classes.subprompt}>Religious or personal beliefs</Typography>
						<Typography>
							If there are any other contexts in which you would or would not want life-support treatment,
							please write them here:
						</Typography>
						<RadioGroup
							name="directivesWithhold"
							value={this.state.directivesWithhold}
							onChange={this.handleRadioChange}
							row
							className={classes.radioGroup}
						>
							<FormControlLabel value="yes" control={<Radio />} label="yes" labelPlacement="top" />
							<FormControlLabel
								value="trial"
								control={<Radio />}
								label="on a trial basis"
								labelPlacement="top"
							/>
							<FormControlLabel value="no" control={<Radio />} label="no" labelPlacement="top" />
						</RadioGroup>
					</FormControl>
					<Divider className={classes.dividerAfterAutosize} />
					<Typography className={classes.prompt}>Who will you nominate to be your ‘Guardian’?</Typography>
					<Typography className={classes.subprompt}>
						This gives them the authority to manage your finances and sign legal documents in your name.
					</Typography>
					{this.props.data.myNotes.map((note, i) => {
						let relevantCardId = 2;
						if (note.linkedCardId === relevantCardId) {
							return (
								<Typography style={{ margin: '10px auto 20px auto', color: '#aaa' }} key={i}>
									Previously you wrote: <span style={{ color: '#000' }}>"{note.body}"</span>
								</Typography>
							);
						}
						else return null;
						// <NotecardPersonal note={note} linkedCardId={relevantCardId} />;
					})}
					<TextField
						label="first choice"
						name="guardianFirstChoice"
						value={this.state.guardianFirstChoice}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="back up"
						name="guardianBackUp"
						value={this.state.guardianBackUp}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<div style={{ height: 80 }} />
				</form>
			) : activeCardId === 27 ? (
				<form className={classes.instructionsForm} onSubmit={this.handleSubmit}>
					<Button
						onClick={this.handleSubmit}
						variant="contained"
						className={this.props.cardDrawerOpen ? classes.saveButtonOpen : classes.saveButton}
						style={{
							position: 'fixed',
							padding: 'auto 50px auto 50px',
							fontSize: '.85em',
							background:
								'linear-gradient(165deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)',
							color: '#ffffff',
							borderRadius: '1em',
							zIndex: 10,
							marginTop: 20
						}}
					>
						Save
					</Button>
					<Typography className={classes.header}>Body Instructions</Typography>
					<Typography className={classes.subprompt}>
						Time to translate all those thoughts into something more definitive. Save often!
					</Typography>
					<Divider className={classes.divider} />
					<Typography className={classes.prompt}>
						What would be the ideal environment for your passing?
					</Typography>
					<Typography className={classes.subprompt}>
						Where would it be? Who would be there? Would there be some sort of ritual or ceremonial
						experience that would take place immediately after your passing?
					</Typography>
					<TextareaAutosize
						label=""
						name="bodySetting"
						value={this.state.bodySetting}
						type="text"
						rowsMin="4"
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>
					<Divider className={classes.dividerAfterAutosize} />
					<Typography className={classes.prompt}>What would you want to become of your remains? </Typography>
					<Typography className={classes.subprompt}>
						Burial (in a casket), cremation, resomation (eco-friendly cremation), water burial (cast off to
						sea), turned to coral, turned to a tree, donated to science, green burial (no casket), space
						burial (blasted off into space), turned into a gem, cryogentically frozen, some combination of
						the above...
					</Typography>
					{this.props.data.myNotes.map((note, i) => {
						let relevantCardId = 6;
						if (note.linkedCardId === relevantCardId) {
							return (
								<Typography style={{ margin: '10px auto 20px auto', color: '#aaa' }} key={i}>
									Previously you wrote: <span style={{ color: '#000' }}>"{note.body}"</span>
								</Typography>
							);
						}
						else return null;
					})}
					<TextareaAutosize
						label=""
						name="bodyRemains"
						value={this.state.bodyRemains}
						type="text"
						rowsMin="4"
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>
					<Divider className={classes.dividerAfterAutosize} />
					<Typography className={classes.prompt}>
						Are there any special locations that might serve as the place for your body to be buried, to
						have your ashes scattered, to have a memorial installed, etc?
					</Typography>
					<Typography className={classes.subprompt}>
						Consider choosing a place your loved ones can visit after you’re gone.
					</Typography>
					{this.props.data.myNotes.map((note, i) => {
						let relevantCardId = 7;
						if (note.linkedCardId === relevantCardId) {
							return (
								<Typography style={{ margin: '10px auto 20px auto', color: '#aaa' }} key={i}>
									Previously you wrote: <span style={{ color: '#000' }}>"{note.body}"</span>
								</Typography>
							);
						}
						else return null;
					})}
					<TextareaAutosize
						label=""
						name="bodyPlaces"
						value={this.state.bodyPlaces}
						type="text"
						rowsMin="4"
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>
					<Divider className={classes.dividerAfterAutosize} />
					<Typography className={classes.prompt}>
						What else would happen during the first few days?
					</Typography>
					<Typography className={classes.subprompt}>
						Would your body be moved immediately to a funeral home? Are there any religious or cultural
						practices that should take place in the days that follow? Who should be notified and when?
					</Typography>
					<TextareaAutosize
						label=""
						name="bodyFirstDays"
						value={this.state.bodyFirstDays}
						type="text"
						rowsMin="4"
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>
					<Divider className={classes.dividerAfterAutosize} />
					<Typography className={classes.prompt}>
						Who will you ask to be responsible for ensure your remains are handled in accordance with your
						wishes?
					</Typography>
					{this.props.data.myNotes.map((note, i) => {
						let relevantCardId = 10;
						if (note.linkedCardId === relevantCardId) {
							return (
								<Typography style={{ margin: '10px auto 20px auto', color: '#aaa' }} key={i}>
									Previously you wrote: <span style={{ color: '#000' }}>"{note.body}"</span>
								</Typography>
							);
						}
						else return null;
					})}
					<TextField
						label="first choice"
						name="bodyFirstChoice"
						value={this.state.bodyFirstChoice}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="back up"
						name="bodyBackUp"
						value={this.state.bodyBackUp}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="back up back up"
						name="bodyBackUpBackUp"
						value={this.state.bodyBackUpBackUp}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<Divider className={classes.divider} />
					<FormControl component="fieldset" className={classes.formControl}>
						<Typography className={classes.prompt}>Do you want to have your organs donated?</Typography>
						<Typography className={classes.subprompt}>
							You can even put the name of a specific institution in the document.
						</Typography>
						{this.props.data.myNotes.map((note, i) => {
							let relevantCardId = 9;
							if (note.linkedCardId === relevantCardId) {
								return (
									<Typography style={{ margin: '10px auto 20px auto', color: '#aaa' }} key={i}>
										Previously you wrote: <span style={{ color: '#000' }}>"{note.body}"</span>
									</Typography>
								);
							}
							else return null;
						})}
						<RadioGroup
							name="bodyOrgans"
							value={this.state.bodyOrgans}
							onChange={this.handleRadioChange}
							row
							className={classes.radioGroup}
						>
							<FormControlLabel value="yes" control={<Radio />} label="yes" labelPlacement="top" />
							<FormControlLabel
								value="only some"
								control={<Radio />}
								label="yes, but... no some organs are off-limits"
								labelPlacement="top"
							/>
							<FormControlLabel
								value="only transplants"
								control={<Radio />}
								label="yes, but... only for therapy or transplants"
								labelPlacement="top"
							/>
							<FormControlLabel value="no" control={<Radio />} label="no" labelPlacement="top" />
						</RadioGroup>
					</FormControl>
					{this.state.bodyOrgans === 'only some' ? (
						<TextField
							label="which are off limits?"
							name="bodySpecificOrgans"
							value={this.state.bodySpecificOrgans}
							variant="outlined"
							className={classes.textInput}
							type="text"
							onChange={this.handleChange}
							margin="dense"
						/>
					) : null}
					<FormControl component="fieldset" className={classes.formControl}>
						<Typography className={classes.prompt}>Do you concent to an autopsy?</Typography>
						<Typography className={classes.subprompt}>
							An autopsy can help reveal your cause of death, and in some cases it’s required by law.
						</Typography>
						<RadioGroup
							name="bodyAutopsy"
							value={this.state.bodyAutopsy}
							onChange={this.handleRadioChange}
							row
							className={classes.radioGroup}
						>
							<FormControlLabel value="yes" control={<Radio />} label="yes" labelPlacement="top" />
							<FormControlLabel value="no" control={<Radio />} label="no" labelPlacement="top" />
						</RadioGroup>
					</FormControl>
					<div style={{ height: 80 }} />
				</form>
			) : activeCardId === 28 ? (
				<form className={classes.instructionsForm} onSubmit={this.handleSubmit}>
					<Button
						onClick={this.handleSubmit}
						variant="contained"
						className={this.props.cardDrawerOpen ? classes.saveButtonOpen : classes.saveButton}
						style={{
							position: 'fixed',
							padding: 'auto 50px auto 50px',
							fontSize: '.85em',
							background:
								'linear-gradient(165deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)',
							color: '#ffffff',
							borderRadius: '1em',
							zIndex: 10,
							marginTop: 20
						}}
					>
						Save
					</Button>
					<Typography className={classes.header}>Ceremony Instructions</Typography>
					<Typography className={classes.subprompt}>
						Time to translate all those thoughts into something more definitive. Save often!
					</Typography>
					<Divider className={classes.divider} />
					<FormControl component="fieldset" className={classes.formControl}>
						<Typography className={classes.prompt}>What kind of ceremony should there be?</Typography>
						{this.props.data.myNotes.map((note, i) => {
							let relevantCardId = 11;
							if (note.linkedCardId === relevantCardId) {
								return (
									<Typography style={{ margin: '10px auto 20px auto', color: '#aaa' }} key={i}>
										Previously you wrote: <span style={{ color: '#000' }}>"{note.body}"</span>
									</Typography>
								);
							}
							else return null;
						})}
						<RadioGroup
							name="ceremonyType"
							value={this.state.ceremonyType}
							onChange={this.handleRadioChange}
							row
							className={classes.radioGroup}
						>
							<FormControlLabel
								value="funeral"
								control={<Radio />}
								label="funeral (with a body)"
								labelPlacement="top"
							/>
							<FormControlLabel
								value="memorial"
								control={<Radio />}
								label="memorial service (without a body)"
								labelPlacement="top"
							/>
							<FormControlLabel
								value="other"
								control={<Radio />}
								label="other (elaborate below)"
								labelPlacement="top"
							/>
						</RadioGroup>
					</FormControl>
					<Divider className={classes.dividerAfterAutosize} />
					<Typography className={classes.prompt}>What would you want from your funeral/memorial? </Typography>
					<Typography className={classes.subprompt}>
						Think about how you might insert some of your unique character into your ceremony, even if you
						decide to follow a traditional script. What details can you picture, food, songs, readings,
						activities, or even colors and themes? Would your body there and if so should the casket be
						open?
					</Typography>
					{this.props.data.myNotes.map((note, i) => {
						let relevantCardId = 13;
						if (note.linkedCardId === relevantCardId) {
							return (
								<Typography style={{ margin: '10px auto 20px auto', color: '#aaa' }} key={i}>
									Previously you wrote: <span style={{ color: '#000' }}>"{note.body}"</span>
								</Typography>
							);
						}
						else return null;
					})}
					<TextareaAutosize
						label=""
						name="ceremonyVision"
						value={this.state.ceremonyVision}
						type="text"
						rowsMin="8"
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>{' '}
					<Divider className={classes.dividerAfterAutosize} />
					<Typography className={classes.prompt}>
						Who would you want to be invited to your funeral?
					</Typography>
					<Typography className={classes.subprompt}>
						Call out the people you imagine might play a bigger role in planning or on the day of. Also, is
						there anyone who should not be invited?
					</Typography>
					<TextareaAutosize
						label=""
						name="ceremonyAttendees"
						value={this.state.ceremonyAttendees}
						type="text"
						rowsMin="8"
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>{' '}
					<Divider className={classes.dividerAfterAutosize} />
					<Typography className={classes.prompt}>
						How would you like to be spoken about at your funeral?
					</Typography>
					<Typography className={classes.subprompt}>
						Consider which of your stories you would (and would not) want told. Think about which of your
						accomplishments or character traits you would (or would not) want people to emphasize.
					</Typography>
					{this.props.data.myNotes.map((note, i) => {
						let relevantCardId = 14;
						if (note.linkedCardId === relevantCardId) {
							return (
								<Typography style={{ margin: '10px auto 20px auto', color: '#aaa' }} key={i}>
									Previously you wrote: <span style={{ color: '#000' }}>"{note.body}"</span>
								</Typography>
							);
						}
						else return null;
					})}
					<TextareaAutosize
						label=""
						name="ceremonyStories"
						value={this.state.ceremonyStories}
						type="text"
						rowsMin="8"
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>
					<Divider className={classes.dividerAfterAutosize} />
					<Typography className={classes.prompt}>
						After the funeral, what traditions would you like to see people keep to honor your memory?
					</Typography>
					<Typography className={classes.subprompt}>
						Think about how you can create a sense of community to help your loved ones grieve. It could be
						the week of, one month, or one year after the fact. It might even become an annual thing. Also
						consider whether it would be a new tradition or if it could build on an existing tradition that
						you were already doing during your lifetime.
					</Typography>
					<TextareaAutosize
						label=""
						name="ceremonyTraditions"
						value={this.state.ceremonyTraditions}
						type="text"
						rowsMin="12"
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>
					<Divider className={classes.dividerAfterAutosize} />
					<div style={{ height: 80 }} />
				</form>
			) : activeCardId === 29 ? (
				<form className={classes.instructionsForm} onSubmit={this.handleSubmit}>
					<Button
						onClick={this.handleSubmit}
						variant="contained"
						className={this.props.cardDrawerOpen ? classes.saveButtonOpen : classes.saveButton}
						style={{
							position: 'fixed',
							padding: 'auto 50px auto 50px',
							fontSize: '.85em',
							background:
								'linear-gradient(165deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)',
							color: '#ffffff',
							borderRadius: '1em',
							zIndex: 10,
							marginTop: 20
						}}
					>
						Save
					</Button>
					<Typography className={classes.header}>Treasures Instructions</Typography>
					<Typography className={classes.subprompt}>
						Time to translate all those thoughts into something more definitive. Save often!
					</Typography>
					<Divider className={classes.divider} />
					<Typography className={classes.prompt}>Who will you ask to be your ‘Executor’?</Typography>
					<Typography className={classes.subprompt}>
						This person will be responsible for ensuring the wishes expressed in your Will are carried out
						as intended. It can be a lot of work, so you might consider compensating them for their efforts.
					</Typography>
					{this.props.data.myNotes.map((note, i) => {
						let relevantCardId = 20;
						if (note.linkedCardId === relevantCardId) {
							return (
								<Typography style={{ margin: '10px auto 20px auto', color: '#aaa' }} key={i}>
									Previously you wrote: <span style={{ color: '#000' }}>"{note.body}"</span>
								</Typography>
							);
						}
						else return null;
					})}
					<TextField
						label="first choice"
						name="treasuresExecutorFirstChoice"
						value={this.state.treasuresExecutorFirstChoice}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="back up"
						name="treasuresExecutorBackUp"
						value={this.state.treasuresExecutorBackUp}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="back up back up"
						name="treasuresExecutorBackUpBackUp"
						value={this.state.treasuresExecutorBackUpBackUp}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<Divider className={classes.divider} />
					<Typography className={classes.prompt}>
						Who will you ask to take in your children (if under 18 or in need of care) should something
						happen to you?
					</Typography>
					<Typography className={classes.subprompt}>
						Consider what would make the transition as easy as possible, both for your kids and the
						guardian. Would they have to change schools? Could they keep practicing their beliefs? Consider
						what kind of financial support you can provide.
					</Typography>
					{this.props.data.myNotes.map((note, i) => {
						let relevantCardId = 17;
						if (note.linkedCardId === relevantCardId) {
							return (
								<Typography style={{ margin: '10px auto 20px auto', color: '#aaa' }} key={i}>
									Previously you wrote: <span style={{ color: '#000' }}>"{note.body}"</span>
								</Typography>
							);
						}
						else return null;
					})}
					<TextField
						label="first choice"
						name="treasuresGodparentsFirstChoice"
						value={this.state.treasuresGodparentsFirstChoice}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="back up"
						name="treasuresGodparentsBackUp"
						value={this.state.treasuresGodparentsBackUp}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					{/* <Typography className={classes.subprompt}>Notes</Typography> */}
					<TextareaAutosize
						label=""
						placeholder="Add any notes here..."
						name="treasuresGodparentsNotes"
						value={this.state.treasuresGodparentsNotes}
						type="text"
						rowsMin="2"
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
						style={{ marginTop: 20 }}
					/>
					<Divider className={classes.dividerAfterAutosize} />
					<Typography className={classes.prompt}>
						Who will you ask to take in your pets if something were to happen to you?
					</Typography>
					<Typography className={classes.subprompt}>
						Consider what kind of financial support you can provide.
					</Typography>
					<TextField
						label="first choice"
						name="treasuresPetparentsFirstChoice"
						value={this.state.treasuresPetparentsFirstChoice}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="back up"
						name="treasuresPetparentsBackUp"
						value={this.state.treasuresPetparentsBackUp}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					{/* <Typography className={classes.subprompt}>Notes</Typography> */}
					<TextareaAutosize
						label=""
						name="treasuresPetparentsNotes"
						placeholder="Add any notes here..."
						value={this.state.treasuresPetparentsNotes}
						type="text"
						rowsMin="2"
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
						style={{ marginTop: 20 }}
					/>
					<Divider className={classes.dividerAfterAutosize} />
					<Typography className={classes.prompt}>Important information</Typography>
					<TextField
						label="Your Legal Name"
						name="treasuresLegalName"
						value={this.state.treasuresLegalName}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
						style={{ marginTop: 40 }}
					/>
					<TextField
						label="Your Maiden Name"
						name="treasuresMaidenName"
						value={this.state.treasuresMaidenName}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="Your Birthday"
						name="treasuresBirthday"
						value={this.state.treasuresBirthday}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					{/* <TextField
						label="Your Social Security Number"
						name="treasuresSSN"
						value={this.state.treasuresSSN}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/> */}
					{/* <TextField
						label="Your Driver’s License #"
						name="treasuresLicense"
						value={this.state.treasuresLicense}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="Your Passport #"
						name="treasuresPassport"
						value={this.state.treasuresPassport}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/> */}
					<TextField
						label="Your Home Address"
						name="treasuresHomeAddress"
						value={this.state.treasuresHomeAddress}
						type="text"
						variant="outlined"
						multiline
						rows={3}
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
						style={{ marginTop: 40 }}
					/>
					<TextField
						label="Your Birthplace"
						name="treasuresBirthplace"
						value={this.state.treasuresBirthplace}
						type="text"
						variant="outlined"
						multiline
						rows={3}
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="Spouse’s Name"
						name="treasuresSpouse"
						value={this.state.treasuresSpouse}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
						style={{ marginTop: 40 }}
					/>
					<TextField
						label="Former Spouse’s Name"
						name="treasuresFormerSpouse"
						value={this.state.treasuresFormerSpouse}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="Children’s Names"
						name="treasuresChildren"
						value={this.state.treasuresChildren}
						type="text"
						variant="outlined"
						multiline
						rows={4}
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="Grandkids' Names"
						name="treasuresGrandkids"
						value={this.state.treasuresGrandkids}
						type="text"
						variant="outlined"
						multiline
						rows={4}
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="Pets' Names"
						name="treasuresPets"
						value={this.state.treasuresPets}
						type="text"
						variant="outlined"
						multiline
						rows={3}
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="Parents' Name"
						name="treasuresParent1Name"
						value={this.state.treasuresParent1Name}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
						style={{ marginTop: 40 }}
					/>
					<TextField
						label="Parents’ Birthplace"
						name="treasuresParent1Birthplace"
						value={this.state.treasuresParent1Birthplace}
						type="text"
						variant="outlined"
						multiline
						rows={3}
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="Parents' Name"
						name="treasuresParent2Name"
						value={this.state.treasuresParent2Name}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
						style={{ marginTop: 40 }}
					/>
					<TextField
						label="Parents’ Birthplace"
						name="treasuresParent2Birthplace"
						value={this.state.treasuresParent2Birthplace}
						type="text"
						variant="outlined"
						multiline
						rows={3}
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="Siblings’ Names"
						name="treasuresSiblings"
						value={this.state.treasuresSiblings}
						type="text"
						variant="outlined"
						multiline
						rows={3}
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
						style={{ marginTop: 40 }}
					/>
					<TextField
						label="Physician’s Name"
						name="treasuresPhysician"
						value={this.state.treasuresPhysician}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
						style={{ marginTop: 40 }}
					/>
					<TextField
						label="Physician's Contact Info"
						name="treasuresPhysiciansInfo"
						value={this.state.treasuresPhysiciansInfo}
						type="text"
						variant="outlined"
						multiline
						rows={3}
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="Attorney’s Name"
						name="treasuresAttorney"
						value={this.state.treasuresAttorney}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
						style={{ marginTop: 40 }}
					/>
					<TextField
						label="Attorney's Contact Info"
						name="treasuresAttorneysInfo"
						value={this.state.treasuresAttorneysInfo}
						type="text"
						variant="outlined"
						multiline
						rows={3}
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="Accountant’s Name"
						name="treasuresAccountant"
						value={this.state.treasuresAccountant}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
						style={{ marginTop: 40 }}
					/>
					<TextField
						label="Accountant's Contact Info"
						name="treasuresAccountantsInfo"
						value={this.state.treasuresAccountantsInfo}
						type="text"
						variant="outlined"
						multiline
						rows={3}
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="Financial Advisor’s Name"
						name="treasuresFinancialAdvisor"
						value={this.state.treasuresFinancialAdvisor}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
						style={{ marginTop: 40 }}
					/>
					<TextField
						label="Financial Advisor's Contact Info"
						name="treasuresFinancialAdvisorsInfo"
						value={this.state.treasuresFinancialAdvisorsInfo}
						type="text"
						variant="outlined"
						multiline
						rows={3}
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>
					<Divider className={classes.divider} />
					<Typography className={classes.prompt}>
						List out your most precious and valuable assets, including real estate, heirlooms, collectables,
						savings, investments, and business holdings.
					</Typography>
					<Typography className={classes.subprompt}>
						For each, say who should get it (if it is to be assigned an inheritor) and what hopes or
						intentions you have for it.
					</Typography>
					{this.props.data.myNotes.map((note, i) => {
						let relevantCardId = 16;
						if (note.linkedCardId === relevantCardId) {
							return (
								<Typography style={{ margin: '10px auto 20px auto', color: '#aaa' }} key={i}>
									Previously you wrote: <span style={{ color: '#000' }}>"{note.body}"</span>
								</Typography>
							);
						}
						else return null;
					})}
					{/* {tableMarkup} */}

					{/* <MaterialTableDemo updateTable={this.updateTable} /> */}
					<TextareaAutosize
						label=""
						name="treasuresStuff"
						value={this.state.treasuresStuff}
						type="text"
						rowsMin="3"
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>
					<Divider className={classes.dividerAfterAutosize} />
					<Typography className={classes.prompt}>
						How would you like the rest of your assets distributed? (everything not assigned explicitly in
						the previous question)
					</Typography>
					<Typography className={classes.subprompt}>
						Consider whether it’s more important to you to be ‘fair’ or ‘equal’.
					</Typography>
					{this.props.data.myNotes.map((note, i) => {
						let relevantCardId = 18;
						if (note.linkedCardId === relevantCardId) {
							return (
								<Typography style={{ margin: '10px auto 20px auto', color: '#aaa' }} key={i}>
									Previously you wrote: <span style={{ color: '#000' }}>"{note.body}"</span>
								</Typography>
							);
						}
						else return null;
					})}
					<TextareaAutosize
						label=""
						name="treasuresDistribution"
						value={this.state.treasuresDistribution}
						type="text"
						rowsMin="3"
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>
					<Divider className={classes.dividerAfterAutosize} />
					<Typography className={classes.prompt}>
						Is there anyone who should be excluded from your will?
					</Typography>
					<TextareaAutosize
						label=""
						name="treasuresExcluded"
						value={this.state.treasuresExcluded}
						type="text"
						rowsMin="3"
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>
					<Divider className={classes.dividerAfterAutosize} />
					<Typography className={classes.prompt}>What other financial assets do you have?</Typography>
					<Typography className={classes.subprompt}>
						This includes social security, life insurance, retirement accounts, pensions, veteran’s
						benefits, royalties, money you’re owed, etc.
					</Typography>
					{this.props.data.myNotes.map((note, i) => {
						let relevantCardId = 19;
						if (note.linkedCardId === relevantCardId) {
							return (
								<Typography style={{ margin: '10px auto 20px auto', color: '#aaa' }} key={i}>
									Previously you wrote: <span style={{ color: '#000' }}>"{note.body}"</span>
								</Typography>
							);
						}
						else return null;
					})}
					<TextareaAutosize
						label=""
						name="treasuresAssets"
						value={this.state.treasuresAssets}
						type="text"
						rowsMin="3"
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>
					<Divider className={classes.dividerAfterAutosize} />
					<Typography className={classes.prompt}>What financial liabilities do you have?</Typography>
					<Typography className={classes.subprompt}>
						Unless you have a trust, these will be collected before any of your wealth is distributed, and
						may fall on your inheritors to settle. These include morgages, loans, bills, taxes, credit
						cards, debts, etc.
					</Typography>
					<TextareaAutosize
						label=""
						name="treasuresLiabilities"
						value={this.state.treasuresLiabilities}
						type="text"
						rowsMin="3"
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>
					<Divider className={classes.dividerAfterAutosize} />
					<Typography className={classes.prompt}>
						What digital accounts or subscriptions do you have?
					</Typography>
					<Typography className={classes.subprompt}>
						If you’re comfortable, include your usernames and passwords.
					</Typography>
					<TextareaAutosize
						label=""
						name="treasuresOnline"
						value={this.state.treasuresOnline}
						type="text"
						rowsMin="6"
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>
					{/* <Typography className={classes.prompt}>
						Where are all your most important documents kept?
					</Typography>
					<TextField
						label="Your Driver's License"
						name="treasuresLocationLicense"
						value={this.state.treasuresLocationLicense}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="Your Passport"
						name="treasuresLocationPassport"
						value={this.state.treasuresLocationPassport}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="Your Birth Certificate"
						name="treasuresLocationBirthCertificate"
						value={this.state.treasuresLocationBirthCertificate}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="Your Social Security Card"
						name="treasuresLocationSocialSecurityCard"
						value={this.state.treasuresLocationSocialSecurityCard}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="Your Marriage Certificate"
						name="treasuresLocationMarriageCertificate"
						value={this.state.treasuresLocationMarriageCertificate}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="Your Divorce Papers"
						name="treasuresLocationDivorcePapers"
						value={this.state.treasuresLocationDivorcePapers}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="Your Address Book"
						name="treasuresLocationAddressBook"
						value={this.state.treasuresLocationAddressBook}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="Your Online Passwords"
						name="treasuresLocationOnlinePasswords"
						value={this.state.treasuresLocationOnlinePasswords}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="Your Will"
						name="treasuresLocationWill"
						value={this.state.treasuresLocationWill}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="Your Advance Directives"
						name="treasuresLocationAdvanceDirectives"
						value={this.state.treasuresLocationAdvanceDirectives}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/>
					<TextField
						label="Your Safe Deposit Box"
						name="treasuresLocationSafe"
						value={this.state.treasuresLocationSafe}
						variant="outlined"
						className={classes.textInput}
						type="text"
						onChange={this.handleChange}
						margin="dense"
					/> */}
					<div style={{ height: 80 }} />
				</form>
			) : activeCardId === 30 ? (
				<form className={classes.instructionsForm} onSubmit={this.handleSubmit}>
					<Button
						onClick={this.handleSubmit}
						variant="contained"
						className={this.props.cardDrawerOpen ? classes.saveButtonOpen : classes.saveButton}
						style={{
							position: 'fixed',
							padding: 'auto 50px auto 50px',
							fontSize: '.85em',
							background:
								'linear-gradient(165deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)',
							color: '#ffffff',
							borderRadius: '1em',
							zIndex: 10,
							marginTop: 20
						}}
					>
						Save
					</Button>
					<Typography className={classes.header}>Legacy Instructions</Typography>
					<Typography className={classes.subprompt}>
						Time to translate all those thoughts into something more definitive. Save often!
					</Typography>
					<Divider className={classes.divider} />
					<Typography className={classes.prompt}>
						Imagine people got letters from you after you’re gone. Make a list of people you’d want to write
						letters to.
					</Typography>
					<Typography className={classes.subprompt}>
						For each, write a few notes that capture the main thing you want the letter to say, but don’t
						write out the whole thing.
					</Typography>
					{this.props.data.myNotes.map((note, i) => {
						let relevantCardId = 24;
						if (note.linkedCardId === relevantCardId) {
							return (
								<Typography style={{ margin: '10px auto 20px auto', color: '#aaa' }} key={i}>
									Previously you wrote: <span style={{ color: '#000' }}>"{note.body}"</span>
								</Typography>
							);
						}
						else return null;
					})}
					<TextareaAutosize
						label=""
						name="legacyLetters"
						value={this.state.legacyLetters}
						type="text"
						rowsMin="8"
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>
					<Divider className={classes.dividerAfterAutosize} />
					<Typography className={classes.prompt}>
						Imagine you created a scholarship fund. What kind of opportunities would this fund create for
						people?
					</Typography>
					<Typography className={classes.subprompt}>
						Think about the causes that matter most to you, the populations you’re most committed to
						serving, and the kinds of projects or experiences that you believe make the greatest positive
						impact.
					</Typography>
					{this.props.data.myNotes.map((note, i) => {
						let relevantCardId = 22;
						if (note.linkedCardId === relevantCardId) {
							return (
								<Typography style={{ margin: '10px auto 20px auto', color: '#aaa' }} key={i}>
									Previously you wrote: <span style={{ color: '#000' }}>"{note.body}"</span>
								</Typography>
							);
						}
						else return null;
					})}
					<TextareaAutosize
						label=""
						name="legacyScholarship"
						value={this.state.legacyScholarship}
						type="text"
						rowsMin="8"
						className={classes.textInput}
						onChange={this.handleChange}
						margin="dense"
					/>
				</form>
			) : null;

		return <Fragment>{instructionsMarkup}</Fragment>;
	}
}

Instructions.propTypes = {
	classes: PropTypes.object.isRequired,
	getUserData: PropTypes.func.isRequired,
	getNotes: PropTypes.func.isRequired,
	getMyNotes: PropTypes.func.isRequired,
	getMyInstructions: PropTypes.func.isRequired,
	postInstructions: PropTypes.func.isRequired,
	updateInstructions: PropTypes.func.isRequired,
	selectTransition: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({ data: state.data, user: state.user, UI: state.UI });
const mapActionsToProps = {
	getUserData,
	getNotes,
	getMyNotes,
	postNote,
	updateNote,
	clearErrors,
	selectTransition,
	getMyInstructions,
	postInstructions,
	updateInstructions
};

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(Instructions));
