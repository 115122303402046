import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DeleteNote from './DeleteNote';
import NoteDialog from './NoteDialog';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
// import PublicIcon from '@material-ui/icons/Public';
// import LockIcon from '@material-ui/icons/Lock';

const styles = (theme) => ({
	...theme.spreadThis,
	card: {
		position: 'relative',
		display: 'flex',
		marginBottom: 10,
		minHeight: '100px'
	},
	cover: {
		width: 50,
		height: 50,
		objectFit: 'cover'
	},
	content: {
		padding: '10px 15px 0px 15px',
		marginBottom: -20,
		objectFit: 'cover',
		width: '100%'
	},
	cardHandle: {
		fontSize: '1em'
	},
	cardDate: {
		fontSize: '.7em',
		color: '#aaa',
		marginBottom: 10
	},
	noteBody: {
		margin: '2px auto 10px auto',
		color: 'black',
		'& :hover': {
			color: 'black',
			cursor: 'pointer'
		}
	},
	lock: {
		fontSize: '0.8rem',
		margin: '0 5px 0 0'
	}
});

class NotecardPersonal extends Component {
	constructor (props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick () {
		this.setState(() => window.localStorage.setItem('cardId', JSON.stringify(this.props.note.linkedCardId)));
		window.history.pushState(null, null, '/eol/starterpack');
	}

	render () {
		dayjs.extend(relativeTime);
		const {
			classes,
			linkedCardId,
			// chapter,
			note: { body, createdAt, userHandle, noteId },
			user: { authenticated, credentials: { handle } }
		} = this.props;
		// const linkedChapter =
		// 	linkedCardId < 5 && linkedCardId > 0
		// 		? 'care'
		// 		: linkedCardId <= 10 && linkedCardId > 0
		// 			? 'body'
		// 			: linkedCardId <= 15 && linkedCardId > 0
		// 				? 'ceremony'
		// 				: linkedCardId <= 20 && linkedCardId > 0
		// 					? 'treasures'
		// 					: linkedCardId <= 25 && linkedCardId > 0 ? 'legacy' : 'general';
		const deleteButton = authenticated && userHandle === handle ? <DeleteNote noteId={noteId} /> : null;

		// const isPublicMarkup = isPublic ? (
		// 	<PublicIcon color="primary" className={classes.lock} />
		// ) : (
		// 	<LockIcon color="primary" className={classes.lock} />
		// );

		return (
			<Card className={classes.card}>
				<CardContent className={classes.content}>
					<div onClick={() => this.props.changeCard(linkedCardId)}>
						{/* <Link
						to={{
							pathname: '/eol/starterpack',
							state: {
								linkedCardId: this.props.note.linkedCardId
							}
						}}
					> */}
						<Typography variant="body2" color="primary" className={classes.cardHandle}>
							{userHandle}
						</Typography>
						<Typography variant="h5" color="primary" className={classes.cardDate}>
							{/* {isPublicMarkup} */}

							{dayjs(createdAt).fromNow()}
						</Typography>

						<Typography variant="body2" className={classes.noteBody}>
							{this.props.body === '' || this.props.body === undefined ? body : this.props.body}
						</Typography>
						{/* </Link> */}
					</div>
					{deleteButton}
					<NoteDialog noteId={noteId} userHandle={userHandle} openDialog={this.props.openDialog} />
				</CardContent>
			</Card>
		);
	}
}

NotecardPersonal.propTypes = {
	user: PropTypes.object.isRequired,
	note: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	openDialog: PropTypes.bool
};

const mapStateToProps = (state) => ({
	user: state.user
});

export default connect(mapStateToProps, null)(withStyles(styles)(NotecardPersonal));
