import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
// import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import withStyles from '@material-ui/core/styles/withStyles';

import NotecardPersonal from '../components/note/NotecardPersonal';
import NotecardCircle from '../components/note/NotecardCircle';
import NoteSkeleton from '../util/NoteSkeleton.js';
import ProfileSkeleton from '../util/ProfileSkeleton.js';
// import StaticProfile from '../components/profile/StaticProfile';
import ApprovedMeCard from '../components/note/ApprovedMeCard';
import { viewUserData, selectTransition, getMyNotes } from '../redux/actions/dataActions';
import { getApprovedUsers, logoutUser, uploadImage } from '../redux/actions/userActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CircularProgress from '@material-ui/core/CircularProgress';

import careIcon from '../images/eol/careIcon.png';
import bodyIcon from '../images/eol/bodyIcon.png';
import ceremonyIcon from '../images/eol/ceremonyIcon.png';
import treasuresIcon from '../images/eol/treasuresIcon.png';
import legacyIcon from '../images/eol/legacyIcon.png';

const styles = (theme) => ({
	...theme.spreadThis,
	chapterHeader: {
		margin: 'auto 0 auto 0px',
		fontSize: '1.4rem'
	},
	expansionPanel: {
		backgroundColor: 'rgba(240,240,240,0.95)',
		padding: 0,
		margin: 0,
		border: '.5px solid white'
	},
	chapterCard: {
		height: '50px',
		width: '50px',
		objectFit: 'cover',
		borderRadius: '50%',
		border: '2px solid white'
	},
	explainer: {
		[theme.breakpoints.up('xs')]: {
			fontSize: '.9em',
			margin: '0 0px 8px 0px',
			lineHeight: 1.2
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '.7em',
			margin: '0 0px 5px 0px',
			lineHeight: 1.2
		}
	},
	container: {
		[theme.breakpoints.up('xs')]: {
			width: `calc(100% - ${drawerWidth}px -80px)`,
			// marginRight: drawerWidth,
			marginLeft: 440,
			// maxWidth: 1024,
			minWidth: 420
		},
		// [theme.breakpoints.down('sm')]: {
		// 	width: `calc(100% - 80px)`,
		// 	marginLeft: 80
		// },
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			marginLeft: 0,
			minWidth: 0
		}
	},
	containerDrawerMinimized: {
		[theme.breakpoints.up('xs')]: {
			width: `calc(100% - ${drawerWidth}px -80px)`,
			// marginRight: drawerWidth,
			marginLeft: 135
			// maxWidth: 1024
		},
		// [theme.breakpoints.down('sm')]: {
		// 	width: `calc(100% - 80px)`,
		// 	marginLeft: 80
		// },
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			marginLeft: 0
		}
	},
	mainContent: {
		[theme.breakpoints.up('xs')]: {
			width: `calc(100% - 40px)`,
			margin: '0 auto 0 auto'
		},
		[theme.breakpoints.down('xs')]: {
			width: `calc(100% - 20px)`,
			marginLeft: 0
		}
	},
	drawer: {
		[theme.breakpoints.up('md')]: {
			width: drawerWidth,
			flexShrink: 0
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
			flexShrink: 0
		},
		overflowX: 'hidden'
	},
	drawerPaperClosed: {
		width: 55,
		marginLeft: 80,
		// display: 'flex',
		background: 'linear-gradient(105deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)',
		overflowX: 'hidden'
	},
	drawerPaper: {
		width: 360,
		marginLeft: 80,
		// display: 'flex',
		background: 'linear-gradient(105deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)',
		overflowX: 'hidden'
	},
	drawerPaperMobile: {
		[theme.breakpoints.up('xs')]: {
			width: `calc(100% - 80px)`,
			marginLeft: 80
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			marginTop: 100,
			marginLeft: 0
		},
		height: '100%',
		// display: 'flex',
		background: 'linear-gradient(105deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)',
		overflowX: 'hidden'
	},
	drawerResponsive: {
		[theme.breakpoints.up('xs')]: {
			width: `calc(100% - 80px)`,
			height: 40,
			marginLeft: 440
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			height: 40,
			marginLeft: 0,
			top: 60
		},
		'& :hover': {
			cursor: 'pointer'
		}
	},
	header: {
		fontSize: '3.4rem',
		// color: 'white',
		textAlign: 'center',
		letterSpacing: '-0.07em',
		lineHeight: '1.1em',
		maxWidth: '100%',
		margin: '120px 20px 80px 0'
	},
	profileHandle: {
		color: 'rgba(0,0,0,1)',
		fontSize: '2.2rem',
		fontWeight: '200',
		letterSpacing: '-.05em',
		marginBottom: 10
	},
	profileDetails: {
		color: 'rgba(0,0,0,.5)',
		fontSize: '.85rem',
		letterSpacing: '-.03em'
	},
	imageWrapper: {
		textAlign: 'center',
		position: 'relative',
		'& :hover': {
			cursor: 'pointer'
		}
	},
	profileSwitcher: {
		'& :hover': {
			cursor: 'pointer'
		}
	}
});

const drawerWidth = 360;

class user extends Component {
	static defaultProps = {
		chaptersEOL: [
			{ name: 'care', cardId: 26, icon: careIcon, low: 0, high: 5 },
			{ name: 'body', cardId: 27, icon: bodyIcon, low: 5, high: 10 },
			{ name: 'ceremony', cardId: 28, icon: ceremonyIcon, low: 10, high: 15 },
			{ name: 'treasures', cardId: 29, icon: treasuresIcon, low: 15, high: 20 },
			{ name: 'legacy', cardId: 30, icon: legacyIcon, low: 20, high: 25 }
		]
		// chaptersCOVID: [
		// 	{ name: 'preparation', src: preparationIcon, low: 25, high: 30 },
		// 	{ name: 'separation', src: separationIcon, low: 30, high: 35 },
		// 	{ name: 'celebration', src: celebrationIcon, low: 35, high: 40 },
		// 	{ name: 'creation', src: creationIcon, low: 40, high: 45 },
		// 	{ name: 'stabilization', src: stabilizationIcon, low: 45, high: 50 }
		// ]
	};
	constructor (props) {
		super(props);
		this.state = {
			profile: { handle: this.props.match.params.handle },
			noteIdParam: null,
			drawerOpen: true,
			mobileOpen: false,
			approvedMe: [],
			approvedHere: false
		};
		this.props.getApprovedUsers();
	}

	componentDidMount () {
		this.props.selectTransition(this.props.match.params.transition);
		const handle = this.props.match.params.handle;
		const noteId = this.props.match.params.noteId;
		if (noteId) this.setState({ noteIdParam: noteId });
		this.props.viewUserData(handle);
		// axios
		// 	.get(`/user/${this.props.match.params.handle}`)
		// 	.then((res) => {
		// 		this.setState({
		// 			profile: res.data.user
		// 		});
		// 	})
		// 	.catch((err) => console.log(err));
	}

	componentWillReceiveProps (nextProps) {
		if (!nextProps.UI.loading) {
			this.updateApproved();
		}
	}

	handleLogout = () => {
		this.props.logoutUser();
	};

	handleImageChange = (event) => {
		const image = event.target.files[0];
		const formData = new FormData();
		formData.append('image', image, image.name);
		this.props.uploadImage(formData);
	};

	handleDrawerToggle = () => {
		this.setState({ mobileOpen: !this.state.mobileOpen });
	};

	updateApproved = () => {
		let updatedApprovedHere = false;
		let updatedApprovedMe = [];
		this.props.user.approved.map((approval) => {
			if (approval.approvedHandle === this.props.user.credentials.handle) {
				updatedApprovedMe.push({
					handle: approval.approverHandle,
					img: approval.approverImageUrl
				});
				if (approval.approverHandle === this.state.profile.handle) {
					updatedApprovedHere = true;
				}
			}
			return null;
		});
		this.setState({ approvedHere: updatedApprovedHere, approvedMe: updatedApprovedMe });
	};

	handleEditPicture = () => {
		const fileInput = document.getElementById('imageInput');
		fileInput.click();
	};

	render () {
		const {
			classes,
			data: { notes, otherProfile, loading },
			user: { credentials: { handle, imageUrl, createdAt }, authenticated },
			UI: { transition }
		} = this.props;
		const { noteIdParam } = this.state;
		// const visitorHandle = this.props.user.credentials.handle;
		let chapters = [];
		if (transition === 'eol') chapters = this.props.chaptersEOL;
		if (transition === 'covid') chapters = this.props.chaptersCOVID;

		if (
			this.props.user.approved.some((obj) => obj.approverHandle === handle || obj.approvedHandle === handle) &&
			this.state.approved === [] &&
			this.state.approvedMe === []
		) {
			this.updateApproved();
		}

		let approvedMeMarkup = this.state.approvedMe.map((a, i) => {
			if (a.handle !== this.state.profile.handle) {
				return (
					<div
						onClick={() => this.props.history.push(`/eol/users/${a.handle}`)}
						className={classes.profileSwitcher}
						key={i}
						style={{ marginRight: 10 }}
					>
						<ApprovedMeCard className={classes.profileSwitcher} handle={a.handle} img={a.img} />
					</div>
				);
			}
			else return null;
		});

		let profileMarkup =
			!loading && !this.props.data.loading ? authenticated ? this.state.profile.handle === handle ? (
				<Fragment>
					<Grid container item xs spacing={1}>
						<Grid item xs={12} className={classes.profileTop}>
							<div className={classes.profile}>
								<Tooltip
									placement="top"
									TransitionComponent={Zoom}
									title="click to change your profile picture"
								>
									<div className={classes.imageWrapper} onClick={this.handleEditPicture}>
										<img src={imageUrl} alt="profile" className="profile-image" />
										<input
											type="file"
											id="imageInput"
											onChange={this.handleImageChange}
											hidden="hidden"
										/>
									</div>
								</Tooltip>
								<hr />
								<div className="profile-details">
									<Typography
										variant="h5"
										className={classes.profileHandle}
										style={{ color: '#fff' }}
									>
										{handle}
									</Typography>
									<Typography
										variant="body2"
										className={classes.profileDetails}
										style={{ color: '#000' }}
									>
										Joined {dayjs(createdAt).format('MMM YYYY')}
									</Typography>
									<hr />
									<Button
										onClick={this.handleLogout}
										variant="contained"
										className={classes.noteButton}
										style={{
											fontSize: '.85em',
											background:
												'linear-gradient(165deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)',
											color: '#ffffff',
											letterSpacing: '-0.03em',
											borderRadius: '5px',
											border: 'none',
											margin: '40px auto 0px auto',
											padding: '2px auto 12px auto',
											width: '50%'
										}}
									>
										{!loading ? (
											'log out'
										) : (
											<CircularProgress size={20} className={classes.progress} />
										)}
									</Button>
								</div>
							</div>
						</Grid>
					</Grid>
				</Fragment>
			) : (
				<Fragment>
					<Grid container item xs>
						<div style={{ margin: '0px auto 0 auto', display: 'flex' }}>
							<Button
								onClick={() => this.props.history.push(`/eol/mycircle`)}
								style={{
									margin: '0 auto 0 auto',
									color: '#fff',
									letterSpacing: '.3em',
									fontSize: '.9em'
								}}
							>
								{!loading ? (
									'go to my circle'
								) : (
									<CircularProgress size={20} className={classes.progress} />
								)}
							</Button>
						</div>
						<Divider
							style={{
								color: 'rgba(0,0,0,.5)',
								width: '120%',
								position: 'absolute',
								top: 150
							}}
						/>
						<Grid item xs={12} className={classes.profileTop}>
							<div className={classes.profile}>
								<div className="image-wrapper">
									<img src={otherProfile.imageUrl} alt="profile" className="profile-image" />
								</div>
								<hr />
								<div className="profile-details">
									<Typography
										color="primary"
										className={classes.profileHandle}
										variant="h5"
										style={{ color: '#fff' }}
									>
										{otherProfile.handle}
									</Typography>
									<Typography
										variant="body2"
										className={classes.profileDetails}
										style={{ color: '#000', marginBottom: 60 }}
									>
										Joined {dayjs(otherProfile.createdAt).format('MMM YYYY')}
									</Typography>
									<hr />
									{approvedMeMarkup}
								</div>
							</div>
						</Grid>
					</Grid>
				</Fragment>
			) : (
				<Fragment>
					<Grid container item xs>
						<Grid item xs={12} className={classes.profileTop}>
							<div className={classes.profile}>
								<div className="image-wrapper">
									<img src={otherProfile.imageUrl} alt="profile" className="profile-image" />
								</div>
								<hr />
								<div className="profile-details">
									<Typography
										color="primary"
										className={classes.profileHandle}
										variant="h5"
										style={{ color: '#fff' }}
									>
										{otherProfile.handle}
									</Typography>
									<Typography
										variant="body2"
										className={classes.profileDetails}
										style={{ color: '#000', marginBottom: 60 }}
									>
										Joined {dayjs(otherProfile.createdAt).format('MMM YYYY')}
									</Typography>
									<hr />
								</div>
							</div>
						</Grid>
					</Grid>
					<Paper
						className={classes.paper}
						style={{ marginTop: 20, borderRadius: '10px', border: '2px solid #ccc' }}
					>
						<Typography variant="body2" align="center" style={{ margin: '20px 20px 0 20px' }}>
							No profile found, please sign in
						</Typography>
						<div className={classes.buttons}>
							<Button
								variant="contained"
								style={{
									background:
										'linear-gradient(165deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)'
								}}
								component={Link}
								to="/login"
							>
								Log In
							</Button>
							<Button
								variant="contained"
								style={{
									background: 'linear-gradient(105deg,rgba(15, 135, 151, 1) 0%, #01508d 100%'
								}}
								component={Link}
								to="/signup"
							>
								Sign Up
							</Button>
						</div>
					</Paper>
				</Fragment>
			) : (
				<ProfileSkeleton />
			);

		const notesMarkup =
			this.state.profile.handle === handle
				? this.props.chaptersEOL.map((chapters, i) => {
						let chapter = chapters.name;
						let low = chapters.low;
						let high = chapters.high;
						let icon = chapters.icon;

						return (
							<Fragment key={i}>
								{notes !== null ? notes.some(
									(obj) => obj.linkedCardId > low && obj.linkedCardId <= high
								) ? (
									<Grid container alignItems="center" style={{ margin: '20px 0 10px 0' }}>
										{/* <Grid item xs={2}>
							<img alt={`${chapter} card`} src={imgSrc} className={classes.chapterCard} />
						</Grid> */}
										{this.props.user.loading ||
										this.props.data.loading ||
										this.props.UI.loading ? null : (
											<Grid container item xs spacing={2} alignItems="center">
												<Grid item>
													<img
														src={icon}
														alt={chapter}
														style={{
															height: '30px',
															width: '30px'
														}}
													/>
												</Grid>
												<Grid item>
													<Typography variant="h5" className={classes.chapterHeader}>
														{chapter}
													</Typography>
												</Grid>
											</Grid>
										)}
									</Grid>
								) : null : null}

								<Grid container style={{ margin: '0' }}>
									{loading ? (
										<Grid item xs style={{ marginTop: 40 }}>
											<NoteSkeleton />
										</Grid>
									) : notes === null ? null : (
										notes.map((note) => {
											if (note.linkedCardId > low && note.linkedCardId <= high) {
												return (
													<Grid item xs={12} key={note.noteId}>
														<NotecardPersonal
															key={note.noteId}
															note={note}
															linkedCardId={note.linkedCardId}
														/>
													</Grid>
												);
											}
											else return null;
										})
									)}
								</Grid>
							</Fragment>
						);
					})
				: this.state.approvedHere
					? this.props.chaptersEOL.map((chapters, i) => {
							let chapter = chapters.name;
							let low = chapters.low;
							let high = chapters.high;
							let icon = chapters.icon;

							return (
								<Fragment key={i}>
									{notes !== null ? notes.some(
										(obj) => obj.linkedCardId > low && obj.linkedCardId <= high
									) ? (
										<Grid container alignItems="center" style={{ margin: '20px 0 10px 0' }}>
											{/* <Grid item xs={2}>
							<img alt={`${chapter} card`} src={imgSrc} className={classes.chapterCard} />
						</Grid> */}
											<Grid container item xs spacing={2} alignItems="center">
												<Grid item>
													<img
														src={icon}
														alt={chapter}
														style={{
															height: '30px',
															width: '30px'
														}}
													/>
												</Grid>
												<Grid item>
													<Typography variant="h5" className={classes.chapterHeader}>
														{chapter}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									) : null : null}

									<Grid container style={{ margin: '0' }}>
										{loading ? (
											<Grid item xs style={{ marginTop: 40 }}>
												<NoteSkeleton />
											</Grid>
										) : notes === null ? null : (
											notes.map((note) => {
												if (note.linkedCardId > low && note.linkedCardId <= high) {
													return (
														<Grid item xs={12} key={note.noteId}>
															<NotecardCircle
																key={note.noteId}
																note={note}
																linkedCardId={note.linkedCardId}
															/>
														</Grid>
													);
												}
												else return null;
											})
										)}
									</Grid>
								</Fragment>
							);
						})
					: this.props.chaptersEOL.map((chapters, i) => {
							let chapter = chapters.name;
							let low = chapters.low;
							let high = chapters.high;
							let icon = chapters.icon;

							return (
								<Fragment key={i}>
									{notes !== null ? notes.some(
										(obj) => obj.linkedCardId > low && obj.linkedCardId <= high && obj.isPublic
									) ? (
										<Grid container alignItems="center" style={{ margin: '20px 0 10px 0' }}>
											{/* <Grid item xs={2}>
							<img alt={`${chapter} card`} src={imgSrc} className={classes.chapterCard} />
						</Grid> */}
											<Grid container item xs spacing={2} alignItems="center">
												<Grid item>
													<img
														src={icon}
														alt={chapter}
														style={{
															height: '30px',
															width: '30px'
														}}
													/>
												</Grid>
												<Grid item>
													<Typography variant="h5" className={classes.chapterHeader}>
														{chapter}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									) : null : null}

									<Grid container style={{ margin: 0 }}>
										{loading ? (
											<Grid item xs style={{ marginTop: 40 }}>
												<NoteSkeleton />
											</Grid>
										) : notes === null ? null : (
											notes.map((note) => {
												if (note.linkedCardId > low && note.linkedCardId <= high && note.isPublic) {
													return (
														<Grid item xs={12} key={note.noteId}>
															<NotecardCircle
																key={note.noteId}
																note={note}
																linkedCardId={note.linkedCardId}
															/>
														</Grid>
													);
												}
												else return null;
											})
										)}
									</Grid>
								</Fragment>
							);
						});

		let wishesTitleMarkup =
			this.props.user.loading || this.props.data.loading ? null : this.state.profile.handle === handle ? (
				<Fragment>
					<Grid item xs={12} className={classes.header} style={{ margin: '60px 0 60px 0' }}>
						<Typography variant="body2" className={classes.header} style={{ margin: '60px 0 -10px 0' }}>
							my wishes
						</Typography>
					</Grid>
				</Fragment>
			) : this.state.approvedHere ? (
				<Fragment>
					<Typography variant="body2" className={classes.header} style={{ margin: '60px 0 20px 0' }}>{`${this
						.state.profile.handle}'s wishes`}</Typography>
					<Typography
						variant="body2"
						className={classes.explainer}
						style={{ margin: '0px 0 60px 0', textAlign: 'center', color: 'rgba(0,0,0,.5)' }}
					>{`You are in ${this.state.profile
						.handle}'s circle so you can see their public and private wishes`}</Typography>
				</Fragment>
			) : (
				<Typography variant="body2" className={classes.header} style={{ margin: '60px 0 60px 0' }}>{`${this
					.state.profile.handle}'s public wishes`}</Typography>
			);

		let profileDrawerMarkup = (
			<nav className={classes.drawer}>
				<Hidden xsDown implementation="css">
					<Drawer
						transitionDuration={0}
						variant="persistent"
						anchor="left"
						open={this.state.drawerOpen}
						// onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaper
						}}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}
					>
						<Grid
							container
							item
							xs={12}
							spacing={1}
							justify="center"
							alignItems="flex-start"
							style={{ padding: '0' }}
						>
							<Grid container spacing={1} justify="center" style={{ margin: '0 auto 0 auto' }}>
								<Grid
									container
									item
									xs={12}
									spacing={1}
									justify="center"
									alignItems="stretch"
									style={{
										margin: '7px 0 auto 20px',
										width: '100%'
									}}
								>
									<Grid
										item
										xs={12}
										style={{
											margin: '0 0 10px 0'
										}}
									>
										<Typography
											className={classes.explainer}
											style={{
												color: 'rgba(0,0,0,.5)',
												textAlign: 'left',
												width: 280
											}}
										>
											<span style={{ fontWeight: '600', color: 'rgba(0,0,0,1)' }}>
												Instructions:{' '}
											</span>Every person has their own page showing their public responses. Only
											people in their circle can also see their private responses. Copy and paste
											the URL in your browser to share.
										</Typography>
									</Grid>
									<Divider
										style={{
											color: 'rgba(0,0,0,.5)',
											width: '120%',
											position: 'absolute',
											top: 86
										}}
									/>
									<ChevronLeftIcon
										color="primary"
										style={{ position: 'absolute', top: 10, right: 10, color: '#fff' }}
										onClick={() => this.setState({ drawerOpen: false })}
									/>
								</Grid>
								{profileMarkup}
							</Grid>
						</Grid>
					</Drawer>
					<Drawer
						transitionDuration={0}
						variant="persistent"
						anchor="left"
						open={!this.state.drawerOpen}
						onClick={() => this.setState({ drawerOpen: true })}
						// onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaperClosed
						}}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}
					>
						<Grid
							container
							item
							xs={12}
							spacing={1}
							justify="center"
							alignItems="flex-start"
							style={{ padding: '0' }}
						>
							<Grid container spacing={1} justify="center" style={{ margin: '0 auto 0 auto' }}>
								<Grid
									container
									item
									xs={12}
									spacing={1}
									justify="center"
									alignItems="stretch"
									style={{
										margin: '10px 0 auto 20px',
										width: '100%'
									}}
								>
									<Grid
										item
										xs={12}
										style={{
											margin: '0 0 10px 0'
										}}
									/>
									<ChevronRightIcon
										color="primary"
										style={{ position: 'absolute', top: 10, right: 15 }}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Drawer>
				</Hidden>
				<Hidden smUp implementation="css">
					<AppBar
						position="fixed"
						color="primary"
						className={classes.drawerResponsive}
						onClick={this.handleDrawerToggle}
					>
						<BottomNavigation
							className={classes.root}
							style={{
								background:
									'linear-gradient(165deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)'
							}}
						>
							{!this.state.mobileOpen ? (
								<BottomNavigationAction label="SEE MORE" showLabel style={{ color: '#fff' }} />
							) : (
								<BottomNavigationAction label="BACK" showLabel style={{ color: '#fff' }} />
							)}
						</BottomNavigation>
					</AppBar>
					<Drawer
						// container={container}
						variant="temporary"
						anchor="top"
						open={this.state.mobileOpen}
						onClose={this.handleDrawerToggle}
						classes={{
							paper: classes.drawerPaperMobile
						}}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}
					>
						<Grid
							container
							item
							xs={12}
							spacing={1}
							justify="center"
							alignItems="flex-start"
							style={{ padding: '0 20px 0 20px', height: '80%' }}
						>
							<Grid
								container
								spacing={1}
								justify="center"
								style={{
									maxWidth: 320,
									margin: '0 20px 0 20px'
								}}
							>
								{/* <Grid
									container
									item
									xs={12}
									spacing={1}
									justify="center"
									alignItems="stretch"
									style={
										this.state.activeChapter === null || this.state.activeChapter === '' ? (
											{ transform: 'translateY(200px)' }
										) : null
									}
								>
									{chapterCardsMarkup}
								</Grid> */}

								<Grid
									container
									item
									xs={12}
									spacing={1}
									justify="center"
									alignItems="stretch"
									style={{
										margin: '20px 20px 0 20px',
										height: '100%'
									}}
								>
									<Grid
										item
										xs={12}
										style={{
											margin: '0 0 10px 0'
										}}
									>
										<Typography
											className={classes.explainer}
											style={{
												color: 'rgba(255,255,255,.5)',
												textAlign: 'left'
											}}
										>
											<span style={{ fontWeight: '600', color: 'rgba(255,255,255,1)' }}>
												Instructions:{' '}
											</span>Every person has their own page showing their public responses. Copy
											and paste the URL in your browser to share.
										</Typography>
									</Grid>
									{profileMarkup}
								</Grid>
							</Grid>
						</Grid>
					</Drawer>
				</Hidden>
			</nav>
		);

		return (
			<div className={this.state.drawerOpen ? classes.container : classes.containerDrawerMinimized}>
				{profileDrawerMarkup}
				<Grid
					container
					justify="center"
					direction="column"
					spacing={2}
					className={classes.mainContent}
					style={{ maxWidth: '780px', margin: 'auto' }}
				>
					<Grid item xs={12} style={{ marginTop: 20 }}>
						{wishesTitleMarkup}
						{notesMarkup}
					</Grid>
					<div style={{ height: 80 }} />
				</Grid>
			</div>
		);
	}
}

user.propTypes = {
	getApprovedUsers: PropTypes.func.isRequired,
	uploadImage: PropTypes.func.isRequired,
	viewUserData: PropTypes.func.isRequired,
	getMyNotes: PropTypes.func.isRequired,
	selectTransition: PropTypes.func.isRequired,
	logoutUser: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	data: state.data,
	user: state.user,
	UI: state.UI
});

export default connect(mapStateToProps, {
	viewUserData,
	selectTransition,
	getApprovedUsers,
	logoutUser,
	uploadImage,
	getMyNotes
})(withStyles(styles)(user));
