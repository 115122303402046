import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { viewUserData } from '../../redux/actions/dataActions';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
// import CardMedia from '@material-ui/core/CardMedia';

const styles = (theme) => ({
	...theme.spreadThis,
	card: {
		position: 'relative',
		display: 'flex',
		width: '100%',
		margin: 0,
		background: 'linear-gradient(165deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)'
	},
	avatars: {
		width: 50,
		height: 50,
		objectFit: 'cover',
		borderRadius: '50%',
		border: '2px solid #fff',
		display: 'flex',
		margin: 'auto'
	},
	content: {
		padding: '5px 5px 5px 5px',
		marginBottom: -20,
		objectFit: 'cover',
		width: '90%'
	},
	cardChapter: {
		fontSize: '1.2em',
		margin: '10px 0 5px 0',
		// fontWeight: '300',
		letterSpacing: '-0.03em'
	},
	cardHandle: {
		fontSize: '.8em',
		textAlign: 'center',
		color: 'rgba(0,0,0,0.5)',
		justify: 'center',
		fontWeight: '400',
		marginTop: -5
	},
	cardDate: {
		fontSize: '.7em',
		color: '#aaa'
	}
});

class ApprovedMeCard extends Component {
	componentDidMount () {}
	render () {
		const { classes, handle, img } = this.props;

		return (
			<Grid container spacing={0} alignItems="center" justify="center">
				<Grid item xs>
					<img src={img} className={classes.avatars} alt={`${handle}'s profile pic`} />
				</Grid>
				<Grid item xs={9} style={{ width: '100%' }}>
					<Card className={classes.card}>
						{/* <CardMedia
							component="img"
							alt={`${handle}'s profile pic`}
							image={img}
							title={`${handle}'s logo`}
							className={classes.avatars}
						/> */}
						<CardContent className={classes.content} style={{ margin: 'auto', padding: '5px 0 5px 0' }}>
							<Typography
								variant="body1"
								className={classes.cardChapter}
								style={{ marginTop: 0, color: 'rgba(255,255,255,1)' }}
							>
								{handle}
								<ChevronRightIcon
									color="primary"
									style={{ position: 'absolute', right: 7, top: 7, color: '#fff' }}
								/>
							</Typography>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		);
	}
}

ApprovedMeCard.propTypes = {
	data: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	viewUserData: PropTypes.func.isRequired
};

const mapActionsToProps = {
	viewUserData
};

const mapStateToProps = (state) => ({
	user: state.user,
	data: state.data,
	UI: state.UI
});

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(ApprovedMeCard));
