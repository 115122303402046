import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import MyButton from '../../util/MyButton';
import LikeButton from './LikeButton';
import DeleteNote from './DeleteNote';
import NoteDialog from './NoteDialog';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ChatIcon from '@material-ui/icons/ChatBubbleOutline';

const styles = (theme) => ({
	...theme.spreadThis,
	card: {
		position: 'relative',
		display: 'flex',
		marginBottom: 10
	},
	avatars: {
		width: 50,
		height: 50,
		objectFit: 'cover',
		borderRadius: '50%',
		border: '2px solid #eeeeee',
		display: 'flex',
		margin: 'auto auto -5px auto'
	},
	content: {
		padding: '10px 15px 0px 15px',
		marginBottom: -20,
		objectFit: 'cover',
		width: '90%'
	},
	cardChapter: {
		fontSize: '1em',
		margin: '10px 0 5px 0'
	},
	cardHandle: {
		fontSize: '.8em',
		textAlign: 'center',
		color: 'rgba(0,0,0,0.5)',
		justify: 'center',
		fontWeight: '400',
		marginTop: -5
	},
	cardDate: {
		fontSize: '.7em',
		color: '#aaa'
	},
	likeWord: {
		margin: 'auto 10px 0 -5px',
		color: '#aaa',
		fontSize: '.85em'
	},
	likesAndComments: {
		transform: 'translateX(-12px)'
	}
});

class Notecard extends Component {
	state = { chapter: null };
	static defaultProps = {
		cardsEOL: [
			{
				id: 1,
				chapter: 'care',
				tag: 'What environment would you want for your care?'
			},
			{
				id: 2,
				chapter: 'care',
				tag: 'Who would be your legal guardian?'
			},
			{
				id: 3,
				chapter: 'care',
				tag: 'Do you want to be resuscitated?'
			},
			{
				id: 4,
				chapter: 'care',
				tag: 'What to do if terminally ill or in a coma?'
			},
			{
				id: 5,
				chapter: 'care',
				tag: 'Who will be your "agent"?'
			},
			{
				id: 6,
				chapter: 'body',
				tag: 'What should become of your body?'
			},
			{
				id: 7,
				chapter: 'body',
				tag: 'Where should you be laid to rest?'
			},
			{
				id: 8,
				chapter: 'body',
				tag: 'What to inscribe at your memorial site?'
			},
			{
				id: 9,
				chapter: 'body',
				tag: 'Should your organs be donated?'
			},
			{
				id: 10,
				chapter: 'body',
				tag: 'Who should be responsible for handling your remains?'
			},
			{
				id: 11,
				chapter: 'ceremony',
				tag: 'What should happen at your funeral?'
			},
			{
				id: 12,
				chapter: 'ceremony',
				tag: 'Where should your funeral take place?'
			},
			{
				id: 13,
				chapter: 'ceremony',
				tag: 'What details do you picture for your funeral?'
			},
			{
				id: 14,
				chapter: 'ceremony',
				tag: 'What should be shared at your funeral?'
			},
			{
				id: 15,
				chapter: 'ceremony',
				tag: 'Who should organize your funeral?'
			},
			{
				id: 16,
				chapter: 'treasures',
				tag: 'What valuables do you want to pass down?'
			},
			{
				id: 17,
				chapter: 'treasures',
				tag: 'Who will take care of your kids and pets?'
			},
			{
				id: 18,
				chapter: 'treasures',
				tag: 'How will you distribute your estate?'
			},
			{
				id: 19,
				chapter: 'treasures',
				tag: 'What is your plan for your biggest assets?'
			},
			{
				id: 20,
				chapter: 'treasures',
				tag: 'Who will be your "executor"?'
			},
			{
				id: 21,
				chapter: 'legacy',
				tag: 'How will your life story be preserved?'
			},
			{
				id: 22,
				chapter: 'legacy',
				tag: 'What causes can people support to honor you?'
			},
			{
				id: 23,
				chapter: 'legacy',
				tag: 'Who do you still want to settle up with?'
			},
			{
				id: 24,
				chapter: 'legacy',
				tag: 'Who would you like to leave a letter or video to?'
			},
			{
				id: 25,
				chapter: 'legacy',
				tag: 'Who do you hope will carry on your legacy?'
			}
		]
	};

	componentDidMount () {
		this.setState({ chapter: this.props.cardsEOL[this.props.note.linkedCardId - 1].chapter });
	}

	render () {
		dayjs.extend(relativeTime);
		const {
			classes,
			note: { body, createdAt, userImage, userHandle, noteId, likeCount, commentCount },
			user: { authenticated, credentials: { handle } },
			UI: { transition }
		} = this.props;

		const deleteButton = authenticated && userHandle === handle ? <DeleteNote noteId={noteId} /> : null;
		return (
			<Grid
				container
				spacing={1}
				// style={{ margin: '0 10px 0 20px' }}
			>
				<Grid
					container
					item
					component={Link}
					to={`/${transition}/users/${userHandle}`}
					justify="center"
					alignItems="center"
					style={{ width: '70px' }}
				>
					<Grid item xs={12}>
						<img src={userImage} className={classes.avatars} alt={`@${userHandle}'s profile pic`} />
						<br />
						<Typography variant="body2" color="primary" className={classes.cardHandle}>
							{userHandle}
						</Typography>
					</Grid>
				</Grid>
				<Grid item style={{ width: `calc(100% - 70px)` }}>
					<Card className={classes.card}>
						<CardContent className={classes.content}>
							<Link
								to={{
									pathname: `/${this.props.UI.transition}/mywishes`,
									state: {
										linkedCardId: this.props.note.linkedCardId
									}
								}}
							>
								<Typography variant="h5" color="primary" className={classes.cardChapter}>
									{this.props.cardsEOL[this.props.note.linkedCardId - 1].tag}{' '}
									<span className={classes.cardDate}>({dayjs(createdAt).fromNow()})</span>
								</Typography>
							</Link>

							{deleteButton}
							<Typography variant="body2" style={{ marginBottom: 0 }}>
								{body}
							</Typography>
							<div className={classes.likesAndComments}>
								<LikeButton noteId={noteId} />
								<span className={classes.likeWord}>{likeCount} likes</span>

								<ChatIcon color="primary" fontSize="small" style={{ marginRight: 12 }} />

								<span className={classes.likeWord}>{commentCount} comments</span>
							</div>
							<NoteDialog noteId={noteId} userHandle={userHandle} openDialog={this.props.openDialog} />
						</CardContent>
						{/* <CardMedia
					component="img"
					alt="Profile Image"
					className={classes.avatars}
					image={userImage}
					title="Profile Image"
				/> */}
					</Card>
				</Grid>
			</Grid>
		);
	}
}

Notecard.propTypes = {
	user: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
	note: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	openDialog: PropTypes.bool
};

const mapStateToProps = (state) => ({
	user: state.user,
	UI: state.UI
});

export default connect(mapStateToProps, null)(withStyles(styles)(Notecard));
