import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import Notifications from './Notifications';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import List from '@material-ui/core/List';
import Toolbar from '@material-ui/core/Toolbar';
// import Box from '@material-ui/core/Box';
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
// import MenuIcon from '@material-ui/icons/Menu';
// import ListItem from '@material-ui/core/ListItem';
// import MailIcon from '@material-ui/icons/Mail';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import Notifications from './Notifications';
import NoImg from '../../images/no-img.jpg';
import AfterLogo from '../../images/fab.png';
import afterWordWhite from '../../images/afterwordwhite.png';
import navWishes from '../../images/navWishes.png';
import navCircle from '../../images/navCircle.png';
import navExplore from '../../images/navExplore.png';
// import navWishesBlank from '../../images/navWishesBlank.png';
// import navCircleBlank from '../../images/navCircleBlank.png';
// import navExploreBlank from '../../images/navExploreBlank.png';
// import MenuIcon from '@material-ui/icons/Menu';
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';

const drawerWidth = 80;

const styles = (theme) => ({
	...theme.spreadThis,
	root: {
		display: 'flex'
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			flexShrink: 0
		},
		overflowX: 'hidden'
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none'
		}
	},
	drawerPaper: {
		width: drawerWidth,
		overflowX: 'hidden',
		// display: 'flex',
		// background: 'linear-gradient(105deg,rgba(179, 215, 182, 1) 0%, rgba(15, 135, 151, 1) 100%)'
		background: 'linear-gradient(105deg, rgba(45, 45, 45, 1) 0%, rgba(0, 0, 0, 1) 100%)'
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	avatars: {
		width: 38,
		height: 38,
		objectFit: 'cover',
		borderRadius: '50%',
		border: '2px solid white'
	},
	icons: {
		width: 48,
		height: 48,
		objectFit: 'cover'
	},
	afterLogo: {
		width: '30px',
		borderRadius: '50%',
		margin: '16px 0 0 0',
		justifyContent: 'center'
	},
	afterLogoMobile: {
		width: '36px',
		borderRadius: '50%',
		margin: '0',
		padding: 1,
		justifyContent: 'center'
	},
	afterWord: {
		width: '100%',
		padding: '8px 20px 16px 20px',
		justifyContent: 'center'
	},
	navStyles: {
		width: drawerWidth,
		display: 'flex',
		justifyContent: 'center'
	},
	navStylesOff: {
		width: drawerWidth,
		display: 'flex',
		justifyContent: 'center',
		opacity: 0.2,
		'& :hover': {
			cursor: 'not-allowed'
		}
	},
	grow: {
		flexGrow: 1
	},
	appBar: {
		[theme.breakpoints.up('sm')]: {
			width: '100%'
		}
	}
});

class NavbarLeft extends Component {
	constructor (props) {
		super(props);
		this.state = { mobileOpen: false };
	}

	render () {
		const { classes, user: { credentials: { imageUrl, handle }, loading } } = this.props;
		let profilePic = !loading ? (
			<img src={imageUrl} className={classes.avatars} alt={handle} />
		) : (
			<img src={NoImg} className={classes.avatars} alt={handle} />
		);

		// const handleDrawerToggle = () => {
		// 	this.setState({ mobileOpen: !this.state.mobileOpen });
		// };
		const handleDrawerShut = () => {
			this.setState({ mobileOpen: false });
		};

		let wishesIcon = navWishes;
		let circleIcon = navCircle;
		let exploreIcon = navExplore;

		const drawer = (
			<div style={{ flexGrow: 1 }}>
				<div style={{ marginBottom: 'auto' }}>
					<div className={classes.navStyles} onClick={handleDrawerShut}>
						<Link to={`/eol/mywishes`} aria-label="open starter pack">
							<img alt="after icon" src={AfterLogo} className={classes.afterLogo} />
						</Link>
					</div>
					<Hidden smUp implementation="css">
						<div style={{ height: 16 }} />
					</Hidden>
					<Hidden xsDown implementation="css">
						<div onClick={handleDrawerShut}>
							<Link to={`/eol/mywishes`} aria-label="open starter pack">
								<img alt="after logo" src={afterWordWhite} className={classes.afterWord} />
							</Link>
						</div>
					</Hidden>
					<Divider style={{ color: '#fff' }} />
					{/* <div style={{ width: '100%' }}> */}
					{/* // p={1}
				// m={1} */}
					<List>
						<Link to={`/eol/mywishes`} aria-label="open starter pack" onClick={handleDrawerShut}>
							<ListItemIcon
								className={this.props.user.authenticated ? classes.navStyles : classes.navStylesOff}
							>
								<IconButton
									edge="end"
									aria-label="menu"
									style={{ color: 'rgba(0,0,0,0.3)', margin: '35px auto -5px auto', padding: 0 }}
								>
									<img src={wishesIcon} className={classes.icons} alt={'me'} />
								</IconButton>
							</ListItemIcon>
							<ListItemText
								disableTypography
								primary={'my wishes'}
								className={this.props.user.authenticated ? classes.navStyles : classes.navStylesOff}
								style={{ color: 'rgba(255,255,255,1)', fontSize: '.85em' }}
							/>
						</Link>
						<Link to={`/eol/mycircle`} aria-label="open starter pack" onClick={handleDrawerShut}>
							<ListItemIcon
								className={this.props.user.authenticated ? classes.navStyles : classes.navStylesOff}
							>
								<IconButton
									edge="end"
									aria-label="menu"
									style={{ color: 'rgba(0,0,0,0.3)', margin: '20px auto -5px auto', padding: 0 }}
								>
									<img src={circleIcon} className={classes.icons} alt={'me'} />
								</IconButton>
							</ListItemIcon>
							<ListItemText
								disableTypography
								primary={'my circle'}
								className={this.props.user.authenticated ? classes.navStyles : classes.navStylesOff}
								style={{ color: 'rgba(255,255,255,1)', fontSize: '.85em' }}
							/>
						</Link>
						<Link to={`/eol/explore`} aria-label="explore" onClick={handleDrawerShut}>
							<ListItemIcon className={classes.navStyles}>
								<IconButton
									edge="end"
									aria-label="menu"
									style={{ color: 'rgba(0,0,0,0.3)', margin: '20px auto -5px auto', padding: 0 }}
								>
									<img src={exploreIcon} className={classes.icons} alt={'me'} />
								</IconButton>
							</ListItemIcon>
							<ListItemText
								disableTypography
								primary={'explore'}
								className={classes.navStyles}
								style={{ color: 'rgba(255,255,255,1)', fontSize: '.85em' }}
							/>
						</Link>
					</List>
				</div>
				{/* <Divider /> */}
				{/* <List>
					{[
						'All mail',
						'Trash',
						'Spam'
					].map((text, index) => (
						<ListItem button key={text}>
							<ListItemText primary={text} />
						</ListItem>
					))}
				</List> */}
				{/* <div className={classes.grow} /> */}
				<Hidden xsDown implementation="css">
					<div className={this.props.user.authenticated ? classes.navStyles : classes.navStylesOff}>
						<IconButton
							edge="end"
							aria-label="menu"
							// style={{
							// 	color: 'rgba(0,0,0,0.3)',
							// 	// margin: '10px auto auto auto',
							// 	padding: 0
							// 	// alignSelf: 'flex-end'
							// }}
							style={{ position: 'absolute', bottom: 10 }}
						>
							<a href={`/eol/users/${handle}`}>{profilePic}</a>
						</IconButton>
					</div>
				</Hidden>
			</div>
		);

		const navbar = (
			<Fragment>
				{this.props.user.authenticated ? (
					<Fragment>
						<AppBar
							position="fixed"
							style={{
								background: 'linear-gradient(105deg, rgba(35, 35, 35, 1) 0%, rgba(0, 0, 0, 1) 100%)'
							}}
							className={classes.appBar}
						>
							<Toolbar className={classes.root} justify="space-between">
								{/* <div onClick={handleDrawerToggle}>
									<MenuIcon style={{ color: 'rgba(0,0,0,0.3)', margin: 'auto' }} />
								</div> */}
								{/* <div className={classes.section}>
									<IconButton
										edge="start"
										aria-label="menu"
										component={Link}
										to={`/eol/users/${handle}`}
										// className={classes.icons}
										style={{ color: 'rgba(0,0,0,0.3)', margin: 'auto' }}
									>
										<img alt="after icon" src={AfterLogo} className={classes.afterLogoMobile} />
									</IconButton>
								</div>
								<div className={classes.grow} /> */}

								<div className={classes.section}>
									<Link
										to={`/eol/mywishes`}
										aria-label="open starter pack"
										onClick={handleDrawerShut}
									>
										<IconButton
											edge="end"
											aria-label="menu"
											style={{
												color: 'rgba(0,0,0,0.3)',
												margin: '0 5px 0 5px',
												padding: 0
											}}
										>
											<img src={wishesIcon} className={classes.icons} alt={'me'} />
										</IconButton>
									</Link>
									<Link
										to={`/eol/mycircle`}
										aria-label="open starter pack"
										onClick={handleDrawerShut}
									>
										<IconButton
											edge="end"
											aria-label="menu"
											style={{
												color: 'rgba(0,0,0,0.5)',
												margin: '0 5px 0 5px',
												padding: 0
											}}
										>
											<img src={circleIcon} className={classes.icons} alt={'me'} />
										</IconButton>
									</Link>
									<Link to={`/eol/explore`} aria-label="explore" onClick={handleDrawerShut}>
										<IconButton
											edge="end"
											aria-label="menu"
											style={{
												color: 'rgba(0,0,0,0.5)',
												margin: '0 5px 0 5px',
												padding: 0
											}}
										>
											<img src={exploreIcon} className={classes.icons} alt={'me'} />
										</IconButton>
									</Link>
								</div>
								<div className={classes.grow} />
								<div className={classes.section}>
									{/* <Notifications /> */}

									<IconButton
										edge="end"
										aria-label="menu"
										component={Link}
										to={`/eol/users/${handle}`}
										// className={classes.icons}
										style={{ color: 'rgba(0,0,0,0.3)', margin: 'auto' }}
									>
										{profilePic}
									</IconButton>
								</div>
							</Toolbar>
						</AppBar>
						<div style={{ height: 70 }} />
					</Fragment>
				) : // <AppBar position="static" color="primary">
				// <Toolbar className={classes.root} justify="space-between">
				// 	<div className={classes.grow} />
				// 	<div className={classes.section}>
				// 		<Button
				// 			edge="end"
				// 			aria-label="log in"
				// 			component={Link}
				// 			to="/login"
				// 			variant="outlined"
				// 			// className={classes.links}
				// 			style={{
				// 				color: 'rgba(0,0,0,0.3)',
				// 				margin: 'auto',
				// 				letterSpacing: '.3em',
				// 				fontSize: '0.85em'
				// 			}}
				// 		>
				// 			Log In
				// 		</Button>
				// 	</div>
				// </Toolbar>
				// </AppBar>
				null}
			</Fragment>
		);

		return (
			<div className={classes.root}>
				<CssBaseline />
				{/* {this.props.user.authenticated ? ( */}
				<Fragment>
					<nav className={classes.drawer} aria-label="navigation">
						{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
						<Hidden smUp implementation="css">
							{navbar}
							{/* <Drawer
									// container={container}
									variant="temporary"
									anchor={'left'}
									open={this.state.mobileOpen}
									onClose={handleDrawerToggle}
									classes={{
										paper: classes.drawerPaper
									}}
									ModalProps={{
										keepMounted: true // Better open performance on mobile.
									}}
								>
									{drawer}
								</Drawer> */}
						</Hidden>
						<Hidden xsDown implementation="css">
							<Drawer
								classes={{
									paper: classes.drawerPaper
								}}
								variant="permanent"
								open
							>
								{drawer}
							</Drawer>
						</Hidden>
					</nav>
				</Fragment>
				{/* ) : null} */}
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	user: state.user,
	UI: state.UI
});
export default connect(mapStateToProps)(withStyles(styles)(NavbarLeft));
