import {
	SET_USER,
	SET_ERRORS,
	SET_LIKES,
	SET_APPROVED,
	POST_APPROVED,
	DELETE_APPROVED,
	CLEAR_ERRORS,
	LOADING_UI,
	STOP_LOADING_UI,
	SET_UNAUTHENTICATED,
	LOADING_USER,
	MARK_NOTIFICATIONS_READ
} from '../types';
import axios from 'axios';

export const loginUser = (userData, history) => (dispatch) => {
	dispatch({ type: LOADING_UI });
	axios
		.post('/login', userData)
		.then((res) => {
			setAuthorizationHeader(res.data.token);
			dispatch(getUserData());
			dispatch({ type: CLEAR_ERRORS });
			history.push('/eol/mywishes');
		})
		.catch((err) => {
			dispatch({
				type: SET_ERRORS,
				payload: err.response.data
			});
		});
};

export const signupUser = (newUserData, history) => (dispatch) => {
	dispatch({ type: LOADING_UI });
	axios
		.post('/signup', newUserData)
		.then((res) => {
			setAuthorizationHeader(res.data.token);
			dispatch(getUserData());
			dispatch({ type: CLEAR_ERRORS });
			history.push('/eol/mywishes');
		})
		.catch((err) => {
			dispatch({
				type: SET_ERRORS,
				payload: err.response.data
			});
		});
};

export const passwordReset = (email) => (dispatch) => {
	dispatch({ type: LOADING_UI });
	axios
		.post('/passwordReset', email)
		.then(() => {
			dispatch({ type: STOP_LOADING_UI });
		})
		.catch((err) => {
			dispatch({
				type: SET_ERRORS,
				payload: err.response.data
			});
		});
};

export const logoutUser = () => (dispatch) => {
	localStorage.removeItem('FBIdToken');
	delete axios.defaults.headers.common['Authorization'];
	dispatch({ type: SET_UNAUTHENTICATED });
};

export const getUserData = () => (dispatch) => {
	dispatch({ type: LOADING_USER });
	axios
		.get('/user')
		.then((res) => {
			dispatch({
				type: SET_USER,
				payload: res.data
			});
		})
		.catch((err) => console.log(err));
};

export const getAllMyLikes = () => (dispatch) => {
	dispatch({ type: LOADING_USER });
	axios
		.get('/likes')
		.then((res) => {
			dispatch({
				type: SET_LIKES,
				payload: res.data
			});
		})
		.catch((err) => console.log(err));
};

export const getApprovedUsers = () => (dispatch) => {
	dispatch({ type: LOADING_UI });
	axios
		.get('/approved')
		.then((res) => {
			dispatch({
				type: SET_APPROVED,
				payload: res.data
			});
			dispatch({ type: STOP_LOADING_UI });
		})
		.catch((err) => console.log(err));
};

export const deleteApproved = (approvalId) => (dispatch) => {
	axios
		.delete(`/approved/${approvalId}`)
		.then(() => {
			dispatch({ type: DELETE_APPROVED, payload: approvalId });
		})
		.catch((err) => console.log(err));
};

export const postApproved = (newApproval) => (dispatch) => {
	dispatch({ type: LOADING_USER });
	axios
		.post('/approved', newApproval)
		.then((res) => {
			dispatch({
				type: POST_APPROVED,
				payload: res.data
			});
		})
		.catch((err) => console.log(err));
};

export const uploadImage = (formData) => (dispatch) => {
	dispatch({ type: LOADING_USER });
	axios
		.post('/user/image', formData)
		.then(() => {
			dispatch(getUserData());
		})
		.catch((err) => console.log(err));
};

export const editUserDetails = (userDetails) => (dispatch) => {
	dispatch({ type: LOADING_USER });
	axios
		.post('/user', userDetails)
		.then(() => {
			dispatch(getUserData());
		})
		.catch((err) => console.log(err));
};

export const markNotificationsRead = (notificationIds) => (dispatch) => {
	axios
		.post('/notifications', notificationIds)
		.then(() => {
			dispatch({
				type: MARK_NOTIFICATIONS_READ
			});
		})
		.catch((err) => console.log(err));
};

const setAuthorizationHeader = (token) => {
	const FBIdToken = `Bearer ${token}`;
	localStorage.setItem('FBIdToken', FBIdToken);
	axios.defaults.headers.common['Authorization'] = FBIdToken;
};
