import {
	SET_ALLUSERS,
	SET_NOTES,
	SET_MY_NOTES,
	SET_MY_INSTRUCTIONS,
	LOADING_DATA,
	LIKE_NOTE,
	UNLIKE_NOTE,
	DELETE_NOTE,
	POST_NOTE,
	UPDATE_NOTE,
	POST_INSTRUCTIONS,
	UPDATE_INSTRUCTIONS,
	SET_ERRORS,
	CLEAR_ERRORS,
	LOADING_UI,
	STOP_LOADING_UI,
	SET_NOTE,
	SUBMIT_COMMENT,
	SET_ACTIVE_CARD,
	SET_TRANSITION,
	SET_OTHER_PROFILE
} from '../types';
import axios from 'axios';

// Get all notes

export const getNotes = () => (dispatch) => {
	dispatch({ type: LOADING_DATA });
	axios
		.get('/notes')
		.then((res) => {
			dispatch({
				type: SET_NOTES,
				payload: res.data
			});
		})
		.catch((err) => {
			dispatch({
				type: SET_NOTES,
				payload: []
			});
		});
};

// Get my notes

export const getMyNotes = () => (dispatch) => {
	dispatch({ type: LOADING_DATA });
	axios
		.get('/mynotes')
		.then((res) => {
			dispatch({
				type: SET_MY_NOTES,
				payload: res.data
			});
		})
		.catch((err) => {
			dispatch({
				type: SET_MY_NOTES,
				payload: []
			});
		});
};

// Get my instructions

export const getMyInstructions = () => (dispatch) => {
	dispatch({ type: LOADING_DATA });
	axios
		.get('/myinstructions')
		.then((res) => {
			dispatch({
				type: SET_MY_INSTRUCTIONS,
				payload: res.data
			});
		})
		.catch((err) => {
			dispatch({
				type: SET_MY_INSTRUCTIONS,
				payload: []
			});
		});
};

// Get one note

export const getNote = (noteId) => (dispatch) => {
	dispatch({ type: LOADING_UI });
	axios
		.get(`/note/${noteId}`)
		.then((res) => {
			dispatch({
				type: SET_NOTE,
				payload: res.data
			});
			dispatch({ type: STOP_LOADING_UI });
		})
		.catch((err) => console.log(err));
};

// Post a note

export const postNote = (newNote) => (dispatch) => {
	dispatch({ type: LOADING_UI });
	axios
		.post('/note', newNote)
		.then((res) => {
			dispatch({
				type: POST_NOTE,
				payload: res.data
			});
			dispatch(clearErrors());
		})
		.catch((err) => {
			dispatch({
				type: SET_ERRORS,
				payload: err.response.data
			});
		});
};

// Update a note

export const updateNote = (updatedNote, noteId) => (dispatch) => {
	dispatch({ type: LOADING_UI });
	axios
		.post(`/note/${noteId}`, updatedNote)
		.then((res) => {
			dispatch({
				type: UPDATE_NOTE,
				payload: res.data
			});
			dispatch(clearErrors());
		})
		.catch((err) => {
			dispatch({
				type: SET_ERRORS,
				payload: err.response.data
			});
		});
};

// Post instructions

export const postInstructions = (newInstructions) => (dispatch) => {
	dispatch({ type: LOADING_UI });
	axios
		.post('/instructions', newInstructions)
		.then((res) => {
			dispatch({
				type: POST_INSTRUCTIONS,
				payload: res.data
			});
			dispatch(clearErrors());
		})
		.catch((err) => {
			dispatch({
				type: SET_ERRORS,
				payload: err.response.data
			});
		});
};

// Update instructions

export const updateInstructions = (updatedInstructions, instructionsId) => (dispatch) => {
	dispatch({ type: LOADING_UI });
	axios
		.post(`/instructions/${instructionsId}`, updatedInstructions)
		.then((res) => {
			dispatch({
				type: UPDATE_INSTRUCTIONS,
				payload: res.data
			});
			dispatch(clearErrors());
		})
		.catch((err) => {
			dispatch({
				type: SET_ERRORS,
				payload: err.response.data
			});
		});
};

// Get all Users (to see who approved you)

export const getAllUsers = () => (dispatch) => {
	dispatch({ type: LOADING_DATA });
	axios
		.get('/users')
		.then((res) => {
			dispatch({
				type: SET_ALLUSERS,
				payload: res.data
			});
		})
		.catch((err) => console.log(err));
};

// Like a note

export const likeNote = (noteId) => (dispatch) => {
	axios
		.get(`/note/${noteId}/like`)
		.then((res) => {
			dispatch({
				type: LIKE_NOTE,
				payload: res.data
			});
		})
		.catch((err) => console.log(err));
};

// Unlike a note

export const unlikeNote = (noteId) => (dispatch) => {
	axios
		.get(`/note/${noteId}/unlike`)
		.then((res) => {
			dispatch({
				type: UNLIKE_NOTE,
				payload: res.data
			});
		})
		.catch((err) => console.log(err));
};

// Submit a comment

export const submitComment = (noteId, commentData) => (dispatch) => {
	axios
		.post(`/note/${noteId}/comment`, commentData)
		.then((res) => {
			dispatch({
				type: SUBMIT_COMMENT,
				payload: res.data
			});
			dispatch(clearErrors());
		})
		.catch((err) => {
			dispatch({
				type: SET_ERRORS,
				payload: err.response.data
			});
		});
};

// Delete a note

export const deleteNote = (noteId) => (dispatch) => {
	axios
		.delete(`/note/${noteId}`)
		.then(() => {
			dispatch({ type: DELETE_NOTE, payload: noteId });
		})
		.catch((err) => console.log(err));
};

export const viewUserData = (userHandle) => (dispatch) => {
	dispatch({ type: LOADING_DATA });
	axios
		.get(`/user/${userHandle}`)
		.then((res) => {
			dispatch({
				type: SET_NOTES,
				payload: res.data.notes
			});
			dispatch({
				type: SET_OTHER_PROFILE,
				payload: res.data.user
			});
		})
		.catch((err) => {
			dispatch({
				type: SET_NOTES,
				payload: null
			});
		});
};

// Clear errors

export const clearErrors = () => (dispatch) => {
	dispatch({ type: CLEAR_ERRORS });
};

// Set an Active Card to Link

export const selectCard = (activeCard) => (dispatch) => {
	dispatch({ type: SET_ACTIVE_CARD, payload: activeCard });
};

// Set Active Transition

export const selectTransition = (activeTransition) => (dispatch) => {
	dispatch({ type: SET_TRANSITION, payload: activeTransition });
};
