import {
	SET_ALLUSERS,
	SET_NOTES,
	SET_MY_NOTES,
	SET_MY_INSTRUCTIONS,
	SET_NOTE,
	LIKE_NOTE,
	UNLIKE_NOTE,
	LOADING_DATA,
	DELETE_NOTE,
	POST_NOTE,
	UPDATE_NOTE,
	POST_INSTRUCTIONS,
	UPDATE_INSTRUCTIONS,
	SUBMIT_COMMENT,
	SET_OTHER_PROFILE
} from '../types';

const initialState = {
	notes: [],
	myNotes: [],
	myInstructions: [],
	note: {},
	loading: false,
	allUsers: [],
	otherProfile: {}
};

export default function (state = initialState, action){
	switch (action.type) {
		case LOADING_DATA:
			return {
				...state,
				loading: true
			};
		case SET_NOTES:
			return {
				...state,
				notes: action.payload,
				loading: false
			};
		case SET_MY_NOTES:
			return {
				...state,
				myNotes: action.payload,
				loading: false
			};
		case SET_MY_INSTRUCTIONS:
			return {
				...state,
				myInstructions: action.payload,
				loading: false
			};
		case SET_ALLUSERS:
			return {
				...state,
				allUsers: action.payload,
				loading: false
			};
		case SET_OTHER_PROFILE:
			return {
				...state,
				otherProfile: action.payload
			};
		case SET_NOTE:
			return {
				...state,
				note: action.payload
			};
		case LIKE_NOTE:
		case UNLIKE_NOTE:
			let index = state.notes.findIndex((note) => note.noteId === action.payload.noteId);
			state.notes[index] = action.payload;
			if (state.note.noteId === action.payload.noteId) {
				state.note = action.payload;
			}
			return {
				...state
			};
		case SUBMIT_COMMENT:
			return {
				...state,
				note: {
					...state.note,
					comments: [
						action.payload,
						...state.note.comments
					]
				}
			};
		case DELETE_NOTE:
			let deleteIndex = state.notes.findIndex((note) => note.noteId === action.payload);
			state.notes.splice(deleteIndex, 1);
			return {
				...state
			};
		case POST_NOTE:
			return {
				...state,
				myNotes: [
					{
						body: action.payload.body,
						linkedCardId: action.payload.linkedCardId,
						isPublic: action.payload.isPublic,
						isComplete: action.payload.isComplete,
						isCompleteSlider: action.payload.isCompleteSlider
					},
					...state.myNotes
				]
			};
		case UPDATE_NOTE:
			return {
				...state,
				myNotes: [
					{
						body: action.payload.body,
						linkedCardId: action.payload.linkedCardId,
						isPublic: action.payload.isPublic,
						isComplete: action.payload.isComplete,
						isCompleteSlider: action.payload.isCompleteSlider
					},
					...state.myNotes
				]
			};
		case POST_INSTRUCTIONS:
			return {
				...state,
				myInstructions: [
					{
						items: action.payload.items,
						agentFirstChoice: action.payload.agentFirstChoice,
						agentBackUp: action.payload.agentBackUp,
						agentBackUpBackUp: action.payload.agentBackUpBackUp,
						agentSupport: action.payload.agentSupport,
						HCDmedicalCare: action.payload.HCDmedicalCare,
						HCDlifeSustaining: action.payload.HCDlifeSustaining,
						HCDpainMedication: action.payload.HCDpainMedication,
						HCDinterpret: action.payload.HCDinterpret,
						HCDhireHelp: action.payload.HCDhireHelp,
						HCDrelocate: action.payload.HCDrelocate,
						HCDorgans: action.payload.HCDorgans,
						HCDmedicalRecords: action.payload.HCDmedicalRecords,
						HCDlegalAction: action.payload.HCDlegalAction,
						HCDbenefits: action.payload.HCDbenefits,
						tastesNails: action.payload.tastesNails,
						tastesLips: action.payload.tastesLips,
						tastesFeverish: action.payload.tastesFeverish,
						tastesBaths: action.payload.tastesBaths,
						tastesTeeth: action.payload.tastesTeeth,
						tastesHair: action.payload.tastesHair,
						tastesSoiled: action.payload.tastesSoiled,
						tastesMusic: action.payload.tastesMusic,
						tastesCompany: action.payload.tastesCompany,
						tastesPictures: action.payload.tastesPictures,
						tastesHandholding: action.payload.tastesHandholding,
						tastesPrayer: action.payload.tastesPrayer,
						tastesAddMore: action.payload.tastesAddMore,
						directivesPain: action.payload.directivesPain,
						directivesResuscitate: action.payload.directivesResuscitate,
						directivesIV: action.payload.directivesIV,
						directivesTerminal: action.payload.directivesTerminal,
						directivesComa: action.payload.directivesComa,
						directivesBrainDamage: action.payload.directivesBrainDamage,
						directivesLastDays: action.payload.directivesLastDays,
						directivesWithhold: action.payload.directivesWithhold,
						guardianFirstChoice: action.payload.guardianFirstChoice,
						guardianBackUp: action.payload.guardianBackUp,
						bodySetting: action.payload.bodySetting,
						bodyRemains: action.payload.bodyRemains,
						bodyPlaces: action.payload.bodyPlaces,
						bodyFirstDays: action.payload.bodyFirstDays,
						bodyFirstChoice: action.payload.bodyFirstChoice,
						bodyBackUp: action.payload.bodyBackUp,
						bodyBackUpBackUp: action.payload.bodyBackUpBackUp,
						bodyOrgans: action.payload.bodyOrgans,
						bodySpecificOrgans: action.payload.bodySpecificOrgans,
						bodyAutopsy: action.payload.bodyAutopsy,
						ceremonyType: action.payload.ceremonyType,
						ceremonyVision: action.payload.ceremonyVision,
						ceremonyAttendees: action.payload.ceremonyAttendees,
						ceremonyStories: action.payload.ceremonyStories,
						ceremonyTraditions: action.payload.ceremonyTraditions,
						treasuresExecutorFirstChoice: action.payload.treasuresExecutorFirstChoice,
						treasuresExecutorBackUp: action.payload.treasuresExecutorBackUp,
						treasuresExecutorBackUpBackUp: action.payload.treasuresExecutorBackUpBackUp,
						treasuresGodparentsFirstChoice: action.payload.treasuresGodparentsFirstChoice,
						treasuresGodparentsBackUp: action.payload.treasuresGodparentsBackUp,
						treasuresGodparentsNotes: action.payload.treasuresGodparentsNotes,
						treasuresPetparentsFirstChoice: action.payload.treasuresPetparentsFirstChoice,
						treasuresPetparentsBackUp: action.payload.treasuresPetparentsBackUp,
						treasuresPetparentsNotes: action.payload.treasuresPetparentsNotes,
						treasuresLegalName: action.payload.treasuresLegalName,
						treasuresMaidenName: action.payload.treasuresMaidenName,
						treasuresBirthday: action.payload.treasuresBirthday,
						treasuresSSN: action.payload.treasuresSSN,
						treasuresLicense: action.payload.treasuresLicense,
						treasuresPassport: action.payload.treasuresPassport,
						treasuresHomeAddress: action.payload.treasuresHomeAddress,
						treasuresBirthplace: action.payload.treasuresBirthplace,
						treasuresSpouse: action.payload.treasuresSpouse,
						treasuresFormerSpouse: action.payload.treasuresFormerSpouse,
						treasuresChildren: action.payload.treasuresChildren,
						treasuresGrandkids: action.payload.treasuresGrandkids,
						treasuresPets: action.payload.treasuresPets,
						treasuresParent1Name: action.payload.treasuresParent1Name,
						treasuresParent1Birthplace: action.payload.treasuresParent1Birthplace,
						treasuresParent2Name: action.payload.treasuresParent2Name,
						treasuresParent2Birthplace: action.payload.treasuresParent2Birthplace,
						treasuresSiblings: action.payload.treasuresSiblings,
						treasuresPhysician: action.payload.treasuresPhysician,
						treasuresPhysiciansInfo: action.payload.treasuresPhysiciansInfo,
						treasuresAttorney: action.payload.treasuresAttorney,
						treasuresAttorneysInfo: action.payload.treasuresAttorneysInfo,
						treasuresAccountant: action.payload.treasuresAccountant,
						treasuresAccountantsInfo: action.payload.treasuresAccountantsInfo,
						treasuresFinancialAdvisor: action.payload.treasuresFinancialAdvisor,
						treasuresFinancialAdvisorsInfo: action.payload.treasuresFinancialAdvisorsInfo,
						treasuresStuff: action.payload.treasuresStuff,
						treasuresDistribution: action.payload.treasuresDistribution,
						treasuresExcluded: action.payload.treasuresExcluded,
						treasuresAssets: action.payload.treasuresAssets,
						treasuresLiabilities: action.payload.treasuresLiabilities,
						treasuresOnline: action.payload.treasuresOnline,
						treasuresLocationLicense: action.payload.treasuresLocationLicense,
						treasuresLocationPassport: action.payload.treasuresLocationPassport,
						treasuresLocationBirthCertificate: action.payload.treasuresLocationBirthCertificate,
						treasuresLocationSocialSecurityCard: action.payload.treasuresLocationSocialSecurityCard,
						treasuresLocationMarriageCertificate: action.payload.treasuresLocationMarriageCertificate,
						treasuresLocationDivorcePapers: action.payload.treasuresLocationDivorcePapers,
						treasuresLocationAddressBook: action.payload.treasuresLocationAddressBook,
						treasuresLocationOnlinePasswords: action.payload.treasuresLocationOnlinePasswords,
						treasuresLocationWill: action.payload.treasuresLocationWill,
						treasuresLocationAdvanceDirectives: action.payload.treasuresLocationAdvanceDirectives,
						treasuresLocationSafe: action.payload.treasuresLocationSafe,
						legacyLetters: action.payload.legacyLetters,
						legacyScholarship: action.payload.legacyScholarship
					},
					...state.myInstructions
				]
			};
		case UPDATE_INSTRUCTIONS:
			return {
				...state,
				myNotes: [
					{
						items: action.payload.items,
						agentFirstChoice: action.payload.agentFirstChoice,
						agentBackUp: action.payload.agentBackUp,
						agentBackUpBackUp: action.payload.agentBackUpBackUp,
						agentSupport: action.payload.agentSupport,
						HCDmedicalCare: action.payload.HCDmedicalCare,
						HCDlifeSustaining: action.payload.HCDlifeSustaining,
						HCDpainMedication: action.payload.HCDpainMedication,
						HCDinterpret: action.payload.HCDinterpret,
						HCDhireHelp: action.payload.HCDhireHelp,
						HCDrelocate: action.payload.HCDrelocate,
						HCDorgans: action.payload.HCDorgans,
						HCDmedicalRecords: action.payload.HCDmedicalRecords,
						HCDlegalAction: action.payload.HCDlegalAction,
						HCDbenefits: action.payload.HCDbenefits,
						tastesNails: action.payload.tastesNails,
						tastesLips: action.payload.tastesLips,
						tastesFeverish: action.payload.tastesFeverish,
						tastesBaths: action.payload.tastesBaths,
						tastesTeeth: action.payload.tastesTeeth,
						tastesHair: action.payload.tastesHair,
						tastesSoiled: action.payload.tastesSoiled,
						tastesMusic: action.payload.tastesMusic,
						tastesCompany: action.payload.tastesCompany,
						tastesPictures: action.payload.tastesPictures,
						tastesHandholding: action.payload.tastesHandholding,
						tastesPrayer: action.payload.tastesPrayer,
						tastesAddMore: action.payload.tastesAddMore,
						directivesPain: action.payload.directivesPain,
						directivesResuscitate: action.payload.directivesResuscitate,
						directivesIV: action.payload.directivesIV,
						directivesTerminal: action.payload.directivesTerminal,
						directivesComa: action.payload.directivesComa,
						directivesBrainDamage: action.payload.directivesBrainDamage,
						directivesLastDays: action.payload.directivesLastDays,
						directivesWithhold: action.payload.directivesWithhold,
						guardianFirstChoice: action.payload.guardianFirstChoice,
						guardianBackUp: action.payload.guardianBackUp,
						bodySetting: action.payload.bodySetting,
						bodyRemains: action.payload.bodyRemains,
						bodyPlaces: action.payload.bodyPlaces,
						bodyFirstDays: action.payload.bodyFirstDays,
						bodyFirstChoice: action.payload.bodyFirstChoice,
						bodyBackUp: action.payload.bodyBackUp,
						bodyBackUpBackUp: action.payload.bodyBackUpBackUp,
						bodyOrgans: action.payload.bodyOrgans,
						bodySpecificOrgans: action.payload.bodySpecificOrgans,
						bodyAutopsy: action.payload.bodyAutopsy,
						ceremonyType: action.payload.ceremonyType,
						ceremonyVision: action.payload.ceremonyVision,
						ceremonyAttendees: action.payload.ceremonyAttendees,
						ceremonyStories: action.payload.ceremonyStories,
						ceremonyTraditions: action.payload.ceremonyTraditions,
						treasuresExecutorFirstChoice: action.payload.treasuresExecutorFirstChoice,
						treasuresExecutorBackUp: action.payload.treasuresExecutorBackUp,
						treasuresExecutorBackUpBackUp: action.payload.treasuresExecutorBackUpBackUp,
						treasuresGodparentsFirstChoice: action.payload.treasuresGodparentsFirstChoice,
						treasuresGodparentsBackUp: action.payload.treasuresGodparentsBackUp,
						treasuresGodparentsNotes: action.payload.treasuresGodparentsNotes,
						treasuresPetparentsFirstChoice: action.payload.treasuresPetparentsFirstChoice,
						treasuresPetparentsBackUp: action.payload.treasuresPetparentsBackUp,
						treasuresPetparentsNotes: action.payload.treasuresPetparentsNotes,
						treasuresLegalName: action.payload.treasuresLegalName,
						treasuresMaidenName: action.payload.treasuresMaidenName,
						treasuresBirthday: action.payload.treasuresBirthday,
						treasuresSSN: action.payload.treasuresSSN,
						treasuresLicense: action.payload.treasuresLicense,
						treasuresPassport: action.payload.treasuresPassport,
						treasuresHomeAddress: action.payload.treasuresHomeAddress,
						treasuresBirthplace: action.payload.treasuresBirthplace,
						treasuresSpouse: action.payload.treasuresSpouse,
						treasuresFormerSpouse: action.payload.treasuresFormerSpouse,
						treasuresChildren: action.payload.treasuresChildren,
						treasuresGrandkids: action.payload.treasuresGrandkids,
						treasuresPets: action.payload.treasuresPets,
						treasuresParent1Name: action.payload.treasuresParent1Name,
						treasuresParent1Birthplace: action.payload.treasuresParent1Birthplace,
						treasuresParent2Name: action.payload.treasuresParent2Name,
						treasuresParent2Birthplace: action.payload.treasuresParent2Birthplace,
						treasuresSiblings: action.payload.treasuresSiblings,
						treasuresPhysician: action.payload.treasuresPhysician,
						treasuresPhysiciansInfo: action.payload.treasuresPhysiciansInfo,
						treasuresAttorney: action.payload.treasuresAttorney,
						treasuresAttorneysInfo: action.payload.treasuresAttorneysInfo,
						treasuresAccountant: action.payload.treasuresAccountant,
						treasuresAccountantsInfo: action.payload.treasuresAccountantsInfo,
						treasuresFinancialAdvisor: action.payload.treasuresFinancialAdvisor,
						treasuresFinancialAdvisorsInfo: action.payload.treasuresFinancialAdvisorsInfo,
						treasuresStuff: action.payload.treasuresStuff,
						treasuresDistribution: action.payload.treasuresDistribution,
						treasuresExcluded: action.payload.treasuresExcluded,
						treasuresAssets: action.payload.treasuresAssets,
						treasuresLiabilities: action.payload.treasuresLiabilities,
						treasuresOnline: action.payload.treasuresOnline,
						treasuresLocationLicense: action.payload.treasuresLocationLicense,
						treasuresLocationPassport: action.payload.treasuresLocationPassport,
						treasuresLocationBirthCertificate: action.payload.treasuresLocationBirthCertificate,
						treasuresLocationSocialSecurityCard: action.payload.treasuresLocationSocialSecurityCard,
						treasuresLocationMarriageCertificate: action.payload.treasuresLocationMarriageCertificate,
						treasuresLocationDivorcePapers: action.payload.treasuresLocationDivorcePapers,
						treasuresLocationAddressBook: action.payload.treasuresLocationAddressBook,
						treasuresLocationOnlinePasswords: action.payload.treasuresLocationOnlinePasswords,
						treasuresLocationWill: action.payload.treasuresLocationWill,
						treasuresLocationAdvanceDirectives: action.payload.treasuresLocationAdvanceDirectives,
						treasuresLocationSafe: action.payload.treasuresLocationSafe,
						legacyLetters: action.payload.legacyLetters,
						legacyScholarship: action.payload.legacyScholarship
					},
					...state.myInstructions
				]
			};
		default:
			return state;
	}
}
