import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
	...theme.spreadThis,
	card: {
		position: 'relative',
		display: 'flex',
		margin: '0',
		paddingRight: 10
	},
	avatars: {
		width: 50,
		height: 50,
		objectFit: 'cover',
		borderRadius: '50%',
		border: '2px solid #eeeeee',
		display: 'flex',
		margin: 'auto 10px auto 10px'
	},
	content: {
		padding: '5px 15px 5px 15px',
		marginBottom: -20,
		objectFit: 'cover',
		width: '90%'
	},
	cardChapter: {
		fontSize: '1em',
		margin: '10px 0 5px 0'
	},
	cardHandle: {
		fontSize: '.8em',
		textAlign: 'center',
		color: 'rgba(0,0,0,0.5)',
		justify: 'center',
		fontWeight: '400',
		marginTop: -5
	},
	cardDate: {
		fontSize: '.7em',
		color: '#aaa'
	}
});

class ResourceCard extends Component {
	render () {
		const { classes, name, description, link, src } = this.props;

		return (
			<Grid container spacing={1} style={{ margin: 0 }}>
				<Grid item style={{ width: '100%', margin: 0 }}>
					<a href={link}>
						<Card className={classes.card}>
							<CardMedia
								component="img"
								alt={`${name}'s logo`}
								image={src}
								title={`${name}'s logo`}
								className={classes.avatars}
							/>
							<CardContent
								className={classes.content}
								style={{ margin: '10px auto 10px auto', padding: '10px 0 10px 0' }}
							>
								<Typography
									variant="h5"
									color="primary"
									className={classes.cardChapter}
									style={{ marginTop: 0 }}
								>
									{name} <span className={classes.cardDate}>{link}</span>
								</Typography>

								<Typography variant="body2" style={{ marginBottom: 0, padding: 0 }}>
									{description}
								</Typography>
							</CardContent>
						</Card>
					</a>
				</Grid>
			</Grid>
		);
	}
}

ResourceCard.propTypes = {
	user: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	openDialog: PropTypes.bool
};

const mapStateToProps = (state) => ({
	user: state.user,
	UI: state.UI
});

export default connect(mapStateToProps, null)(withStyles(styles)(ResourceCard));
