// User reducer types

export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const SET_LIKES = 'SET_LIKES';
export const SET_APPROVED = 'SET_APPROVED';
export const POST_APPROVED = 'POST_APPROVED';
export const DELETE_APPROVED = 'DELETE_APPROVED';
export const SET_ALLUSERS = 'SET_ALLUSERS';
export const LOADING_USER = 'LOADING_USER';
export const MARK_NOTIFICATIONS_READ = 'MARK_NOTIFICATIONS_READ';

// UI reducer types

export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOADING_DATA = 'LOADING_DATA';
export const STOP_LOADING_DATA = 'STOP_LOADING_DATA';
export const SET_TRANSITION = 'SET_TRANSITION';

// Data reducer types

export const SET_NOTES = 'SET_NOTES';
export const SET_MY_NOTES = 'SET_MY_NOTES';
export const SET_MY_INSTRUCTIONS = 'SET_MY_INSTRUCTIONS';
export const SET_NOTE = 'SET_NOTE';
export const LIKE_NOTE = 'LIKE_NOTE';
export const UNLIKE_NOTE = 'UNLIKE_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const POST_NOTE = 'POST_NOTE';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const POST_INSTRUCTIONS = 'POST_INSTRUCTIONS';
export const UPDATE_INSTRUCTIONS = 'UPDATE_INSTRUCTIONS';
export const SUBMIT_COMMENT = 'SUBMIT_COMMENT';
export const SET_ACTIVE_CARD = 'SET_LINKED_CARD';
export const SET_OTHER_PROFILE = 'SET_OTHER_PROFILE';

// Starter Pack reducer types

export const NEW_POST_OPEN = 'NEW_POST_OPEN';
export const NEW_POST_CLOSE = 'NEW_POST_CLOSE';
export const SELECT_CHAPTER = 'SELECT_CHAPTER';
export const SELECT_CARD = 'SELECT_CARD';
