import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import afterlogo from '../images/afterlogo.png';
import afterwordwhite from '../images/afterword.png';
import afterword from '../images/afterword.png';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { signupUser } from '../redux/actions/userActions';
import SplashContent from './EOL/splashContentEOL.js';
// import Splash from './EOL/splashEOL.js';
// import AlarmIcon from '@material-ui/icons/Alarm';
// import EventAvailableIcon from '@material-ui/icons/EventAvailable';
// import StartWhereYouAre from '@material-ui/icons/ChildFriendlyOutlined';
// import CategoryIcon from '@material-ui/icons/Category';
// import FingerprintIcon from '@material-ui/icons/Fingerprint';
// import BubbleChartIcon from '@material-ui/icons/BubbleChart';
// import CloudIcon from '@material-ui/icons/Cloud';
// import Paper from '@material-ui/core/Paper';

const styles = (theme) => ({
	...theme.spreadThis,
	splashCarousel: {
		[theme.breakpoints.up('xs')]: {
			position: 'relative',
			marginTop: 30,
			marginLeft: 30,
			width: 'calc(100% - 340px)'
		},
		[theme.breakpoints.down('xs')]: {
			position: 'relative',
			marginTop: 0,
			bottom: 0,
			marginLeft: 0,
			width: '100%'
		}
	},
	logo: {
		[theme.breakpoints.up('xs')]: {
			width: 48,
			height: 48,
			margin: '0px auto 30px auto'
		},
		[theme.breakpoints.down('xs')]: {
			width: 24,
			height: 24,
			margin: '0px auto 20px auto'
		}
	},
	afterWord: {
		[theme.breakpoints.up('xs')]: {
			height: 64,
			marginBottom: 12
		},
		[theme.breakpoints.down('xs')]: {
			height: 48,
			marginBottom: 8
		}
	},
	subtitle: {
		textTransform: 'uppercase',
		letterSpacing: '1em',
		[theme.breakpoints.up('xs')]: {
			fontSize: '1em',
			margin: 'auto auto 35px 5px',
			color: '#fff'
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '.75em',
			margin: 'auto auto 35px 5px',
			color: '#0f8797'
		}
	},
	container: {
		[theme.breakpoints.up('xs')]: {
			margin: '0px auto 0 auto'
		},
		[theme.breakpoints.down('xs')]: {
			margin: '0px auto 0 auto'
		}
	},
	logoMarkup: {
		[theme.breakpoints.up('xs')]: {
			margin: '80px auto 120px auto'
		},
		[theme.breakpoints.down('xs')]: {
			margin: '40px auto 80px auto'
		}
	},
	signupMarkup: {
		[theme.breakpoints.up('xs')]: {
			margin: '0px auto auto auto',
			height: '100%'
		},
		[theme.breakpoints.down('xs')]: {
			margin: '0px auto auto auto',
			width: '100%'
		}
	},
	paperLogin: {
		[theme.breakpoints.up('xs')]: {
			height: 'calc(100% - 20px)',
			width: '350px',
			margin: '10px auto 0px auto',
			position: 'absolute',
			top: 0,
			right: 10,
			borderRadius: 25,
			border: '2px solid white',
			background: 'linear-gradient(105deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)'
		},
		[theme.breakpoints.down('xs')]: {
			height: 'auto',
			margin: '0px auto 0px auto',
			position: 'relative',
			borderRadius: 0,
			background: '#fff'
		}
	},
	buttonNewAccount: {
		[theme.breakpoints.up('xs')]: {
			color: '#fff',
			fontSize: '.75em',
			letterSpacing: '.5em'
		},
		[theme.breakpoints.down('xs')]: {
			color: '#0f8797',
			fontSize: '.75em',
			letterSpacing: '.5em'
		}
	},
	button: {
		[theme.breakpoints.up('xs')]: {
			margin: '40px auto 15px auto'
		},
		[theme.breakpoints.down('xs')]: {
			margin: '40px auto 15px auto'
		},
		position: 'relative',
		height: 40
	}
});

class signup extends Component {
	constructor (props) {
		super(props);
		this.state = {
			email: '',
			password: '',
			confirmPassword: '',
			handle: '',
			errors: {},
			mobileStarted: false
		};
	}

	componentWillReceiveProps (nextProps) {
		if (nextProps.UI.errors) {
			this.setState({ errors: nextProps.UI.errors });
		}
	}

	handleSubmit = (event) => {
		event.preventDefault();
		this.setState({
			loading: true
		});
		const newUserData = {
			email: this.state.email,
			password: this.state.password,
			confirmPassword: this.state.confirmPassword,
			handle: this.state.handle
		};
		this.props.signupUser(newUserData, this.props.history);
	};
	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	render () {
		const { classes, UI: { loading } } = this.props;
		const { errors } = this.state;
		return (
			<Grid container className={classes.form}>
				<Grid container spacing={0} justify="center" className={classes.container}>
					<Hidden xsDown implementation="css">
						<Grid item xs>
							<div className={classes.splashCarousel}>
								<SplashContent />
							</div>
						</Grid>
					</Hidden>
					<Hidden xsDown implementation="css">
						<Grid item xs className={classes.signupMarkup}>
							<Paper className={classes.paperLogin}>
								<div className={classes.logoMarkup}>
									<img src={afterlogo} alt="After Logo" className={classes.logo} />
									<hr className={classes.invisibleSeparator} />
									<div className={classes.afterWord}>
										<img src={afterwordwhite} alt="After Word" className={classes.afterWord} />
										<Typography variant="h5" className={classes.subtitle}>
											planner
										</Typography>
									</div>
								</div>
								<form noValidate onSubmit={this.handleSubmit}>
									<TextField
										id="handle"
										name="handle"
										type="text"
										label="username"
										className={classes.textField}
										helperText={errors.handle}
										error={errors.handle ? true : false}
										value={this.state.handle}
										onChange={this.handleChange}
										fullWidth
									/>
									<TextField
										id="email"
										name="email"
										type="email"
										label="email"
										className={classes.textField}
										helperText={errors.email}
										error={errors.email ? true : false}
										value={this.state.email}
										onChange={this.handleChange}
										fullWidth
									/>
									<TextField
										id="password"
										name="password"
										type="password"
										label="password"
										className={classes.textField}
										helperText={errors.password}
										error={errors.password ? true : false}
										value={this.state.password}
										onChange={this.handleChange}
										fullWidth
									/>
									<TextField
										id="confirmPassword"
										name="confirmPassword"
										type="password"
										label="confirm password"
										className={classes.textField}
										helperText={errors.confirmPassword}
										error={errors.confirmPassword ? true : false}
										value={this.state.confirmPassword}
										onChange={this.handleChange}
										fullWidth
									/>
									{errors.general && (
										<Typography variant="body2" className={classes.customError}>
											{errors.general}
										</Typography>
									)}{' '}
									<hr className={classes.invisibleSeparator} />
									<Button
										type="submit"
										disabled={loading}
										variant="contained"
										style={{
											background: 'linear-gradient(105deg,rgba(15, 135, 151, 1) 0%, #01508d 100%'
										}}
										className={classes.button}
									>
										{!loading ? (
											'sign up'
										) : (
											<CircularProgress size={20} className={classes.progress} />
										)}
									</Button>
									<br />
									<Button
										disabled={loading}
										variant="text"
										component={Link}
										to="/login"
										className={classes.buttonNewAccount}
									>
										{!loading ? (
											'log in'
										) : (
											<CircularProgress size={20} className={classes.progress} />
										)}
									</Button>
								</form>
							</Paper>
						</Grid>
					</Hidden>
					<Hidden smUp implementation="css">
						<Grid item xs className={classes.signupMarkup}>
							<div className={classes.logoMarkup}>
								<img src={afterlogo} alt="After Logo" className={classes.logo} />
								<hr className={classes.invisibleSeparator} />
								<div className={classes.afterWord}>
									<img src={afterword} alt="After Word" className={classes.afterWord} />
									<Typography variant="h5" className={classes.subtitle}>
										planner
									</Typography>
								</div>
							</div>

							{!this.state.mobileStarted ? (
								<div className={classes.splashCarousel}>
									<SplashContent />
									<Button
										onClick={() => this.setState({ mobileStarted: true })}
										disabled={loading}
										variant="contained"
										style={{
											position: 'fixed',
											width: 'calc(100% - 30px)',
											left: 15,
											bottom: 0,
											top: 'auto',
											background: 'linear-gradient(105deg,rgba(15, 135, 151, 1) 0%, #01508d 100%',
											color: '#fff'
										}}
										className={classes.button}
									>
										{!loading ? (
											'get started'
										) : (
											<CircularProgress size={20} className={classes.progress} />
										)}
									</Button>
								</div>
							) : (
								// <div style={{ height: 20 }} />
								<form noValidate onSubmit={this.handleSubmit}>
									<TextField
										id="handle"
										name="handle"
										type="text"
										label="username"
										className={classes.textField}
										helperText={errors.handle}
										error={errors.handle ? true : false}
										value={this.state.handle}
										onChange={this.handleChange}
										fullWidth
									/>
									<TextField
										id="email"
										name="email"
										type="email"
										label="email"
										className={classes.textField}
										helperText={errors.email}
										error={errors.email ? true : false}
										value={this.state.email}
										onChange={this.handleChange}
										fullWidth
									/>
									<TextField
										id="password"
										name="password"
										type="password"
										label="password"
										className={classes.textField}
										helperText={errors.password}
										error={errors.password ? true : false}
										value={this.state.password}
										onChange={this.handleChange}
										fullWidth
									/>
									<TextField
										id="confirmPassword"
										name="confirmPassword"
										type="password"
										label="confirm password"
										className={classes.textField}
										helperText={errors.confirmPassword}
										error={errors.confirmPassword ? true : false}
										value={this.state.confirmPassword}
										onChange={this.handleChange}
										fullWidth
									/>
									{errors.general && (
										<Typography variant="body2" className={classes.customError}>
											{errors.general}
										</Typography>
									)}{' '}
									<hr className={classes.invisibleSeparator} />
									<Button
										type="submit"
										disabled={loading}
										variant="contained"
										style={{
											background: 'linear-gradient(105deg,rgba(15, 135, 151, 1) 0%, #01508d 100%',
											color: '#fff'
										}}
										className={classes.button}
									>
										{!loading ? (
											'sign up'
										) : (
											<CircularProgress size={20} className={classes.progress} />
										)}
									</Button>
									<br />
									<Button
										disabled={loading}
										variant="text"
										component={Link}
										to="/login"
										className={classes.buttonNewAccount}
									>
										{!loading ? (
											'log in'
										) : (
											<CircularProgress size={20} className={classes.progress} />
										)}
									</Button>
								</form>
							)}
						</Grid>
					</Hidden>
				</Grid>
			</Grid>
		);
	}
}

signup.propTypes = {
	classes: PropTypes.object.isRequired,
	signupUser: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	user: state.user,
	UI: state.UI
});

const mapActionsToProps = {
	signupUser
};

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(signup));
