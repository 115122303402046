export default {
	palette: {
		primary: {
			vlight: '#0f8797',
			light: '#01508d',
			main: '#0f8797',
			dark: '#382c78',
			contrastText: '#fff'
		},
		secondary: {
			vlight: '#fec23e',
			light: '#f58545',
			main: '#aaa',
			dark: '#e85353',
			contrastText: '#fff'
		}
	},
	typography: {
		useNextVariants: true,
		fontFamily: "'sofia-pro', 'Helvetica Neue', sans-serif"
	},
	spreadThis: {
		paper: {
			padding: 20
		},
		profileTop: {
			margin: '40px 0 0 0'
		},
		profile: {
			'& .image-wrapper': {
				textAlign: 'center',
				position: 'relative',
				'& button': {
					position: 'absolute',
					top: '80%',
					left: '70%'
				}
			},
			'& .profile-image': {
				width: 200,
				height: 200,
				objectFit: 'cover',
				maxWidth: '100%',
				borderRadius: '50%',
				border: '3px solid #ffffff'
			},
			'& .profile-handle': {
				color: 'rgba(0,0,0,0.5)',
				fontSize: '2.2rem',
				fontWeight: '200',
				letterSpacing: '-.05em'
			},
			'& .profile-details': {
				textAlign: 'center',
				'& span, svg': {
					verticalAlign: 'middle'
				}
			},
			'& hr': {
				border: 'none',
				margin: '0 0 10px 0'
			},
			'& svg.button': {
				'&:hover': {
					cursor: 'pointer'
				}
			}
		},
		buttons: {
			textAlign: 'center',
			'& a': {
				margin: '20px 10px'
			}
		},
		form: {
			textAlign: 'center'
		},
		image: {
			margin: '20px auto 20px auto'
		},
		pageTitle: {
			margin: '0 auto 20px auto'
		},
		newPostTitle: {
			borderBottom: '1px solid #0f8797'
		},
		textField: {
			margin: '0px auto 0px auto',
			width: '70%',
			color: '#ffffff'
		},
		textFieldComment: {
			margin: '0px auto 0px auto',
			width: '100%'
		},
		button: {
			margin: '40px auto 20px auto',
			position: 'relative',
			height: 40
		},
		customError: {
			color: 'red',
			fontSize: '0.8rem',
			marginTop: 10
		},
		progress: {
			position: 'absolute'
		},
		invisibleSeparator: {
			border: 'none',
			margin: 4
		},
		bgVisibleSeparator: {
			width: '97%',
			borderBottom: '1px solid rgba(255,255,255,1)',
			transform: 'translate(4px)',
			marginBottom: 20
		},
		visibleSeparator: {
			width: '100%',
			borderBottom: '1px solid rgba(0,0,0,0.1)',
			marginBottom: 20
		},

		// Before being signed in

		logo: {
			width: 48,
			height: 48,
			margin: '0px auto 40px auto'
		},
		afterWord: {
			height: 96,
			marginBottom: 8
		},
		subtitle: {
			textTransform: 'uppercase',
			letterSpacing: '1em',
			fontSize: '1.4em',
			margin: 'auto auto 35px 5px'
		},
		features: {
			marginBottom: 10,
			padding: '10px 0 10px 0'
		},
		featureIcon: {
			padding: '10px 0 10px 0',
			fontSize: 45,
			color: 'primary',
			border: '1px solid #0f8797',
			borderRadius: '50%',
			margin: '10px 0 10px  20px'
		},
		featureHeader: {
			textTransform: 'uppercase',
			letterSpacing: 5,
			fontSize: '.5em',
			textAlign: 'left'
		},
		featureText: {
			textAlign: 'left',
			lineHeight: 1.2,
			padding: '5px auto 5px auto',
			margin: 'auto auto auto 30px',
			fontSize: '1.1em',
			// margin: '10px auto 30px 30px',
			// color: 'white',
			// width: '80%',
			justify: 'center',
			alignSelf: 'center'
		},
		featureCollection: {
			marginBottom: 15
		},
		switchLoginSignupText: {
			textAlign: 'center',
			lineHeight: 1.2,
			fontSize: '1em'
		}
	}
};
