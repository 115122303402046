import React, { Component, Fragment } from 'react';
import axios from 'axios';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Instructions from './Instructions';
import NotecardStarterPack from '../components/note/NotecardStarterPack';
import NotecardActive from '../components/note/NotecardActive';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
// import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { connect } from 'react-redux';
import {
	getNotes,
	getMyNotes,
	postNote,
	updateNote,
	clearErrors,
	selectTransition
} from '../redux/actions/dataActions';
import { getUserData } from '../redux/actions/userActions';

import withStyles from '@material-ui/core/styles/withStyles';
// import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';

// import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
// import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
// import MenuIcon from '@material-ui/icons/Menu';
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
// import AfterLogo from '../images/fab.png';

// import careIcon from '../images/eol/careIcon.png';
import careBlank from '../images/eol/careBlank.png';
import careFilled from '../images/eol/careFilled.png';
import careCheck from '../images/eol/careCheck.png';
import careLocked from '../images/eol/careLocked.png';
// import bodyIcon from '../images/eol/bodyIcon.png';
import bodyBlank from '../images/eol/bodyBlank.png';
import bodyFilled from '../images/eol/bodyFilled.png';
import bodyCheck from '../images/eol/bodyCheck.png';
import bodyLocked from '../images/eol/bodyLocked.png';
// import ceremonyIcon from '../images/eol/ceremonyIcon.png';
import ceremonyBlank from '../images/eol/ceremonyBlank.png';
import ceremonyFilled from '../images/eol/ceremonyFilled.png';
import ceremonyCheck from '../images/eol/ceremonyCheck.png';
import ceremonyLocked from '../images/eol/ceremonyLocked.png';
// import treasuresIcon from '../images/eol/treasuresIcon.png';
import treasuresBlank from '../images/eol/treasuresBlank.png';
import treasuresFilled from '../images/eol/treasuresFilled.png';
import treasuresCheck from '../images/eol/treasuresCheck.png';
import treasuresLocked from '../images/eol/treasuresLocked.png';
// import legacyIcon from '../images/eol/legacyIcon.png';
import legacyBlank from '../images/eol/legacyBlank.png';
import legacyFilled from '../images/eol/legacyFilled.png';
import legacyCheck from '../images/eol/legacyCheck.png';
import legacyLocked from '../images/eol/legacyLocked.png';

import care1 from '../images/eol/big-01.png';
import care2 from '../images/eol/big-02.png';
import care3 from '../images/eol/big-03.png';
import care4 from '../images/eol/big-04.png';
import care5 from '../images/eol/big-05.png';
import body1 from '../images/eol/big-06.png';
import body2 from '../images/eol/big-07.png';
import body3 from '../images/eol/big-08.png';
import body4 from '../images/eol/big-09.png';
import body5 from '../images/eol/big-10.png';
import ceremony1 from '../images/eol/big-11.png';
import ceremony2 from '../images/eol/big-12.png';
import ceremony3 from '../images/eol/big-13.png';
import ceremony4 from '../images/eol/big-14.png';
import ceremony5 from '../images/eol/big-15.png';
import treasures1 from '../images/eol/big-16.png';
import treasures2 from '../images/eol/big-17.png';
import treasures3 from '../images/eol/big-18.png';
import treasures4 from '../images/eol/big-19.png';
import treasures5 from '../images/eol/big-20.png';
import legacy1 from '../images/eol/big-21.png';
import legacy2 from '../images/eol/big-22.png';
import legacy3 from '../images/eol/big-23.png';
import legacy4 from '../images/eol/big-24.png';
import legacy5 from '../images/eol/big-25.png';
import s1 from '../images/eol/small-01.png';
import s2 from '../images/eol/small-02.png';
import s3 from '../images/eol/small-03.png';
import s4 from '../images/eol/small-04.png';
import s5 from '../images/eol/small-05.png';
import s6 from '../images/eol/small-06.png';
import s7 from '../images/eol/small-07.png';
import s8 from '../images/eol/small-08.png';
import s9 from '../images/eol/small-09.png';
import s10 from '../images/eol/small-10.png';
import s11 from '../images/eol/small-11.png';
import s12 from '../images/eol/small-12.png';
import s13 from '../images/eol/small-13.png';
import s14 from '../images/eol/small-14.png';
import s15 from '../images/eol/small-15.png';
import s16 from '../images/eol/small-16.png';
import s17 from '../images/eol/small-17.png';
import s18 from '../images/eol/small-18.png';
import s19 from '../images/eol/small-19.png';
import s20 from '../images/eol/small-20.png';
import s21 from '../images/eol/small-21.png';
import s22 from '../images/eol/small-22.png';
import s23 from '../images/eol/small-23.png';
import s24 from '../images/eol/small-24.png';
import s25 from '../images/eol/small-25.png';
import careCover from '../images/eol/careCover.png';
import bodyCover from '../images/eol/bodyCover.png';
import ceremonyCover from '../images/eol/ceremonyCover.png';
import treasuresCover from '../images/eol/treasuresCover.png';
import legacyCover from '../images/eol/legacyCover.png';
import preparation1 from '../images/covid/big-26.png';
import preparation2 from '../images/covid/big-27.png';
import preparation3 from '../images/covid/big-28.png';
import preparation4 from '../images/covid/big-29.png';
import preparation5 from '../images/covid/big-30.png';
import separation1 from '../images/covid/big-31.png';
import separation2 from '../images/covid/big-32.png';
import separation3 from '../images/covid/big-33.png';
import separation4 from '../images/covid/big-34.png';
import separation5 from '../images/covid/big-35.png';
import celebration1 from '../images/covid/big-36.png';
import celebration2 from '../images/covid/big-37.png';
import celebration3 from '../images/covid/big-38.png';
import celebration4 from '../images/covid/big-39.png';
import celebration5 from '../images/covid/big-40.png';
import creation1 from '../images/covid/big-41.png';
import creation2 from '../images/covid/big-42.png';
import creation3 from '../images/covid/big-43.png';
import creation4 from '../images/covid/big-44.png';
import creation5 from '../images/covid/big-45.png';
import stabilization1 from '../images/covid/big-46.png';
import stabilization2 from '../images/covid/big-47.png';
import stabilization3 from '../images/covid/big-48.png';
import stabilization4 from '../images/covid/big-49.png';
import stabilization5 from '../images/covid/big-50.png';
import s26 from '../images/covid/small-26.png';
import s27 from '../images/covid/small-27.png';
import s28 from '../images/covid/small-28.png';
import s29 from '../images/covid/small-29.png';
import s30 from '../images/covid/small-30.png';
import s31 from '../images/covid/small-31.png';
import s32 from '../images/covid/small-32.png';
import s33 from '../images/covid/small-33.png';
import s34 from '../images/covid/small-34.png';
import s35 from '../images/covid/small-35.png';
import s36 from '../images/covid/small-36.png';
import s37 from '../images/covid/small-37.png';
import s38 from '../images/covid/small-38.png';
import s39 from '../images/covid/small-39.png';
import s40 from '../images/covid/small-40.png';
import s41 from '../images/covid/small-41.png';
import s42 from '../images/covid/small-42.png';
import s43 from '../images/covid/small-43.png';
import s44 from '../images/covid/small-44.png';
import s45 from '../images/covid/small-45.png';
import s46 from '../images/covid/small-46.png';
import s47 from '../images/covid/small-47.png';
import s48 from '../images/covid/small-48.png';
import s49 from '../images/covid/small-49.png';
import s50 from '../images/covid/small-50.png';
import preparationCover from '../images/covid/1coverBig.png';
import separationCover from '../images/covid/2coverBig.png';
import celebrationCover from '../images/covid/3coverBig.png';
import creationCover from '../images/covid/4coverBig.png';
import stabilizationCover from '../images/covid/5coverBig.png';
// import NoteSkeleton from '../util/NoteSkeleton';
// import DeleteNote from '../components/note/DeleteNote';

const styles = (theme) => ({
	...theme.spreadThis,
	cover: {
		margin: 0
	},
	card: {
		position: 'relative',
		display: 'flex',
		maxHeight: '75px',
		maxWidth: '55px',
		// transform: 'translate(4px)',
		margin: '0 0 5px 0',
		overflow: 'hidden',
		perspective: '1px',
		border: '.1px solid white',
		borderRadius: 5,
		'& :hover': {
			cursor: 'pointer'
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			height: '8vh',
			// maxWidth: '35px',
			borderRadius: '5px',
			marginBottom: 1
		}
	},
	cardLocked: {
		position: 'relative',
		display: 'flex',
		maxHeight: '75px',
		maxWidth: '55px',
		// transform: 'translate(4px)',
		marginBottom: 5,
		overflow: 'hidden',
		perspective: '1px',
		border: '.1px solid #aaa',
		borderRadius: 5,
		'& :hover': {
			cursor: 'not-allowed'
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			height: '8vh',
			// height: `35px`,
			// width: '35px',
			borderRadius: '5px',
			marginBottom: 1
		}
	},
	cardUnlocked: {
		position: 'relative',
		display: 'flex',
		maxHeight: '75px',
		maxWidth: '55px',
		// transform: 'translate(4px)',
		marginBottom: 5,
		overflow: 'hidden',
		perspective: '1px',
		border: '.1px solid #fec23e',
		borderRadius: 5,
		'& :hover': {
			cursor: 'pointer'
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			height: '8vh',
			// height: `35px`,
			// width: '35px',
			borderRadius: '5px',
			marginBottom: 1
		}
	},
	content: { padding: 25, objectFit: 'cover', width: '60%' },
	bigCard: {
		position: 'relative',
		display: 'flex',
		maxHeight: '400px',
		maxWidth: '285px',
		margin: '20px auto 20px auto',
		// transform: 'translate(4px)',
		borderRadius: 10,
		overflow: 'hidden',
		perspective: '1px',
		border: '2px solid rgba(0,0,0,.1)'
	},
	notePaper: {
		width: '100%',
		margin: '10px auto 0 auto',
		// transform: 'translate(4px)',
		backgroundColor: '#fff',
		padding: ' 20px auto 0 auto'
	},
	chapterNotesPaper: {
		width: '100%',
		margin: '5px auto 20px auto',
		// transform: 'translate(4px)',
		backgroundColor: 'rgba(240,240,240,0.95)',
		// border: '.5px solid white',
		padding: '0',
		alignItems: 'center'
	},
	individualNote: {
		width: '100%',
		margin: '10px auto 0 auto',
		'& :hover': {
			cursor: 'pointer'
		}
	},
	submitButton: {
		position: 'relative',
		float: 'right',
		marginTop: 10,
		marginBottom: 15
	},
	// progressSpinner: {
	// 	position: 'absolute'
	// },
	closeButton: {
		position: 'absolute',
		top: '3%',
		left: '85%'
	},
	publicSwitch: {
		position: 'relative',
		float: 'center',
		// marginTop: 10,
		// marginBottom: 15,
		width: 250
	},
	editIconBackdrop: {
		height: 50,
		width: 50,
		borderRadius: '50%',
		border: '2px solid white',
		background: '#000000'
	},
	editIcon: {
		transform: 'translateY(0px)'
	},
	bigNote: {
		[theme.breakpoints.up('sm')]: {
			margin: '20px auto 0 auto'
		},
		[theme.breakpoints.down('sm')]: {
			margin: '0px auto 0 auto'
		},
		'& :hover': {
			cursor: 'pointer'
		}
	},
	textareaAutosize: {
		[theme.breakpoints.up('sm')]: {
			margin: '10px auto 0 0'
		},
		[theme.breakpoints.down('sm')]: {
			margin: '-8px 0 0 0'
		},
		width: '100%',
		border: 'none',
		padding: 0,
		fontSize: '1.4rem',
		letterSpacing: '-0.03em',
		lineHeight: '1.3em',
		fontWeight: '300'
	},
	prompt: {
		[theme.breakpoints.up('sm')]: {
			margin: '20px 20px 20px 0'
		},
		[theme.breakpoints.down('sm')]: {
			margin: '0px 0px 20px 0px'
		},
		fontSize: '1.8rem',
		// color: 'white',
		textAlign: 'left',
		letterSpacing: '-0.05em',
		lineHeight: '1.1em',
		maxWidth: '100%'
	},
	subprompt: {
		[theme.breakpoints.up('sm')]: {
			margin: '0 20px 40px 0'
		},
		[theme.breakpoints.down('sm')]: {
			margin: '0 0 40px 0'
		},
		color: '#0f8797'
	},
	divider: {
		[theme.breakpoints.up('sm')]: {
			margin: '0 0 40px 0'
		},
		[theme.breakpoints.down('sm')]: {
			margin: '0 0 0 0'
		}
	},
	chapterTitles: {
		fontSize: '.5rem',
		textAlign: 'center',
		// transform: 'translate(4px)',
		color: 'rgba (0,0,0,0.3)'
	},
	newNoteButton: {
		margin: '20px auto 30px auto',
		fontSize: '.7rem',
		color: '#0f8797',
		letterSpacing: '.3em',
		'& :active': {
			outline: '2px solid white'
		}
	},
	loading: {
		margin: '35px, auto, 40px, auto'
	},
	drawerPaperClosed: {
		width: 55,
		marginLeft: 80,
		// display: 'flex',
		background: 'linear-gradient(105deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)',
		overflowX: 'hidden'
	},
	drawerPaper: {
		width: 360,
		marginLeft: 80,
		// display: 'flex',
		background: 'linear-gradient(105deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)',
		overflowX: 'hidden'
	},
	drawerPaperMobile: {
		[theme.breakpoints.up('xs')]: {
			width: `calc(100% - 80px)`,
			marginLeft: 80
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			marginTop: 100,
			marginLeft: 0
		},
		height: '100%',
		// display: 'flex',
		background: 'linear-gradient(105deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)',
		overflowX: 'hidden'
	},
	drawerResponsive: {
		[theme.breakpoints.up('xs')]: {
			width: `calc(100% - 80px)`,
			height: 40,
			marginLeft: 440
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			height: 40,
			marginLeft: 0,
			top: 60
		},
		'& :hover': {
			cursor: 'pointer'
		}
	},
	drawer: {
		[theme.breakpoints.up('md')]: {
			width: drawerWidth,
			flexShrink: 0
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
			flexShrink: 0
		},
		overflowX: 'hidden'
	},
	container: {
		[theme.breakpoints.up('xs')]: {
			width: `calc(100% - ${drawerWidth}px -80px)`,
			// marginRight: drawerWidth,
			marginLeft: 440,
			marginTop: 40,
			// maxWidth: 1024,
			minWidth: 420
		},
		// [theme.breakpoints.down('sm')]: {
		// 	width: `calc(100% - 80px)`,
		// 	marginLeft: 80
		// },
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			marginLeft: 0,
			marginTop: 60,
			minWidth: 0
		}
	},
	containerDrawerMinimized: {
		[theme.breakpoints.up('xs')]: {
			width: `calc(100% - ${drawerWidth}px -80px)`,
			// marginRight: drawerWidth,
			marginLeft: 135,
			marginTop: 40
			// maxWidth: 1024
		},
		// [theme.breakpoints.down('sm')]: {
		// 	width: `calc(100% - 80px)`,
		// 	marginLeft: 80
		// },
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			marginLeft: 0,
			marginTop: 60
		}
	},
	mainContent: {
		[theme.breakpoints.up('xs')]: {
			width: `calc(100% - 40px)`,
			margin: '0 auto 0 auto'
		},
		[theme.breakpoints.down('xs')]: {
			width: `calc(100% - 0px)`,
			margin: '0'
		}
	},
	chapterHeading: {
		[theme.breakpoints.up('xs')]: {
			fontSize: '1.6em',
			letterSpacing: '-.05em',
			margin: '0px 0px -2px 0px'
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.1em',
			margin: '5px 0px -2px 0px'
		}
	},
	explainer: {
		[theme.breakpoints.up('xs')]: {
			fontSize: '.9em',
			margin: '0 0px 8px 0px',
			lineHeight: 1.2
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '.7em',
			margin: '0 0px 5px 0px',
			lineHeight: 1.2
		}
	},
	instructions: {
		[theme.breakpoints.up('xs')]: {
			fontSize: '.9em',
			margin: '0 0px 0px 0px',
			lineHeight: 1.2
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '.7em',
			margin: '0 0px 5px 0px',
			lineHeight: 1.2
		}
	},
	noteButton: {
		[theme.breakpoints.up('xs')]: {
			margin: '20px auto 0 auto'
		},
		[theme.breakpoints.down('xs')]: {
			margin: '20px auto 80px auto'
		}
	},
	complete: { margin: '0 auto 10px auto' },
	header: {
		fontSize: '3.4rem',
		// color: 'white',
		textAlign: 'left',
		letterSpacing: '-0.07em',
		lineHeight: '1.1em',
		maxWidth: '100%',
		margin: '120px 20px 20px 0'
	}
});

const drawerWidth = 360;

const AntSwitch = withStyles((theme) => ({
	root: {
		width: 28,
		height: 16,
		padding: 0,
		display: 'flex'
	},
	switchBase: {
		padding: 2,
		color: theme.palette.grey[500],
		'&$checked': {
			transform: 'translateX(12px)',
			color: theme.palette.common.white,
			'& + $track': {
				opacity: 1,
				backgroundColor: theme.palette.primary.main,
				borderColor: theme.palette.primary.main
			}
		}
	},
	thumb: {
		width: 12,
		height: 12,
		boxShadow: 'none'
	},
	track: {
		border: `1px solid ${theme.palette.grey[500]}`,
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor: theme.palette.common.white
	},
	checked: {}
}))(Switch);

class StarterPack extends Component {
	static defaultProps = {
		chaptersEOL: [
			{
				name: 'care',
				cardId: 26,
				src: careCover,
				low: 1,
				high: 5,
				title: 'care',
				description: `how you'll want to be cared for in your last chapter`
			},
			{
				name: 'body',
				cardId: 27,
				src: bodyCover,
				low: 6,
				high: 10,
				title: 'body',
				description: `how your body should be laid to rest after your passing`
			},
			{
				name: 'ceremony',
				cardId: 28,
				src: ceremonyCover,
				low: 11,
				high: 15,
				title: 'ceremony',
				description: `how your life is honored and memorialized`
			},
			{
				name: 'treasures',
				cardId: 29,
				src: treasuresCover,
				low: 16,
				high: 20,
				title: 'treasures',
				description: `how your assets and responsibilities should be handled`
			},
			{
				name: 'legacy',
				cardId: 30,
				src: legacyCover,
				low: 21,
				high: 25,
				title: 'legacy',
				description: `how your story and values live on after you're gone`
			}
		],
		cardsEOL: [
			{
				src: care1,
				srcSmall: s1,
				id: 1,
				chapter: 'care',
				chapterImg: careCover,
				prompt: 'What would be the ideal environment in which you’d like to be cared for?',
				subprompt:
					'Create a vision for the best case scenario, who you’d be surrounded by, where you’d be – get specific',
				tag: 'What environment would you want for your care?'
			},
			{
				src: care2,
				srcSmall: s2,
				id: 2,
				chapter: 'care',
				prompt: 'Who would you choose to be your guardian, and manage your finances?',
				subprompt:
					'This person would have control over all your finances and more – you’ll want to select a backup as well',
				tag: 'Who would be your legal guardian?'
			},
			{
				src: care3,
				srcSmall: s3,
				id: 3,
				chapter: 'care',
				prompt: 'If you are no longer breathing or your heart has stopped, would you want to be resuscitated?',
				subprompt: 'This is especially important for emergency responders',
				tag: 'Do you want to be resuscitated?'
			},
			{
				src: care4,
				srcSmall: s4,
				id: 4,
				chapter: 'care',
				prompt:
					'Would you want to continue treatment even if you became terminally ill or permanently unconscious?',
				subprompt:
					'You can choose to refuse treatment or leave it to your agent to decide for either of the two scenarios',
				tag: 'What to do if terminally ill or in a coma?'
			},
			{
				src: care5,
				srcSmall: s5,
				id: 5,
				chapter: 'care',
				chapterImg: careCover,
				prompt:
					'Who will be the person with the authority to make healthcare decisions on your behalf, if you’re unable to?',
				subprompt:
					'This person is called your “agent” – you’ll also want to decide on one or two backup options',
				tag: 'Who will be your "agent"?'
			},
			{
				src: body1,
				srcSmall: s6,
				id: 6,
				chapter: 'body',
				chapterImg: bodyCover,
				prompt: 'What do you want to become of your body?',
				subprompt:
					'You can be buried or cremated, but you can also become a tree, a gem, cast off to sea, you can do an eco-friendly burial or cremation, be cryogenically frozen, or give your body to science – so many choices!',
				tag: 'What should become of your body?'
			},
			{
				src: body2,
				srcSmall: s7,
				id: 7,
				chapter: 'body',
				chapterImg: bodyCover,
				prompt: 'Where do you want your body to be laid to rest?',
				subprompt:
					'Think of places where you spent a lot of time or had meaningful moments, or even places you never got to go',
				tag: 'Where should you be laid to rest?'
			},
			{
				src: body3,
				srcSmall: s8,
				id: 8,
				chapter: 'body',
				chapterImg: bodyCover,
				prompt: 'What do you want inscribed at the place where your body is memorialized?',
				subprompt:
					'Whether you have a headstone or another kind of marker, consider what should be written at your resting place',
				tag: 'What to inscribe at your memorial site?'
			},
			{
				src: body4,
				srcSmall: s9,
				id: 9,
				chapter: 'body',
				chapterImg: bodyCover,
				prompt:
					'Are you comfortable donating any or all of your body to save lives or advance scientific research?',
				subprompt:
					'From organ donation to becoming a crash-test-dummy, there are lots of ways your body can save lives',
				tag: 'Should your organs be donated?'
			},
			{
				src: body5,
				srcSmall: s10,
				id: 10,
				chapter: 'body',
				chapterImg: bodyCover,
				prompt: 'Who will be the person making sure your wishes for your body are honored?',
				subprompt: 'This helps ensure your loved ones don’t fight over these decisions',
				tag: 'Who should be responsible for handling your remains?'
			},
			{
				src: ceremony1,
				srcSmall: s11,
				id: 11,
				chapter: 'ceremony',
				chapterImg: ceremonyCover,
				prompt: 'What should actually happen at your ceremony? How should it feel?',
				subprompt: 'Think about the kind of experience you want to create for the attendees',
				tag: 'What should happen at your funeral?'
			},
			{
				src: ceremony2,
				srcSmall: s12,
				id: 12,
				chapter: 'ceremony',
				chapterImg: ceremonyCover,
				prompt: 'Where do you imagine your ceremony taking place?',
				subprompt: 'This could either be a specific spot or more general, like “by the beach”',
				tag: 'Where should your funeral take place?'
			},
			{
				src: ceremony3,
				srcSmall: s13,
				id: 13,
				chapter: 'ceremony',
				chapterImg: ceremonyCover,
				prompt: 'What details do you picture when you think of the experience of the ceremony itself?',
				subprompt: 'Think about specific artifacts, smells, moments, foods, music, colors...',
				tag: 'What details do you picture for your funeral?'
			},
			{
				src: ceremony4,
				srcSmall: s14,
				id: 14,
				chapter: 'ceremony',
				chapterImg: ceremonyCover,
				prompt: 'Is there anything you would like to write or create that should be shared at your ceremony?',
				subprompt:
					'Think about what you’d like to  share with the group of people that would be gathered at your ceremony',
				tag: 'What should be shared at your funeral?'
			},
			{
				src: ceremony5,
				srcSmall: s15,
				id: 15,
				chapter: 'ceremony',
				chapterImg: ceremonyCover,
				prompt: 'Who will be the person making sure your ceremony happens as you’ve imagined it?',
				subprompt: 'It’s an honor to ask someone to put together your ceremony but also a lot of work',
				tag: 'Who should organize your funeral?'
			},
			{
				src: treasures1,
				srcSmall: s16,
				id: 16,
				chapter: 'treasures',
				chapterImg: treasuresCover,
				prompt: 'Of all your valuables, what do you want to make sure goes to specific people?',
				subprompt:
					'By default, anything you don’t designate for a specific person falls on your executor to distribute fairly',
				tag: 'What valuables do you want to pass down?'
			},
			{
				src: treasures2,
				srcSmall: s17,
				id: 17,
				chapter: 'treasures',
				chapterImg: treasuresCover,
				prompt: 'Who will take in your dependents (kids, pets, etc) and how will they be compensated?',
				subprompt: 'Think about how you might start fostering these relationships today',
				tag: 'Who will take care of your kids and pets?'
			},
			{
				src: treasures3,
				srcSmall: s18,
				id: 18,
				chapter: 'treasures',
				chapterImg: treasuresCover,
				prompt:
					'Who will inherit all the stuff you didn’t specifically assign, and how are you dividing it up fairly?',
				subprompt: 'Sometimes giving everyone the same is not actually what’s most fair',
				tag: 'How will you distribute your estate?'
			},
			{
				src: treasures4,
				srcSmall: s19,
				id: 19,
				chapter: 'treasures',
				chapterImg: treasuresCover,
				prompt:
					'What is the plan for your biggest assets, including any real estate, businesses, investments, etc?',
				subprompt: 'Consider creating a transition plan for anything you actively manage',
				tag: 'What is your plan for your biggest assets?'
			},
			{
				src: treasures5,
				srcSmall: s20,
				id: 20,
				chapter: 'treasures',
				chapterImg: treasuresCover,
				prompt: 'Who will be the person making sure your estate is distributed as intended?',
				subprompt:
					'This person is called the “executor” of your Last Will and Testament – also you’ll want to designate one or two backup options for this role',
				tag: 'Who will be your "executor"?'
			},
			{
				src: legacy1,
				srcSmall: s21,
				id: 21,
				chapter: 'legacy',
				chapterImg: legacyCover,
				prompt: 'How do you hope your family history and life story will be preserved?',
				subprompt:
					'Think about all the different ways you’ve learned about those who preceded you (recipes, stories, songs, photographs...)',
				tag: 'How will your life story be preserved?'
			},
			{
				src: legacy2,
				srcSmall: s22,
				id: 22,
				chapter: 'legacy',
				chapterImg: legacyCover,
				prompt: 'What causes can people honor you by supporting after you’re gone?',
				subprompt: 'Consider how a scholarship fund or foundation might serve this work',
				tag: 'What causes can people support to honor you?'
			},
			{
				src: legacy3,
				srcSmall: s23,
				id: 23,
				chapter: 'legacy',
				chapterImg: legacyCover,
				prompt: 'Who would you still want to settle up with at some point?',
				subprompt: 'Think about the people and conversations you’ve been avoiding',
				tag: 'Who do you still want to settle up with?'
			},
			{
				src: legacy4,
				srcSmall: s24,
				id: 24,
				chapter: 'legacy',
				chapterImg: legacyCover,
				prompt: 'Who would you like to receive a letter or video from you once you’re gone?',
				subprompt: 'Think about what lessons and stories you’d like shared at future milestones',
				tag: 'Who would you like to leave a letter or video to?'
			},
			{
				src: legacy5,
				srcSmall: s25,
				id: 25,
				chapter: 'legacy',
				chapterImg: legacyCover,
				prompt: 'Who do you hope will continue to carry the torch for you after you’re gone?',
				subprompt:
					'Consider what conversations today will help ensure that the work you’ve started can continue in your absence',
				tag: 'Who do you hope will carry on your legacy?'
			}
		],
		chaptersCOVID: [
			{ name: 'preparation', cardId: 26, src: preparationCover, low: 26, high: 30 },
			{ name: 'separation', cardId: 27, src: separationCover, low: 31, high: 35 },
			{ name: 'celebration', cardId: 28, src: celebrationCover, low: 36, high: 40 },
			{ name: 'creation', cardId: 29, src: creationCover, low: 41, high: 45 },
			{ name: 'stabilization', cardId: 30, src: stabilizationCover, low: 46, high: 50 }
		],
		cardsCOVID: [
			{
				src: preparation1,
				srcSmall: s26,
				id: 26,
				chapter: 'preparation'
			},
			{
				src: preparation2,
				srcSmall: s27,
				id: 27,
				chapter: 'preparation'
			},
			{
				src: preparation3,
				srcSmall: s28,
				id: 28,
				chapter: 'preparation'
			},
			{
				src: preparation4,
				srcSmall: s29,
				id: 29,
				chapter: 'preparation'
			},
			{
				src: preparation5,
				srcSmall: s30,
				id: 30,
				chapter: 'preparation'
			},
			{
				src: separation1,
				srcSmall: s31,
				id: 31,
				chapter: 'separation'
			},
			{
				src: separation2,
				srcSmall: s32,
				id: 32,
				chapter: 'separation'
			},
			{
				src: separation3,
				srcSmall: s33,
				id: 33,
				chapter: 'separation'
			},
			{
				src: separation4,
				srcSmall: s34,
				id: 34,
				chapter: 'separation'
			},
			{
				src: separation5,
				srcSmall: s35,
				id: 35,
				chapter: 'separation'
			},
			{
				src: celebration1,
				srcSmall: s36,
				id: 36,
				chapter: 'celebration'
			},
			{
				src: celebration2,
				srcSmall: s37,
				id: 37,
				chapter: 'celebration'
			},
			{
				src: celebration3,
				srcSmall: s38,
				id: 38,
				chapter: 'celebration'
			},
			{
				src: celebration4,
				srcSmall: s39,
				id: 39,
				chapter: 'celebration'
			},
			{
				src: celebration5,
				srcSmall: s40,
				id: 40,
				chapter: 'celebration'
			},
			{
				src: creation1,
				srcSmall: s41,
				id: 41,
				chapter: 'creation'
			},
			{
				src: creation2,
				srcSmall: s42,
				id: 42,
				chapter: 'creation'
			},
			{
				src: creation3,
				srcSmall: s43,
				id: 43,
				chapter: 'creation'
			},
			{
				src: creation4,
				srcSmall: s44,
				id: 44,
				chapter: 'creation'
			},
			{
				src: creation5,
				srcSmall: s45,
				id: 45,
				chapter: 'creation'
			},
			{
				src: stabilization1,
				srcSmall: s46,
				id: 46,
				chapter: 'stabilization'
			},
			{
				src: stabilization2,
				srcSmall: s47,
				id: 47,
				chapter: 'stabilization'
			},
			{
				src: stabilization3,
				srcSmall: s48,
				id: 48,
				chapter: 'stabilization'
			},
			{
				src: stabilization4,
				srcSmall: s49,
				id: 49,
				chapter: 'stabilization'
			},
			{
				src: stabilization5,
				srcSmall: s50,
				id: 50,
				chapter: 'stabilization'
			}
		]
	};

	constructor (props) {
		super(props);
		this.props.getMyNotes();
		let updatedActiveCard = '';
		if (this.props.location.state !== undefined) {
			updatedActiveCard = this.props.location.state.linkedCardId;
		}
		else if (
			JSON.parse(window.localStorage.getItem('cardId') !== '' && window.localStorage.getItem('cardId') !== null)
		) {
			updatedActiveCard = JSON.parse(window.localStorage.getItem('cardId'));
		}
		else {
			updatedActiveCard = '';
		}
		this.state = {
			activeChapter: '',
			activeCardId: updatedActiveCard,
			profile: null,
			existingNote: true,
			existingNoteId: '',
			body: '',
			errors: {},
			isPublic: false,
			isComplete: false,
			isCompleteSlider: 30,
			isEditing: false,
			inspiration: false,
			mobileOpen: false,
			cardDrawerOpen: true,
			fux: 1,
			cardStatus: [
				'blank',
				'blank',
				'blank',
				'blank',
				'blank',
				'blank',
				'blank',
				'blank',
				'blank',
				'blank',
				'blank',
				'blank',
				'blank',
				'blank',
				'blank',
				'blank',
				'blank',
				'blank',
				'blank',
				'blank',
				'blank',
				'blank',
				'blank',
				'blank',
				'blank'
			],
			chapterUnlocked: [
				false,
				false,
				false,
				false,
				false
			]
		};
		this.changeCard = this.changeCard.bind(this);
		this.changeChapter = this.changeChapter.bind(this);
		this.handleOpen = this.handleOpen.bind(this);
		this.updateCards = this.updateCards.bind(this);
		// this.props.selectTransition(this.props.match.params.transition);
		window.scrollTo(0, 0);
		// let updatedActiveCard = '';
		// let resetChapter = '';
		// if (this.props.location.state !== undefined) {
		// 	this.setState(() =>
		// 		window.localStorage.setItem('cardId', JSON.stringify(this.props.location.state.linkedCardId))
		// 	);
		// 	updatedActiveCard = this.props.location.state.linkedCardId;
		// let fromLocationState = this.props.location.state.linkedCardId;
		// if (fromLocationState > 0 && fromLocationState <= 25 && this.props.match.params.transition === 'eol') {
		// 	updatedActiveCard = fromLocationState;
		// 	resetChapter = this.props.cardsEOL[fromLocationState - 1].chapter;
		// }
		// else if (
		// 	fromLocationState > 25 &&
		// 	fromLocationState <= 50 &&
		// 	this.props.match.params.transition === 'covid'
		// ) {
		// 	updatedActiveCard = fromLocationState;
		// 	resetChapter = this.props.cardsEOL[fromLocationState - 26].chapter;
		// }
		// else updatedActiveCard = '';
		// }
		// else if (
		// 	JSON.parse(window.localStorage.getItem('cardId') !== '' && window.localStorage.getItem('cardId') !== null)
		// ) {
		// 	updatedActiveCard = JSON.parse(window.localStorage.getItem('cardId'));
		// let fromLocal = window.localStorage.getItem('cardId');
		// if (fromLocal > 0 && fromLocal <= 25 && this.props.match.params.transition === 'eol') {
		// 	updatedActiveCard = JSON.parse(fromLocal);
		// 	resetChapter = this.props.cardsEOL[fromLocal - 1].chapter;
		// }
		// else if (fromLocal > 25 && fromLocal <= 50 && this.props.match.params.transition === 'covid') {
		// 	updatedActiveCard = JSON.parse(fromLocal);
		// 	resetChapter = this.props.cardsCOVID[fromLocal - 26].chapter;
		// }
		// else updatedActiveCard = '';
		// }
		// else {
		// 	updatedActiveCard = '';
		// }

		// this.setState({ activeChapter: resetChapter, activeCardId: updatedActiveCard });
	}

	componentDidMount () {
		this.setState(() => window.localStorage.setItem('cardId', JSON.stringify(this.state.activeCardId)));
		this.props.getMyNotes();
		// this.checkExists();
		if (this.state.activeCardId !== null && this.state.activeCardId !== '') {
			this.toggleEdit();
		}
		this.updateCards();
	}

	componentWillReceiveProps (nextProps) {
		if (nextProps.UI.errors) {
			this.setState({
				errors: nextProps.UI.errors
			});
		}
		if (!nextProps.UI.errors && !nextProps.UI.loading) {
			this.setState({
				errors: {}
			});
		}
		if (!nextProps.data.loading || nextProps.data.myNotes !== [] || !nextProps.UI.loading) {
			this.updateCards();
			if (this.state.cardStatus[this.state.activeCardId - 1] !== 'blank') {
				this.props.data.myNotes.map((note) => {
					if (note.linkedCardId === this.state.activeCardId) {
						this.setState({
							existingNote: true,
							existingNoteId: note.noteId,
							body: note.body,
							isPublic: note.isPublic,
							isComplete: note.isComplete,
							isCompleteSlider: note.isCompleteSlider,
							isEditing: false
						});
					}
					return null;
				});
				// for (var i = 0; i < this.props.data.myNotes.length; i++) {
				// 	if (this.props.data.myNotes[i].linkedCardId === this.state.activeCardId) {
				// 		this.setState({
				// 			existingNote: true,
				// 			existingNoteId: this.props.data.myNotes[i].noteId,
				// 			body: this.props.data.myNotes[i].body,
				// 			isPublic: this.props.data.myNotes[i].isPublic,
				// 			isComplete: this.props.data.myNotes[i].isComplete,
				// 			isEditing: false
				// 		});
				// 		break;
				// 	}
				// }
			}
		}
	}

	async checkExists () {
		try {
			let myNotesCheck = await axios.get('/mynotes');
			let updatedCardStatus = this.state.cardStatus;
			if (myNotesCheck.data.some((obj) => obj.linkedCardId === this.state.activeCardId)) {
				myNotesCheck.data.map((note) => {
					if (note.isComplete) {
						updatedCardStatus.splice(note.linkedCardId - 1, 1, 'check');
					}
					else {
						updatedCardStatus.splice(note.linkedCardId - 1, 1, 'filled');
					}
					return null;
				});
				this.setState({ cardStatus: updatedCardStatus });
				this.checkLocked();
				for (var i = 0; i < myNotesCheck.data.length; i++) {
					if (myNotesCheck.data[i].linkedCardId === this.state.activeCardId) {
						this.setState({
							existingNote: true,
							existingNoteId: myNotesCheck.data[i].noteId,
							body: myNotesCheck.data[i].body,
							isPublic: myNotesCheck.data[i].isPublic,
							isComplete: myNotesCheck.data[i].isComplete,
							isCompleteSlider: myNotesCheck.data[i].isCompleteSlider,
							isEditing: false
						});
						break;
					}
				}
			}
		} catch (err) {
			// alert(err);
		}
	}

	handleOpen () {
		if (this.existingNoteId !== '') {
			this.setState({ isEditing: true });
		}
		else {
			this.changeCard(this.state.activeCardId);
		}
		if (this.state.activeCardId !== '' && this.state.body === '') {
			this.changeCard(this.state.activeCardId);
		}
	}
	changeCard (newCardId) {
		// this.props.getNotes();
		this.updateCards();
		if (this.props.history.location.state !== undefined) {
			this.props.history.push(`/${this.props.UI.transition}/mywishes`);
		}

		if (this.state.cardStatus[newCardId - 1] !== 'blank') {
			this.props.data.myNotes.map((note) => {
				if (note.linkedCardId === newCardId) {
					this.setState({
						existingNote: true,
						existingNoteId: note.noteId,
						body: note.body,
						isPublic: note.isPublic,
						isComplete: note.isComplete,
						isCompleteSlider: note.isCompleteSlider,
						isEditing: false
					});
				}
				return null;
			});
			// for (var i = 0; i < this.props.data.myNotes.length; i++) {
			// 	if (this.props.data.myNotes[i].linkedCardId === newCardId) {
			// 		this.setState({
			// 			existingNote: true,
			// 			existingNoteId: this.props.data.myNotes[i].noteId,
			// 			body: this.props.data.myNotes[i].body,
			// 			isPublic: this.props.data.myNotes[i].isPublic,
			// 			isComplete: this.props.data.myNotes[i].isComplete,
			// 			isEditing: false
			// 		});
			// 		break;
			// 	}
			// }
		}
		else {
			this.setState({
				existingNote: false,
				existingNoteId: '',
				body: '',
				isPublic: false,
				isComplete: false,
				isCompleteSlider: 30,
				isEditing: true
			});
		}

		if (newCardId > 0 && newCardId <= 30) {
			this.setState({ activeCardId: newCardId, mobileOpen: false });
			this.setState(() => window.localStorage.setItem('cardId', JSON.stringify(newCardId)));
		}

		if (newCardId === this.state.activeCardId) {
			this.setState({ activeCardId: '' });
		}
	}
	changeChapter (newChapter) {
		if (this.props.history.location.state !== undefined) {
			this.props.history.push('/eol/mywishes');
		}
		this.setState({
			activeChapter: newChapter.chapter,
			activeCardId: '',
			existingNote: false,
			existingNoteId: '',
			body: '',
			isPublic: false,
			isComplete: false,
			isCompleteSlider: 30,
			isEditing: false
		});
	}
	updateCards () {
		const updatedCardStatus = this.state.cardStatus;
		this.props.data.myNotes.map((note) => {
			if (note.isComplete) {
				updatedCardStatus.splice(note.linkedCardId - 1, 1, 'check');
			}
			else {
				updatedCardStatus.splice(note.linkedCardId - 1, 1, 'filled');
			}
			return null;
		});
		this.setState({ cardStatus: updatedCardStatus });
		this.checkLocked();
	}
	toggleEdit = () => {
		this.setState({ isEditing: true });
	};
	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};
	handleSubmit = (event) => {
		if (this.state.body === '') {
			this.setState({
				isEditing: false,
				existingNote: false,
				isPublic: false,
				isComplete: false,
				isCompleteSlider: 30
			});
		}
		else {
			// event.preventDefault();
			const noteData = {
				body: this.state.body,
				linkedCardId: this.state.activeCardId,
				isPublic: this.state.isPublic,
				isComplete: this.state.isComplete,
				isCompleteSlider: this.state.isCompleteSlider
			};
			if (this.state.existingNote === false) {
				this.props.postNote(noteData);
			}
			else {
				this.props.updateNote(noteData, this.state.existingNoteId);
			}
			// this.changeCard(this.state.activeCardId);
			this.props.getMyNotes();
			this.updateCards();
			this.setState({ isEditing: false, existingNote: true });
			// this.setState({ submitted: true }, () => {
			// 	setTimeout(() => this.setState({ submitted: false }), 1500);
			// });
			// this.setState({ [event.target.name]: event.target.value });
		}
	};
	handleTogglePublic = (event) => {
		this.setState({ isPublic: event.target.checked });
	};
	handleToggleComplete = (event) => {
		this.setState({ isComplete: event.target.checked });
	};
	handleChangeSlider = (event) => {
		let complete = false;
		let rating = event.target.textContent;
		if (rating >= 80) {
			complete = true;
		}
		this.setState({ isCompleteSlider: rating, isComplete: complete });
	};
	handleInspire = () => {
		this.props.getNotes();
		this.setState({ inspiration: true });
	};
	randomCard = () => {
		let randomNumber = Math.floor(Math.random() * 25) + 1;
		this.changeCard(randomNumber);
	};
	checkLocked = () => {
		let updatedChapterUnlocked = this.state.chapterUnlocked;
		this.props.chaptersEOL.map((chapter, i) => {
			let low = chapter.low;
			if (
				this.state.cardStatus[low - 1] === 'check' &&
				this.state.cardStatus[low] === 'check' &&
				this.state.cardStatus[low + 1] === 'check' &&
				this.state.cardStatus[low + 2] === 'check' &&
				this.state.cardStatus[low + 3] === 'check'
			) {
				updatedChapterUnlocked.splice(i, 1, true);
			}
			else if (updatedChapterUnlocked[i] === true) {
				updatedChapterUnlocked.splice(i, 1, false);
			}
			return null;
		});
		this.setState({ chapterUnlocked: updatedChapterUnlocked });
	};
	handleDrawerToggle = () => {
		this.setState({ mobileOpen: !this.state.mobileOpen });
	};
	fuxProceed = (diff) => {
		this.setState({ fux: this.state.fux + diff });
	};

	render () {
		const {
			classes,
			cardsEOL,
			chaptersEOL,
			user: { credentials: { handle } },
			data: { notes, myNotes }
		} = this.props;

		let cardsMarkup = chaptersEOL.map((chapter, i) => {
			let name = chapter.name;
			let low = chapter.low;
			let high = chapter.high;
			let checkImg = '';
			let blankImg = '';
			let filledImg = '';
			let lockedImg = '';
			// let iconImg = '';
			if (name === 'care') {
				checkImg = careCheck;
				blankImg = careBlank;
				filledImg = careFilled;
				lockedImg = careLocked;
				// iconImg = careIcon;
			}
			else if (name === 'body') {
				checkImg = bodyCheck;
				blankImg = bodyBlank;
				filledImg = bodyFilled;
				lockedImg = bodyLocked;
				// iconImg = bodyIcon;
			}
			else if (name === 'ceremony') {
				checkImg = ceremonyCheck;
				blankImg = ceremonyBlank;
				filledImg = ceremonyFilled;
				lockedImg = ceremonyLocked;
				// iconImg = ceremonyIcon;
			}
			else if (name === 'treasures') {
				checkImg = treasuresCheck;
				blankImg = treasuresBlank;
				filledImg = treasuresFilled;
				lockedImg = treasuresLocked;
				// iconImg = treasuresIcon;
			}
			else if (name === 'legacy') {
				checkImg = legacyCheck;
				blankImg = legacyBlank;
				filledImg = legacyFilled;
				lockedImg = legacyLocked;
				// iconImg = legacyIcon;
			}
			return (
				<div key={i}>
					<Grid item xs={12}>
						<Typography
							className={classes.chapterHeading}
							style={{ color: 'rgba(0,0,0,1)', textAlign: 'left' }}
						>
							{chapter.title}
						</Typography>
						<Typography
							className={classes.explainer}
							style={{ color: 'rgba(255,255,255,1)', textAlign: 'left' }}
						>
							{chapter.description}
						</Typography>
					</Grid>
					<Grid container item xs={12} spacing={1} justify="center" alignItems="stretch">
						{cardsEOL.map((card) => {
							let cardImg = '';
							if (this.state.cardStatus[card.id - 1] === 'blank') {
								cardImg = blankImg;
							}
							else if (this.state.cardStatus[card.id - 1] === 'filled') {
								cardImg = filledImg;
							}
							else if (this.state.cardStatus[card.id - 1] === 'check') {
								cardImg = checkImg;
							}
							if (card.id >= low && card.id <= high) {
								return (
									<Grid key={card.id} item xs onClick={() => this.changeCard(card.id)}>
										<Tooltip placement="top" TransitionComponent={Zoom} title={card.tag}>
											<Card
												className={classes.card}
												style={
													this.state.activeCardId === '' ||
													this.state.activeCardId === card.id ||
													this.state.activeCardId === chapter.cardId ? (
														{
															opacity: 1,
															transition: 'opacity 200ms ease-in',
															border: 'solid 1px white'
														}
													) : (
														{
															opacity: 0.2,
															transition: 'opacity 200ms ease-in',
															border: 'solid 1px white'
														}
													)
												}
											>
												<CardMedia
													component="img"
													alt={`${card.chapter} card`}
													className={classes.cover}
													image={cardImg}
													title={`${card.chapter} card`}
												/>
											</Card>
										</Tooltip>
									</Grid>
								);
							}
							else return null;
						})}
						{this.state.chapterUnlocked[i] ? (
							<Grid
								container
								item
								xs
								style={{ margin: '0 -8px 0 15px' }}
								alignItems="center"
								justify="center"
								onClick={() => this.changeCard(chapter.cardId)}
							>
								<Grid item>
									<Card
										className={classes.cardUnlocked}
										style={
											this.state.activeCardId === chapter.cardId ||
											this.state.activeCardId === '' ? (
												{
													opacity: 1,
													transition: 'opacity 200ms ease-in',
													border: 'solid 1px white'
												}
											) : (
												{
													opacity: 0.2,
													transition: 'opacity 200ms ease-in',
													border: 'solid 1px white'
												}
											)
										}
									>
										<CardMedia
											component="img"
											alt={`${chapter} instructions unlocked`}
											className={classes.cover}
											image={filledImg}
											style={
												this.state.activeCardId === chapter.cardId ? (
													{
														// transform: 'scale(1.5)',
														// transition: 'transform 200ms ease-in-out',
														maxWidth: '100%',
														margin: 'auto'
														// border: 'solid 1px #fff'
													}
												) : (
													{ maxWidth: '100%', margin: 'auto' }
												)
											}
										/>
									</Card>
								</Grid>
							</Grid>
						) : (
							<Grid item xs style={{ margin: '0 -8px 0 15px' }}>
								<Card
									className={classes.cardLocked}
									style={
										this.state.activeCardId === '' ? (
											{
												opacity: 1,
												transition: 'opacity 200ms ease-in',
												border: 'solid 1px white'
											}
										) : (
											{
												opacity: 0.2,
												transition: 'opacity 200ms ease-in',
												border: 'solid 1px white'
											}
										)
									}
								>
									<CardMedia
										component="img"
										alt={`${chapter} instructions locked`}
										className={classes.cover}
										image={lockedImg}
									/>
								</Card>
							</Grid>
						)}
					</Grid>
				</div>
			);
		});

		let promptMarkup =
			this.state.activeCardId >= 1 && this.state.activeCardId <= 25 ? (
				<Fragment>
					<Typography variant="body2" className={classes.prompt}>
						{cardsEOL[this.state.activeCardId - 1].prompt}
					</Typography>
					<Typography variant="body2" className={classes.subprompt}>
						{cardsEOL[this.state.activeCardId - 1].subprompt}
					</Typography>
				</Fragment>
			) : this.state.activeCardId >= 26 && this.state.activeCardId <= 30 ? (
				<Fragment>
					<Typography variant="body2" className={classes.prompt}>
						Hi! You've made it
					</Typography>
					<Typography variant="body2" className={classes.subprompt}>
						Congratulations
					</Typography>
				</Fragment>
			) : (
				<Fragment>
					<Typography variant="body2" className={classes.header}>
						Welcome!
					</Typography>
					{this.state.fux === 1 ? (
						<Fragment>
							{' '}
							<Typography variant="body2" className={classes.prompt}>
								You're currently in the "my wishes" section.
							</Typography>
							<Typography variant="body2" className={classes.subprompt}>
								Here, you will draw cards that will prompt you to think about different aspects of your
								last chapters. Take a minute to familiarize yourself with the five chapters: care, body,
								ceremony, treasures, and legacy.
							</Typography>
						</Fragment>
					) : this.state.fux === 2 ? (
						<Fragment>
							<Typography variant="body2" className={classes.prompt}>
								Your goal is to complete all five of the 'instructions' cards.
							</Typography>
							<Typography variant="body2" className={classes.subprompt}>
								Each instruction card is unlocked only once all five cards in the corresponding chapter
								have been answered and completed. A response is considered complete when you mark it as
								80% complete or higher.
							</Typography>
						</Fragment>
					) : this.state.fux === 3 ? (
						<Fragment>
							<Typography variant="body2" className={classes.prompt}>
								The two other sections are "my circle" and "explore".
							</Typography>
							<Typography variant="body2" className={classes.subprompt}>
								In "my circle" you can choose specific people to include in your circle, giving them
								access to all your notes and wishes, even the ones that are private. In "explore" you
								can browse public responses from around the world.
							</Typography>
						</Fragment>
					) : this.state.fux === 4 ? (
						<Fragment>
							<Typography variant="body2" className={classes.prompt}>
								Time to choose a card!
							</Typography>
							<Typography variant="body2" className={classes.subprompt}>
								Don't overthink it. Just decide which chapter you want to start with and pick any card.
								You can always draw another... Or just click the button to draw a random card.
							</Typography>
						</Fragment>
					) : null}
					{this.state.fux > 1 && this.state.fux < 4 ? (
						<Button
							onClick={() => this.fuxProceed(-1)}
							variant="contained"
							className={classes.noteButton}
							style={{
								fontSize: '.85em',
								background:
									'linear-gradient(165deg,rgba(255,255,255, 1) 0%, rgba(225,225,225, 1) 100%)',
								color: '#aaa',
								letterSpacing: '-0.03em',
								borderRadius: '5px',
								border: 'none',
								margin: '0px auto 10px auto',
								padding: '2px auto 12px auto',
								width: '80px'
							}}
						>
							back
						</Button>
					) : null}
					{(this.state.fux > 0 && this.state.fux) < 4 ? (
						<Button
							onClick={() => this.fuxProceed(1)}
							variant="contained"
							className={classes.noteButton}
							style={
								this.state.fux > 1 ? (
									{
										fontSize: '.85em',
										background:
											'linear-gradient(165deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)',
										color: '#fff',
										letterSpacing: '-0.03em',
										borderRadius: '5px',
										border: 'none',
										margin: '0px auto 10px 10px',
										padding: '2px auto 12px auto',
										width: 'calc(100% - 94px)'
									}
								) : (
									{
										fontSize: '.85em',
										background:
											'linear-gradient(165deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)',
										color: '#fff',
										letterSpacing: '-0.03em',
										borderRadius: '5px',
										border: 'none',
										margin: '0px auto 10px auto',
										padding: '2px auto 12px auto',
										width: '100%'
									}
								)
							}
						>
							next
						</Button>
					) : null}
					{this.state.fux === 4 ? (
						<Button
							onClick={this.randomCard}
							variant="contained"
							className={classes.noteButton}
							style={{
								fontSize: '.85em',
								background:
									'linear-gradient(165deg,rgba(255,255,255, 1) 0%, rgba(225,225,225, 1) 100%)',
								color: '#aaa',
								letterSpacing: '-0.03em',
								borderRadius: '5px',
								border: 'none',
								margin: '0px auto 10px auto',
								padding: '2px auto 12px auto',
								width: '80px'
							}}
						>
							random
						</Button>
					) : null}
					<Typography variant="body2" className={classes.explainer} style={{ color: '#aaa' }}>
						{this.state.fux}/4
					</Typography>
				</Fragment>
			);

		let bigNoteMarkup =
			this.props.data.loading || this.props.user.loading ? (
				<div style={{ height: '55px' }}>
					<Paper
						style={{
							height: 20,
							width: 20,
							backgroundColor: 'white',
							borderRadius: '50%',
							margin: '50px auto 15px auto'
						}}
						className={classes.loading}
					>
						<CircularProgress size={20} style={{ color: 'rgba(0,0,0,0.3)' }} />
					</Paper>
				</div>
			) : // is it editing?
			this.state.isEditing ? (
				// <ClickAwayListener onClickAway={this.handleSubmit}>
				<Paper elevation={0} className={classes.notePaper}>
					<form onSubmit={this.handleSubmit}>
						<TextareaAutosize
							name="body"
							type="text"
							label=""
							placeholder="write your response..."
							rowsMin="4"
							className={classes.textareaAutosize}
							onChange={this.handleChange}
							value={this.state.body}
							// defaultValue={this.state.body}
							// multiline
							// error={errors.body ? true : false}
							// helperText={errors.body}
							// fullWidth
						/>

						<Divider variant="fullWidth" style={{ marginBottom: 20 }} />
						<Grid container direction="column" alignItems="center" spacing={1} style={{ flexGrow: 1 }}>
							<Grid item xs={12}>
								{/* {this.state.isComplete ? (
									<CheckCircleIcon color="primary" className={classes.complete} />
								) : (
									<CheckCircleOutlineIcon style={{ color: '#aaaaaa' }} className={classes.complete} />
								)} */}
								<Typography
									component="div"
									variant="body2"
									className={classes.publicSwitch}
									align="center"
									style={{ lineHeight: 1.2, marginBottom: 50 }}
								>
									How complete is your response?<br />
									<span style={{ color: '#aaa', fontSize: '0.85em' }}>
										(80 and above is considered "complete")
									</span>
								</Typography>

								<Slider
									defaultValue={this.state.isCompleteSlider}
									aria-labelledby="discrete-slider"
									valueLabelDisplay="on"
									step={5}
									marks
									min={0}
									max={100}
									color={this.state.isCompleteSlider >= 80 ? 'primary' : 'secondary'}
									// value={this.state.isCompleteSlider}
									onChangeCommitted={this.handleChangeSlider}
								/>
								{/* <Typography component="div" variant="body2" className={classes.publicSwitch}>
									<Grid container component="label" alignItems="center" justify="center" spacing={1}>
										<Grid item>incomplete</Grid>
										<Grid item>
											<AntSwitch
												checked={this.state.isComplete}
												onChange={this.handleToggleComplete}
												name="isComplete"
											/>
										</Grid>
										<Grid item>complete</Grid>
									</Grid>
								</Typography> */}
							</Grid>
							<Grid item xs={12}>
								<Typography component="div" variant="body2" className={classes.publicSwitch}>
									<Grid container component="label" alignItems="center" justify="center" spacing={1}>
										<Grid item>private</Grid>
										<Grid item>
											<AntSwitch
												checked={this.state.isPublic}
												onChange={this.handleTogglePublic}
												name="isPublic"
											/>
										</Grid>
										<Grid item>public</Grid>
									</Grid>
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								style={{
									width: '100%'
								}}
							>
								<Button
									onClick={this.handleSubmit}
									variant="contained"
									className={classes.noteButton}
									style={{
										padding: 'auto 50px auto 50px',
										width: '100%',
										height: '100%',
										fontSize: '.85em',
										background:
											'linear-gradient(165deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)',
										color: '#ffffff',
										borderRadius: '1em'
									}}
								>
									{this.state.body !== '' ? this.state.isComplete ? (
										'Save (complete)'
									) : (
										'Save (not complete)'
									) : (
										`Cancel`
									)}
								</Button>
							</Grid>
						</Grid>
					</form>
				</Paper>
			) : // </ClickAwayListener>
			this.state.activeCardId !== '' && this.state.body !== '' ? ( // is there already a note to show?
				myNotes.map((note) => {
					if (note.linkedCardId === this.state.activeCardId) {
						return (
							<Fragment key={note.noteId}>
								<Grid item xs className={classes.bigNote}>
									<NotecardActive
										key={note.noteId}
										note={note}
										linkedCardId={note.linkedCardId}
										body={note.body}
										isPublic={note.isPublic}
										isComplete={note.isComplete}
									/>
								</Grid>
								<Button
									variant="contained"
									className={classes.noteButton}
									style={{
										width: '100%',
										// height: '100%',
										fontSize: '.85em',
										background: 'linear-gradient(105deg,rgba(15, 135, 151, 1) 0%, #01508d 100%',
										color: '#ffffff',
										borderRadius: '1em'
									}}
									onClick={this.handleOpen}
								>
									edit
								</Button>
							</Fragment>
						);
					}
					else return null;
				})
			) : null;
		// is there no note and should have a button to make a new note?

		// <Button
		// 	variant="contained"
		// 	style={{
		// 		width: '100%',
		// 		// height: '100%',
		// 		margin: '20px auto 0 auto',
		// 		fontSize: '.85em',
		// 		backgroundColor: '#0f8797',
		// 		color: '#ffffff',
		// 		borderRadius: '1em'
		// 	}}
		// 	onClick={this.handleOpen}
		// >
		// 	start writing
		// </Button>

		let inspirationMarkup =
			this.state.activeCardId === null || this.state.activeCardId === '' ? null : this.state.activeCardId >= 26 && // </Button> // 	give me a random card // > // 	onClick={this.randomCard} // 	style={{ fontSize: '.85em', backgroundColor: '#ffffff', width: '100%', borderRadius: '1em' }} // 	variant="contained" // <Button
			this.state.activeCardId <= 30 ? null : !this.state.inspiration ? (
				<Button
					variant="contained"
					style={{
						fontSize: '.85em',
						background: 'linear-gradient(165deg,rgba(255,255,255, 1) 0%, rgba(225,225,225, 1) 100%)',
						width: '100%',
						borderRadius: '1em',
						color: '#aaa'
					}}
					onClick={this.handleInspire}
				>
					see what others have said
				</Button>
			) : this.props.data.notes.some((obj) => obj.linkedCardId === this.state.activeCardId) ? (
				notes.map((note) => {
					if (note.userHandle !== handle && note.linkedCardId === this.state.activeCardId) {
						return (
							<Grid item xs={12} className={classes.individualNote} key={note.noteId}>
								<NotecardStarterPack
									note={note}
									linkedCardId={note.linkedCardId}
									changeCard={this.changeCard}
								/>
							</Grid>
						);
					}
					else return null;
				})
			) : (
				<Grid item xs={12} style={{ width: '100%' }}>
					<Typography
						variant="body2"
						align="left"
						style={{
							// marginBottom: 10,
							// minHeight: '100px',
							padding: '0',
							width: '100%',
							color: '#aaaaaa',
							margin: 'auto'
						}}
					>
						no public responses to this question yet
					</Typography>
				</Grid>
			);

		// let titleMarkup =
		// 	this.state.activeCardId >= 1 && this.state.activeCardId <= 25 ? (
		// 		<Fragment>
		// 			<span style={{ fontWeight: 900 }}>My {cardsEOL[this.state.activeCardId - 1].chapter}</span> /{' '}
		// 			{cardsEOL[this.state.activeCardId - 1].tag}
		// 		</Fragment>
		// 	) : this.state.activeCardId >= 26 && this.state.activeCardId <= 30 ? (
		// 		<Fragment>
		// 			<span style={{ fontWeight: 900 }}>My {chaptersEOL[this.state.activeCardId - 26].name}</span> /{' '}
		// 			Instructions
		// 		</Fragment>
		// 	) : null;

		let cardSelectionDrawerMarkup = (
			<nav className={classes.drawer}>
				<Hidden xsDown implementation="css">
					<Drawer
						transitionDuration={0}
						variant="persistent"
						anchor="left"
						open={this.state.cardDrawerOpen}
						// onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaper
						}}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}
					>
						<Grid
							container
							item
							xs={12}
							spacing={1}
							justify="center"
							alignItems="flex-start"
							style={{ padding: '0' }}
						>
							<Grid container spacing={1} justify="center" style={{ margin: '0 auto 0 auto' }}>
								<Grid
									container
									item
									xs={12}
									spacing={1}
									justify="center"
									alignItems="stretch"
									style={{
										margin: '7px 10px auto 20px',
										width: '100%'
									}}
								>
									<Grid
										item
										xs={12}
										style={{
											margin: '0 0 30px 0'
										}}
									>
										<Typography
											className={classes.instructions}
											style={{
												color: 'rgba(0,0,0,.5)',
												textAlign: 'left',
												width: 280
											}}
										>
											<span style={{ fontWeight: '600', color: 'rgba(0,0,0,1)' }}>
												Instructions:{' '}
											</span>Answer the five questions from each chapter (and mark them complete)
											in order to unlock the "instructions" card. There you will more directly
											outline your end of life wishes
										</Typography>
									</Grid>
									<Divider
										style={{
											color: 'rgba(0,0,0,.5)',
											width: '120%',
											position: 'absolute',
											top: 86
										}}
									/>
									<ChevronLeftIcon
										style={{ position: 'absolute', top: 10, right: 10, color: '#fff' }}
										onClick={() => this.setState({ cardDrawerOpen: false })}
									/>
									{this.props.data.loading ? (
										<CircularProgress
											size={20}
											style={{ marginTop: 60, color: 'rgba(0,0,0,0.3)' }}
										/>
									) : (
										cardsMarkup
									)}
								</Grid>
							</Grid>
						</Grid>
					</Drawer>
					<Drawer
						transitionDuration={0}
						variant="persistent"
						anchor="left"
						open={!this.state.cardDrawerOpen}
						onClick={() => this.setState({ cardDrawerOpen: true })}
						// onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaperClosed
						}}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}
					>
						<Grid
							container
							item
							xs={12}
							spacing={1}
							justify="center"
							alignItems="flex-start"
							style={{ padding: '0' }}
						>
							<Grid container spacing={1} justify="center" style={{ margin: '0 auto 0 auto' }}>
								<Grid
									container
									item
									xs={12}
									spacing={1}
									justify="center"
									alignItems="stretch"
									style={{
										margin: '10px 0 auto 20px',
										width: '100%'
									}}
								>
									<Grid
										item
										xs={12}
										style={{
											margin: '0 0 10px 0'
										}}
									/>
									<ChevronRightIcon
										color="primary"
										style={{ position: 'absolute', top: 10, right: 15 }}
									/>
									<Divider
										style={{
											color: 'rgba(0,0,0,.5)',
											width: '120%',
											position: 'absolute',
											top: 86
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Drawer>
				</Hidden>
				<Hidden smUp implementation="css">
					<AppBar
						position="fixed"
						color="primary"
						className={classes.drawerResponsive}
						onClick={this.handleDrawerToggle}
					>
						<BottomNavigation
							className={classes.root}
							style={{
								background:
									'linear-gradient(165deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)'
							}}
						>
							{!this.state.mobileOpen ? (
								<BottomNavigationAction label="CHANGE CARDS" showLabel style={{ color: '#fff' }} />
							) : (
								<BottomNavigationAction label="BACK" showLabel style={{ color: '#fff' }} />
							)}
						</BottomNavigation>
					</AppBar>
					<Drawer
						// container={container}
						transitionDuration={0}
						variant="temporary"
						anchor="top"
						open={this.state.mobileOpen}
						onClose={this.handleDrawerToggle}
						classes={{
							paper: classes.drawerPaperMobile
						}}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}
					>
						<Grid
							container
							item
							xs={12}
							spacing={0}
							justify="center"
							alignItems="flex-start"
							style={{
								padding: '0'
								// maxWidth: 360
							}}
						>
							<Grid
								container
								spacing={0}
								justify="center"
								style={{
									margin: '0'
								}}
							>
								{/* <Grid
									container
									item
									xs={12}
									spacing={1}
									justify="center"
									alignItems="stretch"
									style={
										this.state.activeChapter === null || this.state.activeChapter === '' ? (
											{ transform: 'translateY(200px)' }
										) : null
									}
								>
									{chapterCardsMarkup}
								</Grid> */}

								<Grid
									container
									item
									xs={12}
									spacing={0}
									justify="center"
									alignItems="stretch"
									style={{
										margin: '20px 20px auto 20px',
										height: '100%'
									}}
								>
									<Grid
										item
										xs={12}
										style={{
											margin: '0'
										}}
									>
										<Typography
											className={classes.instructions}
											style={{
												color: 'rgba(255,255,255,.5)',
												textAlign: 'left'
											}}
										>
											<span style={{ fontWeight: '600', color: 'rgba(255,255,255,1)' }}>
												Instructions:{' '}
											</span>Answer the five questions from each chapter (and mark them complete)
											in order to unlock the "directives" card.
										</Typography>
									</Grid>
									{cardsMarkup}
								</Grid>
							</Grid>
						</Grid>
					</Drawer>
				</Hidden>
			</nav>
		);

		return (
			<div className={this.state.cardDrawerOpen ? classes.container : classes.containerDrawerMinimized}>
				{cardSelectionDrawerMarkup}
				<Grid
					container
					spacing={1}
					className={classes.mainContent}
					style={{ flexGrow: '1', maxWidth: '780px', margin: 'auto' }}
				>
					{/* {this.state.activeCardId >= 26 && this.state.activeCardId <= 30 ? null : (
						<Grid
							item
							xs
							style={{
								margin: '20px auto 0 20px',
								fontSize: '1em',
								fontWeight: '400',
								letterSpacing: '-0.03em'
							}}
						>
							{titleMarkup}
						</Grid>
					)} */}
					<Grid container item xs={12} spacing={10} style={{ margin: '0px 0 0 0' }}>
						{/* <div onClick={this.handleOpen}> */}

						{this.state.activeCardId >= 26 && this.state.activeCardId <= 30 ? (
							<Instructions
								activeCardId={this.state.activeCardId}
								cardDrawerOpen={this.state.cardDrawerOpen}
							/>
						) : this.state.activeCardId === '' ? (
							<Grid item xs={12} style={{ padding: '20px 20px 0 20px' }}>
								{promptMarkup}
							</Grid>
						) : (
							<Fragment>
								<Grid item xs={12} md={5} style={{ padding: '20px 20px 0 20px' }}>
									{promptMarkup}
									<Divider orientation="horizontal" className={classes.divider} />
									<Hidden smDown implementation="css">
										{inspirationMarkup}
									</Hidden>
								</Grid>
								<Grid item xs={12} md={7} style={{ padding: '20px 20px 0 20px' }}>
									{bigNoteMarkup}
								</Grid>
							</Fragment>
						)}
						{/* </div> */}
					</Grid>
				</Grid>
			</div>
		);
	}
}

StarterPack.propTypes = {
	classes: PropTypes.object.isRequired,
	getUserData: PropTypes.func.isRequired,
	getNotes: PropTypes.func.isRequired,
	getMyNotes: PropTypes.func.isRequired,
	selectTransition: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({ data: state.data, user: state.user, UI: state.UI });

const mapActionsToProps = { getUserData, getNotes, getMyNotes, postNote, updateNote, clearErrors, selectTransition };

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(StarterPack));
