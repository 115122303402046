import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DeleteNote from './DeleteNote';
import NoteDialog from './NoteDialog';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import PublicIcon from '@material-ui/icons/Public';
import LockIcon from '@material-ui/icons/Lock';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const styles = (theme) => ({
	...theme.spreadThis,
	card: {
		position: 'relative',
		display: 'flex',
		marginBottom: 10,
		minHeight: '100px'
	},
	cover: {
		width: 50,
		height: 50,
		objectFit: 'cover'
	},
	content: {
		padding: '10px 15px 0px 15px',
		marginBottom: -20,
		objectFit: 'cover',
		width: '90%'
	},
	cardHandle: {
		fontSize: '1em'
	},
	cardDate: {
		fontSize: '.7em',
		color: '#aaa',
		marginBottom: 10
	},
	noteBody: {
		margin: '2px auto 10px auto',
		color: 'black',
		'& :hover': {
			color: 'black',
			cursor: 'pointer'
		}
	},
	complete: {
		fontSize: '0.8rem',
		margin: '0 2px 0 0'
	},
	public: {
		fontSize: '0.8rem',
		margin: '0 8px 0 0'
	},
	question: {
		fontSize: '1em',
		margin: '5px 0 5px 0'
	}
});

class NotecardPersonal extends Component {
	static defaultProps = {
		cardsEOL: [
			{
				id: 1,
				chapter: 'care',
				tag: 'What environment would you want for your care?'
			},
			{
				id: 2,
				chapter: 'care',
				tag: 'Who would be your legal guardian?'
			},
			{
				id: 3,
				chapter: 'care',
				tag: 'Do you want to be resuscitated?'
			},
			{
				id: 4,
				chapter: 'care',
				tag: 'What to do if terminally ill or in a coma?'
			},
			{
				id: 5,
				chapter: 'care',
				tag: 'Who will be your "agent"?'
			},
			{
				id: 6,
				chapter: 'body',
				tag: 'What should become of your body?'
			},
			{
				id: 7,
				chapter: 'body',
				tag: 'Where should you be laid to rest?'
			},
			{
				id: 8,
				chapter: 'body',
				tag: 'What to inscribe at your memorial site?'
			},
			{
				id: 9,
				chapter: 'body',
				tag: 'Should your organs be donated?'
			},
			{
				id: 10,
				chapter: 'body',
				tag: 'Who should be responsible for handling your remains?'
			},
			{
				id: 11,
				chapter: 'ceremony',
				tag: 'What should happen at your funeral?'
			},
			{
				id: 12,
				chapter: 'ceremony',
				tag: 'Where should your funeral take place?'
			},
			{
				id: 13,
				chapter: 'ceremony',
				tag: 'What details do you picture for your funeral?'
			},
			{
				id: 14,
				chapter: 'ceremony',
				tag: 'What should be shared at your funeral?'
			},
			{
				id: 15,
				chapter: 'ceremony',
				tag: 'Who should organize your funeral?'
			},
			{
				id: 16,
				chapter: 'treasures',
				tag: 'What valuables do you want to pass down?'
			},
			{
				id: 17,
				chapter: 'treasures',
				tag: 'Who will take care of your kids and pets?'
			},
			{
				id: 18,
				chapter: 'treasures',
				tag: 'How will you distribute your estate?'
			},
			{
				id: 19,
				chapter: 'treasures',
				tag: 'What is your plan for your biggest assets?'
			},
			{
				id: 20,
				chapter: 'treasures',
				tag: 'Who will be your "executor"?'
			},
			{
				id: 21,
				chapter: 'legacy',
				tag: 'How will your life story be preserved?'
			},
			{
				id: 22,
				chapter: 'legacy',
				tag: 'What causes can people support to honor you?'
			},
			{
				id: 23,
				chapter: 'legacy',
				tag: 'Who do you still want to settle up with?'
			},
			{
				id: 24,
				chapter: 'legacy',
				tag: 'Who would you like to leave a letter or video to?'
			},
			{
				id: 25,
				chapter: 'legacy',
				tag: 'Who do you hope will carry on your legacy?'
			}
		]
	};
	render () {
		dayjs.extend(relativeTime);
		const {
			classes,
			// linkedCardId,
			// chapter,
			note: { body, createdAt, userHandle, noteId, isPublic, isComplete }
		} = this.props;
		// const linkedChapter =
		// 	linkedCardId < 5 && linkedCardId > 0
		// 		? 'care'
		// 		: linkedCardId <= 10 && linkedCardId > 0
		// 			? 'body'
		// 			: linkedCardId <= 15 && linkedCardId > 0
		// 				? 'ceremony'
		// 				: linkedCardId <= 20 && linkedCardId > 0
		// 					? 'treasures'
		// 					: linkedCardId <= 25 && linkedCardId > 0 ? 'legacy' : 'general';

		const isCompleteMarkup = isComplete ? (
			<CheckCircleIcon color="primary" className={classes.complete} />
		) : (
			<CheckCircleOutlineIcon style={{ color: '#aaaaaa' }} className={classes.complete} />
		);
		const isPublicMarkup = isPublic ? (
			<PublicIcon color="primary" className={classes.public} />
		) : (
			<LockIcon color="primary" className={classes.public} />
		);

		return (
			<Card className={classes.card}>
				<CardContent className={classes.content}>
					<Link
						to={{
							pathname: `/eol/mywishes`,
							state: {
								linkedCardId: this.props.note.linkedCardId
							}
						}}
					>
						<Typography variant="body1" color="primary" className={classes.question}>
							{this.props.cardsEOL[this.props.note.linkedCardId - 1].tag}
						</Typography>
						<Typography variant="body2" className={classes.noteBody}>
							{this.props.body === '' || this.props.body === undefined ? body : this.props.body}
						</Typography>
						<Typography variant="h5" color="primary" className={classes.cardDate}>
							{isCompleteMarkup}
							{isPublicMarkup}
							{dayjs(createdAt).fromNow()}
						</Typography>
					</Link>
					<DeleteNote noteId={noteId} />
					<NoteDialog noteId={noteId} userHandle={userHandle} openDialog={this.props.openDialog} />
				</CardContent>
			</Card>
		);
	}
}

NotecardPersonal.propTypes = {
	user: PropTypes.object.isRequired,
	note: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	openDialog: PropTypes.bool
};

const mapStateToProps = (state) => ({
	user: state.user,
	UI: state.UI
});

export default connect(mapStateToProps, null)(withStyles(styles)(NotecardPersonal));
