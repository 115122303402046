import { SET_ERRORS, CLEAR_ERRORS, LOADING_UI, STOP_LOADING_UI, SET_TRANSITION } from '../types';

const initialState = {
	loading: false,
	errors: null,
	transition: null
};

export default function (state = initialState, action){
	switch (action.type) {
		case SET_ERRORS:
			return {
				...state,
				loading: false,
				errors: action.payload
			};
		case CLEAR_ERRORS:
			return {
				...state,
				loading: false,
				errors: null
			};
		case LOADING_UI:
			return {
				...state,
				loading: true
			};
		case STOP_LOADING_UI:
			return {
				...state,
				loading: false
			};
		case SET_TRANSITION:
			return {
				...state,
				transition: action.payload
			};
		default:
			return state;
	}
}
