import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AuthRoute from './util/AuthRoute';
import UnauthRoute from './util/UnauthRoute';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
// import DeviceOrientation, { Orientation } from 'react-screen-orientation';
// Pages
import Login from './pages/login';
import Signup from './pages/signup';
import Profile from './components/profile/Profile';
import Public from './pages/public';
// import Intro from './pages/intro';
// import Help from './pages/help';
import User from './pages/user';
import StarterPack from './pages/StarterPack';
// import MyNotes from './pages/mynotes';
// import SplashEOL from './pages/EOL/splashEOL';
// import SplashCOVID from './pages/COVID/splashCOVID';
// import Lifemap from './pages/Lifemap';
// import PublicCOVID from './pages/COVID/publicCOVID';
// import IntroCOVID from './pages/COVID/introCOVID';
// import HelpCOVID from './pages/COVID/helpCOVID';
// import UserCOVID from './pages/COVID/userCOVID';
// import StarterPackCOVID from './pages/COVID/StarterPackCOVID';
// import MyNotesCOVID from './pages/COVID/mynotesCOVID';
// Components
// import Navbar from './components/layout/Navbar';
import NavbarLeft from './components/layout/NavbarLeft';
// Style
import themeFile from './util/theme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
// import Container from '@material-ui/core/Container';
import './App.css';
// Redux
import { Provider } from 'react-redux';
import store from './redux/store';
import { SET_AUTHENTICATED } from './redux/types';
import { logoutUser, getUserData } from './redux/actions/userActions';

const theme = createMuiTheme(themeFile);

axios.defaults.baseURL = 'https://us-central1-starterpack-7084f.cloudfunctions.net/api';

const token = localStorage.FBIdToken;
if (token) {
	const decodedToken = jwtDecode(token);
	if (decodedToken.exp * 1000 < Date.now()) {
		store.dispatch(logoutUser());
		window.location.href = '/login';
	}
	else {
		store.dispatch({ type: SET_AUTHENTICATED });
		axios.defaults.headers.common['Authorization'] = token;
		store.dispatch(getUserData());
	}
}

function App (){
	// function toggleFullScreen (){
	// 	var doc = window.document;
	// 	var docEl = doc.documentElement;

	// 	var requestFullScreen =
	// 		docEl.requestFullscreen ||
	// 		docEl.mozRequestFullScreen ||
	// 		docEl.webkitRequestFullScreen ||
	// 		docEl.msRequestFullscreen;
	// 	var cancelFullScreen =
	// 		doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

	// 	if (
	// 		!doc.fullscreenElement &&
	// 		!doc.mozFullScreenElement &&
	// 		!doc.webkitFullscreenElement &&
	// 		!doc.msFullscreenElement
	// 	) {
	// 		requestFullScreen.call(docEl);
	// 	}
	// 	else {
	// 		cancelFullScreen.call(doc);
	// 	}
	// }
	return (
		<MuiThemeProvider theme={theme}>
			<Provider store={store}>
				{/* <DeviceOrientation lockOrientation={'portrait'}>
					<Orientation orientation="portrait"> */}
				<div className="App">
					<NavbarLeft />
					{/* <Container maxWidth="xl" className="container"> */}
					<Switch>
						<AuthRoute exact path="/" component={Signup} />
						{/* <AuthRoute exact path="/eol" component={SplashEOL} /> */}
						{/* <AuthRoute exact path="/covid" component={SplashCOVID} /> */}
						<AuthRoute exact path="/login" component={Login} />
						<AuthRoute exact path="/signup" component={Signup} />
						<UnauthRoute exact path="/:transition/mycircle" component={Profile} />
						<Route exact path="/:transition/explore" component={Public} />
						<Route exact path="/:transition/users/:handle" component={User} />
						<Route exact path="/:transition/users/:handle/note/:noteId" component={User} />
						{/* <UnauthRoute exact path="/:transition/intro" component={Intro} /> */}
						{/* <UnauthRoute exact path="/:transition/help" component={Help} /> */}
						{/* <UnauthRoute exact path="/:transition/mynotes" component={MyNotes} /> */}
						<UnauthRoute exact path="/:transition/mywishes" component={StarterPack} />
						{/* <Route exact path="/lifemap" component={Lifemap} /> */}
						{/* <Route exact path="/covid/public" component={PublicCOVID} />
							<Route exact path="/covid/users/:handle" component={UserCOVID} />
							<Route exact path="/covid/users/:handle/note/:noteId" component={UserCOVID} />
							<UnauthRoute exact path="/covid/intro" component={IntroCOVID} />
							<UnauthRoute exact path="/covid/help" component={HelpCOVID} />
							<UnauthRoute exact path="/covid/mynotes" component={MyNotesCOVID} />
							<UnauthRoute exact path="/covid/starterpack" component={StarterPackCOVID} /> */}
					</Switch>
					{/* </Container> */}
					{/* <Navbar /> */}
				</div>
				{/* </Orientation>
				</DeviceOrientation> */}
			</Provider>
		</MuiThemeProvider>
	);
}

export default App;
