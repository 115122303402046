import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getNotes, selectTransition } from '../redux/actions/dataActions';
import { getAllMyLikes } from '../redux/actions/userActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
// import Card from '@material-ui/core/Card';
// import CardMedia from '@material-ui/core/CardMedia';
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import NoteSkeleton from '../util/NoteSkeleton.js';
import Notecard from '../components/note/Notecard.js';
import NotecardLike from '../components/note/NotecardLike.js';
import ResourceCard from '../components/note/ResourceCard.js';
import careIcon from '../images/eol/careIcon.png';
import bodyIcon from '../images/eol/bodyIcon.png';
import ceremonyIcon from '../images/eol/ceremonyIcon.png';
import treasuresIcon from '../images/eol/treasuresIcon.png';
import legacyIcon from '../images/eol/legacyIcon.png';
// import preparationCover from '../images/covid/1coverBig.png';
// import separationCover from '../images/covid/2coverBig.png';
// import celebrationCover from '../images/covid/3coverBig.png';
// import creationCover from '../images/covid/4coverBig.png';
// import stabilizationCover from '../images/covid/5coverBig.png';
import willful from '../images/resources/willful.png';
import trustandwill from '../images/resources/trustandwill.png';
import cake from '../images/resources/cake.svg';
import eternerva from '../images/resources/eternerva.png';
import reimagine from '../images/resources/reimagine.jpg';
import polst from '../images/resources/polst.jpg';
import inelda from '../images/resources/inelda.png';
import tcp from '../images/resources/tcp.jpg';
import deathoverdinner from '../images/resources/deathoverdinner.jpg';
import gatheringus from '../images/resources/gatheringus.jpg';
// import willing from '../images/resources/willing.svg';
// import Profile from '../components/profile/Profile.js';
// import '../App.css';

const styles = (theme) => ({
	...theme.spreadThis,
	pageHeader: {
		color: 'primary',
		textAlign: 'center',
		lineHeight: '1em',
		width: '100%',
		margin: '60px 0 80px 0',
		padding: '0 20px 0 20px',
		fontSize: '3rem',
		// fontWeight: '100',
		letterSpacing: '-.05em',
		'&:hover': {
			cursor: 'default'
		}
	},
	header: {
		fontSize: '3.4rem',
		// color: 'white',
		textAlign: 'center',
		letterSpacing: '-0.07em',
		lineHeight: '1.1em',
		maxWidth: '100%',
		margin: '80px 0px 80px 0'
	},
	card: {
		position: 'relative',
		display: 'flex',
		maxHeight: '75px',
		width: '55px',
		marginBottom: 5,
		overflow: 'hidden',
		perspective: '1px',
		'&:hover': {
			cursor: 'pointer'
		}
	},
	container: {
		[theme.breakpoints.up('xs')]: {
			width: `calc(100% - ${drawerWidth}px -80px)`,
			// marginRight: drawerWidth,
			marginLeft: 440,
			// maxWidth: 1024,
			minWidth: 420
		},
		// [theme.breakpoints.down('sm')]: {
		// 	width: `calc(100% - 80px)`,
		// 	marginLeft: 80
		// },
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			marginLeft: 0,
			marginTop: 60,
			minWidth: 0
		}
	},
	containerDrawerMinimized: {
		[theme.breakpoints.up('xs')]: {
			width: `calc(100% - ${drawerWidth}px -80px)`,
			// marginRight: drawerWidth,
			marginLeft: 135
			// maxWidth: 1024
		},
		// [theme.breakpoints.down('sm')]: {
		// 	width: `calc(100% - 80px)`,
		// 	marginLeft: 80
		// },
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			marginLeft: 0,
			marginTop: 60
		}
	},
	drawer: {
		[theme.breakpoints.up('md')]: {
			width: drawerWidth,
			flexShrink: 0
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
			flexShrink: 0
		},
		overflowX: 'hidden'
	},
	drawerPaperClosed: {
		width: 55,
		marginLeft: 80,
		// display: 'flex',
		background: 'linear-gradient(105deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)',
		// background: 'linear-gradient(105deg, rgba(255, 255, 255, 1) 0%, rgba(235, 235, 235, 1) 100%)',
		overflowX: 'hidden'
	},
	drawerPaper: {
		width: 360,
		marginLeft: 80,
		// display: 'flex',
		background: 'linear-gradient(105deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)',

		// background: 'linear-gradient(105deg, rgba(255, 255, 255, 1) 0%, rgba(235, 235, 235, 1) 100%)',
		overflowX: 'hidden'
	},
	drawerPaperMobile: {
		[theme.breakpoints.up('xs')]: {
			width: `calc(100% - 80px)`,
			marginLeft: 80
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			marginTop: 40,
			marginLeft: 0
		},
		height: '100%',
		// display: 'flex',
		background: 'linear-gradient(105deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)',
		overflowX: 'hidden'
	},
	drawerResponsive: {
		[theme.breakpoints.up('xs')]: {
			width: `calc(100% - 80px)`,
			height: 40,
			marginLeft: 440
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			height: 40,
			marginLeft: 0,
			top: 60
		},
		'& :hover': {
			cursor: 'pointer'
		}
	},
	explainer: {
		[theme.breakpoints.up('xs')]: {
			fontSize: '.9em',
			margin: '0 0px 3px 0px',
			lineHeight: 1.2
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '.7em',
			margin: '0 0px 5px 0px',
			lineHeight: 1.2
		}
	},
	title: {
		fontSize: '1.8rem',
		// color: 'white',
		textAlign: 'left',
		letterSpacing: '-0.05em',
		lineHeight: '1.1em',
		maxWidth: '100%',
		margin: '20px 20px 20px 0'
	},
	cover: {
		height: 32,
		width: 32,
		margin: 'auto'
	},
	filterBox: {
		margin: '10px 10px 0px 10px'
	}
});

const drawerWidth = 360;

class home extends Component {
	static defaultProps = {
		resources: [
			{
				name: 'Trust&Will',
				description: 'Online wills for U.S. Citizens',
				link: 'https://trustandwill.com/',
				chapter: 'treasures',
				src: trustandwill
			},
			{
				name: 'Willful',
				description: 'Fast and easy online wills in Canada',
				link: 'http://willful.co/',
				chapter: 'treasures',
				src: willful
			},
			{
				name: 'Cake',
				description: 'Healthcare decisions and education',
				link: 'https://www.joincake.com/',
				chapter: 'care',
				src: cake
			},
			{
				name: 'POLST',
				description: 'Medical directives for doctors',
				link: 'https://polst.org/',
				chapter: 'care',
				src: polst
			},
			{
				name: 'The Conversation Project',
				description: 'Resources for difficult conversations',
				link: 'http://theconversationproject.org/',
				chapter: 'legacy',
				src: tcp
			},
			{
				name: 'Reimagine',
				description: 'Events for the end-of-life industry',
				link: 'https://letsreimagine.org/',
				chapter: 'body',
				src: reimagine
			},
			{
				name: 'Death over Dinner',
				description: 'Conversation tools about mortality',
				link: 'http://deathoverdinner.org/',
				chapter: 'legacy',
				src: deathoverdinner
			},
			{
				name: 'Eternerva',
				description: 'Turning ashes into diamonds',
				link: 'https://eterneva.com/',
				chapter: 'body',
				src: eternerva
			},
			{
				name: 'Gathering Us',
				description: 'Alternative memorials and funerals',
				link: 'https://www.gatheringus.com/',
				chapter: 'ceremony',
				src: gatheringus
			},
			{
				name: 'INELDA',
				description: 'The End-of-life Doula Association',
				link: 'https://www.inelda.org/',
				chapter: 'ceremony',
				src: inelda
			}
		]
	};

	constructor (props) {
		super(props);
		this.state = {
			chapterFilter: null,
			drawerOpen: true,
			mobileOpen: false,
			chapters: {
				chapter1: { name: 'care', img: careIcon, low: 0, high: 5 },
				chapter2: { name: 'body', img: bodyIcon, low: 5, high: 10 },
				chapter3: { name: 'ceremony', img: ceremonyIcon, low: 10, high: 15 },
				chapter4: { name: 'treasures', img: treasuresIcon, low: 15, high: 20 },
				chapter5: { name: 'legacy', img: legacyIcon, low: 20, high: 25 }
			}
		};
		this.props.getAllMyLikes();
		this.handleFilter = this.handleFilter.bind(this);
	}

	componentDidMount () {
		this.props.getNotes();
		// this.props.selectTransition(this.props.match.params.transition);

		// if (this.props.match.params.transition === 'eol') {
		// 	this.setState({
		// 		chapters: {
		// 			chapter1: { name: 'care', img: careIcon, low: 0, high: 5 },
		// 			chapter2: { name: 'body', img: bodyIcon, low: 5, high: 10 },
		// 			chapter3: { name: 'ceremony', img: ceremonyIcon, low: 10, high: 15 },
		// 			chapter4: { name: 'treasures', img: treasuresIcon, low: 15, high: 20 },
		// 			chapter5: { name: 'legacy', img: legacyIcon, low: 20, high: 25 }
		// 		}
		// 	});
		// }
		// if (this.props.match.params.transition === 'covid') {
		// 	this.setState({
		// 		chapters: {
		// 			chapter1: { name: 'preparation', img: preparationCover, low: 25, high: 30 },
		// 			chapter2: { name: 'separation', img: separationCover, low: 30, high: 35 },
		// 			chapter3: { name: 'celebration', img: celebrationCover, low: 35, high: 40 },
		// 			chapter4: { name: 'creation', img: creationCover, low: 40, high: 45 },
		// 			chapter5: { name: 'stabilization', img: stabilizationCover, low: 45, high: 50 }
		// 		}
		// 	});
		// }
	}

	handleFilter (updatedChapter) {
		if (this.state.chapterFilter !== updatedChapter) {
			this.setState({ chapterFilter: updatedChapter, mobileOpen: false });
		}
		else this.setState({ chapterFilter: '', mobileOpen: false });
	}

	handleDrawerToggle = () => {
		this.setState({ mobileOpen: !this.state.mobileOpen });
	};

	render () {
		const { notes, loading } = this.props.data;
		const { classes } = this.props;
		const { chapter1, chapter2, chapter3, chapter4, chapter5 } = this.state.chapters;

		let recentPublicNotesMarkup = loading ? (
			<NoteSkeleton />
		) : !this.state.chapterFilter ? (
			notes.map((note) => {
				if (note.isPublic && note.linkedCardId > chapter1.low && note.linkedCardId <= chapter5.high)
					return <Notecard key={note.noteId} note={note} />;
				else return null;
				// if (note) return <Notecard key={note.noteId} note={note} />;
			})
		) : this.state.chapterFilter === chapter1.name ? (
			notes.map((note) => {
				if (note.isPublic && note.linkedCardId > chapter1.low && note.linkedCardId <= chapter1.high) {
					return <Notecard key={note.noteId} note={note} />;
				}
				else return null;
			})
		) : this.state.chapterFilter === chapter2.name ? (
			notes.map((note) => {
				if (note.isPublic && note.linkedCardId > chapter2.low && note.linkedCardId <= chapter2.high) {
					return <Notecard key={note.noteId} note={note} />;
				}
				else return null;
			})
		) : this.state.chapterFilter === chapter3.name ? (
			notes.map((note) => {
				if (note.isPublic && note.linkedCardId > chapter3.low && note.linkedCardId <= chapter3.high) {
					return <Notecard key={note.noteId} note={note} />;
				}
				else return null;
			})
		) : this.state.chapterFilter === chapter4.name ? (
			notes.map((note) => {
				if (note.isPublic && note.linkedCardId > chapter4.low && note.linkedCardId <= chapter4.high) {
					return <Notecard key={note.noteId} note={note} />;
				}
				else return null;
			})
		) : this.state.chapterFilter === chapter5.name ? (
			notes.map((note) => {
				if (note.isPublic && note.linkedCardId > chapter5.low && note.linkedCardId <= chapter5.high) {
					return <Notecard key={note.noteId} note={note} />;
				}
				else return null;
			})
		) : null;

		let likesMarkup =
			loading || !this.props.user.authenticated || this.props.user.likes === []
				? null // <NoteSkeleton />
				: this.props.user.likes.map((like) => {
						return notes.map((note) => {
							if (!this.state.chapterFilter || this.state.chapterFilter === '') {
								if (note.noteId === like.noteId) {
									return <NotecardLike key={note.noteId} note={note} />;
								}
								else return null;
							}
							else if (this.state.chapterFilter === chapter1.name) {
								if (
									note.noteId === like.noteId &&
									note.linkedCardId > chapter1.low &&
									note.linkedCardId <= chapter1.high
								) {
									return <NotecardLike key={note.noteId} note={note} />;
								}
								else return null;
							}
							else if (this.state.chapterFilter === chapter2.name) {
								if (
									note.noteId === like.noteId &&
									note.linkedCardId > chapter2.low &&
									note.linkedCardId <= chapter2.high
								) {
									return <NotecardLike key={note.noteId} note={note} />;
								}
								else return null;
							}
							else if (this.state.chapterFilter === chapter3.name) {
								if (
									note.noteId === like.noteId &&
									note.linkedCardId > chapter3.low &&
									note.linkedCardId <= chapter3.high
								) {
									return <NotecardLike key={note.noteId} note={note} />;
								}
								else return null;
							}
							else if (this.state.chapterFilter === chapter4.name) {
								if (
									note.noteId === like.noteId &&
									note.linkedCardId > chapter4.low &&
									note.linkedCardId <= chapter4.high
								) {
									return <NotecardLike key={note.noteId} note={note} />;
								}
								else return null;
							}
							else if (this.state.chapterFilter === chapter5.name) {
								if (
									note.noteId === like.noteId &&
									note.linkedCardId > chapter5.low &&
									note.linkedCardId <= chapter5.high
								) {
									return <NotecardLike key={note.noteId} note={note} />;
								}
								else return null;
							}
							else return null; // if (note) return <Notecard key={note.noteId} note={note} />;
						});
					});

		let resourceCardsMarkup = loading ? (
			<NoteSkeleton />
		) : !this.state.chapterFilter ? (
			this.props.resources.map((r, i) => {
				if (r)
					return (
						<ResourceCard
							key={i}
							name={r.name}
							description={r.description}
							link={r.link}
							chapter={r.chapter}
							src={r.src}
						/>
					);
				else return null;
				// if (note) return <Notecard key={note.noteId} note={note} />;
			})
		) : (
			this.props.resources.map((r, i) => {
				if (r.chapter === this.state.chapterFilter)
					return (
						<ResourceCard
							key={i}
							name={r.name}
							description={r.description}
							link={r.link}
							chapter={r.chapter}
							src={r.src}
						/>
					);
				else return null;
				// if (note) return <Notecard key={note.noteId} note={note} />;
			})
		);

		let chapterCardsMarkup = (
			<Fragment>
				<Grid item xs={1} onClick={() => this.handleFilter(chapter1.name)} className={classes.filterBox}>
					<img
						src={chapter1.img}
						alt={`${chapter1.name} cardback`}
						className={classes.cover}
						style={
							this.state.chapterFilter !== chapter1.name &&
							this.state.chapterFilter !== '' &&
							this.state.chapterFilter !== null ? (
								{ opacity: 0.1, transition: 'opacity 100ms ease-in' }
							) : null
						}
					/>
				</Grid>
				<Grid item xs={1} onClick={() => this.handleFilter(chapter2.name)} className={classes.filterBox}>
					<img
						src={chapter2.img}
						alt={`${chapter2.name} cardback`}
						className={classes.cover}
						style={
							this.state.chapterFilter !== chapter2.name &&
							this.state.chapterFilter !== '' &&
							this.state.chapterFilter !== null ? (
								{ opacity: 0.1, transition: 'opacity 100ms ease-in' }
							) : null
						}
					/>
				</Grid>
				<Grid item xs={1} onClick={() => this.handleFilter(chapter3.name)} className={classes.filterBox}>
					<img
						src={chapter3.img}
						alt={`${chapter3.name} cardback`}
						className={classes.cover}
						style={
							this.state.chapterFilter !== chapter3.name &&
							this.state.chapterFilter !== '' &&
							this.state.chapterFilter !== null ? (
								{ opacity: 0.1, transition: 'opacity 100ms ease-in' }
							) : null
						}
					/>
				</Grid>
				<Grid item xs={1} onClick={() => this.handleFilter(chapter4.name)} className={classes.filterBox}>
					<img
						src={chapter4.img}
						alt={`${chapter4.name} cardback`}
						className={classes.cover}
						style={
							this.state.chapterFilter !== chapter4.name &&
							this.state.chapterFilter !== '' &&
							this.state.chapterFilter !== null ? (
								{ opacity: 0.1, transition: 'opacity 100ms ease-in' }
							) : null
						}
					/>
				</Grid>
				<Grid item xs={1} onClick={() => this.handleFilter(chapter5.name)} className={classes.filterBox}>
					<img
						src={chapter5.img}
						alt={`${chapter5.name} cardback`}
						className={classes.cover}
						style={
							this.state.chapterFilter !== chapter5.name &&
							this.state.chapterFilter !== '' &&
							this.state.chapterFilter !== null ? (
								{ opacity: 0.1, transition: 'opacity 100ms ease-in' }
							) : null
						}
					/>
				</Grid>
				<Grid item xs={12} style={{ marginBottom: 20 }}>
					<Typography
						align="center"
						className={classes.explainer}
						style={{
							color: 'rgba(255,255,255,1)'
						}}
					>
						(click to filter by chapter)
					</Typography>
				</Grid>
				{/* <Grid item xs={1}>
					<Link to={`/${transition}/help`}>
						<HelpOutlineIcon style={{ color: 'rgba(255,255,255,0.5)', transform: 'translateY(0)' }} />
					</Link>
				</Grid> */}
			</Fragment>
		);

		// let titleMarkup =
		// 	this.state.chapterFilter === null || this.state.chapterFilter === '' ? (
		// 		<span style={{ fontWeight: 900 }}>Explore</span>
		// 	) : (
		// 		<span>
		// 			<span style={{ fontWeight: 900 }}>Explore</span> / {this.state.chapterFilter}
		// 		</span>
		// 	);

		let drawerMarkup = (
			<nav className={classes.drawer}>
				<Hidden xsDown implementation="css">
					<Drawer
						transitionDuration={0}
						variant="persistent"
						anchor="left"
						open={this.state.drawerOpen}
						// onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaper
						}}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}
					>
						<Grid
							container
							item
							xs={12}
							spacing={1}
							justify="center"
							alignItems="flex-start"
							style={{ padding: '0' }}
						>
							<Grid container spacing={1} justify="center" style={{ margin: '0 auto 0 auto' }}>
								<Grid
									container
									item
									xs={12}
									spacing={1}
									justify="center"
									alignItems="stretch"
									style={{
										margin: '7px 10px auto 20px',
										width: '100%'
									}}
								>
									<Grid
										item
										xs={12}
										style={{
											margin: '0 0 15px 0'
										}}
									>
										<Typography
											className={classes.explainer}
											style={{
												color: 'rgba(0,0,0,.5)',
												textAlign: 'left',
												width: 280
											}}
										>
											<span style={{ fontWeight: '600', color: 'rgba(0,0,0,1)' }}>
												Instructions:{' '}
											</span>Get inspired by other people's public responses. Find resources to
											help you bring your wishes to life. Refine your search by filtering by one
											of the five chapters.
										</Typography>
									</Grid>
									<Divider
										style={{
											color: 'rgba(0,0,0,1)',
											width: '120%',
											position: 'absolute',
											top: 86
										}}
									/>
									<ChevronLeftIcon
										style={{ position: 'absolute', top: 10, right: 10, color: '#fff' }}
										onClick={() => this.setState({ drawerOpen: false })}
									/>

									{chapterCardsMarkup}
									<Divider
										style={{
											color: 'rgba(0,0,0,1)',
											width: '120%',
											position: 'absolute',
											top: 180
										}}
									/>
									{!this.props.user.authenticated ? (
										<Paper
											className={classes.paper}
											style={{ marginTop: 20, borderRadius: '10px', border: '2px solid #ccc' }}
										>
											<Typography
												variant="body2"
												align="center"
												style={{ margin: '20px 20px 0 20px' }}
											>
												No profile found, please sign in
											</Typography>
											<div className={classes.buttons}>
												<Button
													variant="contained"
													style={{
														background:
															'linear-gradient(165deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)'
													}}
													component={Link}
													to="/login"
												>
													Log In
												</Button>
												<Button
													variant="contained"
													style={{
														background:
															'linear-gradient(105deg,rgba(15, 135, 151, 1) 0%, #01508d 100%'
													}}
													component={Link}
													to="/signup"
												>
													Sign Up
												</Button>
											</div>
										</Paper>
									) : loading || this.props.user.likes === [] ? null : ( // <NoteSkeleton />
										<Typography
											className={classes.explainer}
											style={{
												color: 'rgba(0,0,0,1)',
												textAlign: 'center',
												width: '100%',
												margin: '10px 0 20px 0'
											}}
										>
											responses you've liked
										</Typography>
									)}
									{likesMarkup}
								</Grid>
							</Grid>
						</Grid>
					</Drawer>
					<Drawer
						transitionDuration={0}
						variant="persistent"
						anchor="left"
						open={!this.state.drawerOpen}
						onClick={() => this.setState({ drawerOpen: true })}
						// onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaperClosed
						}}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}
					>
						<Grid
							container
							item
							xs={12}
							spacing={1}
							justify="center"
							alignItems="flex-start"
							style={{ padding: '0' }}
						>
							<Grid container spacing={1} justify="center" style={{ margin: '0 auto 0 auto' }}>
								<Grid
									container
									item
									xs={12}
									spacing={1}
									justify="center"
									alignItems="stretch"
									style={{
										margin: '10px 0 auto 20px',
										width: '100%'
									}}
								>
									<Grid
										item
										xs={12}
										style={{
											margin: '0 0 10px 0'
										}}
									/>
									<ChevronRightIcon
										color="primary"
										style={{ position: 'absolute', top: 10, right: 15 }}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Drawer>
				</Hidden>
				<Hidden smUp implementation="css">
					<AppBar
						position="fixed"
						color="primary"
						className={classes.drawerResponsive}
						onClick={this.handleDrawerToggle}
						style={this.props.user.authenticated ? null : { top: 0 }}
					>
						<BottomNavigation
							className={classes.root}
							style={{
								background:
									'linear-gradient(165deg,rgba(179, 215, 182, 1) -100%, rgba(15, 135, 151, 1) 100%)'
							}}
						>
							{!this.state.mobileOpen ? (
								<BottomNavigationAction label="FILTER CHAPTERS" showLabel style={{ color: '#fff' }} />
							) : (
								<BottomNavigationAction label="BACK" showLabel style={{ color: '#fff' }} />
							)}
						</BottomNavigation>
					</AppBar>
					<Drawer
						// container={container}
						transitionDuration={0}
						variant="temporary"
						anchor="top"
						open={this.state.mobileOpen}
						onClose={this.handleDrawerToggle}
						classes={{
							paper: classes.drawerPaperMobile
						}}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}
					>
						<Grid
							container
							item
							xs={12}
							spacing={1}
							justify="center"
							alignItems="flex-start"
							style={{ padding: '0 20px 0 20px', height: '80%' }}
						>
							<Grid
								container
								spacing={1}
								justify="center"
								style={{
									// maxWidth: 320,
									margin: '0 20px 0 20px'
								}}
							>
								{/* <Grid
										container
										item
										xs={12}
										spacing={1}
										justify="center"
										alignItems="stretch"
										style={
											this.state.activeChapter === null || this.state.activeChapter === '' ? (
												{ transform: 'translateY(200px)' }
											) : null
										}
									>
										{chapterCardsMarkup}
									</Grid> */}

								<Grid
									container
									item
									xs={12}
									spacing={1}
									justify="center"
									alignItems="stretch"
									style={{
										margin: '20px 20px auto 20px',
										height: '100%'
									}}
								>
									<Grid
										item
										xs={12}
										style={{
											margin: '0 0 10px 0'
										}}
									>
										<Typography
											className={classes.explainer}
											style={{
												color: 'rgba(255,255,255,1)',
												textAlign: 'left'
											}}
										>
											<span style={{ fontWeight: '600', color: 'rgba(255,255,255,1)' }}>
												Instructions:{' '}
											</span>Get inspired by other people's public responses. Refine your search
											by filtering by one of the five chapters.
										</Typography>
									</Grid>
									<Divider />
									{chapterCardsMarkup}
								</Grid>
							</Grid>
						</Grid>
					</Drawer>
				</Hidden>
			</nav>
		);

		return (
			<Fragment>
				<div className={this.state.drawerOpen ? classes.container : classes.containerDrawerMinimized}>
					{drawerMarkup}
					<Grid container justify="center" spacing={0} style={{ maxWidth: '780px', margin: 'auto' }}>
						<Grid container justify="center" spacing={1} style={{ margin: '0 20px' }}>
							{/* <Grid
							item
							xs={12}
							style={{
								margin: '20px auto 0 auto',
								fontSize: '1em',
								fontWeight: '400',
								letterSpacing: '-0.03em'
							}}
						>
							{titleMarkup}
						</Grid> */}
							<Grid item xs={12}>
								<Typography variant="body2" className={classes.header}>
									explore {this.state.chapterFilter ? this.state.chapterFilter : null}
								</Typography>
								<Divider style={{ marginBottom: 20 }} />
							</Grid>
							<Grid item sm={12} md={8}>
								{/* <Typography
								variant="h4"
								className={classes.pageHeader}
								onClick={() => this.setState({ chapterFilter: null })}
							>
								them
							</Typography> */}

								{recentPublicNotesMarkup}
							</Grid>
							<Grid item sm={12} md={4} style={{ transform: 'translateY(-4px)' }}>
								{/* <Typography variant="body2" className={classes.title}>
								Resources
							</Typography>
							<Divider style={{ marginBottom: 20 }} /> */}
								{resourceCardsMarkup}
							</Grid>
						</Grid>
					</Grid>
				</div>
			</Fragment>
		);
	}
}

home.propTypes = {
	getNotes: PropTypes.func.isRequired,
	getAllMyLikes: PropTypes.func.isRequired,
	selectTransition: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	data: state.data,
	user: state.user,
	UI: state.UI
});

export default connect(mapStateToProps, { getNotes, getAllMyLikes, selectTransition })(withStyles(styles)(home));
