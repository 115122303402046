import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import relativeTime from 'dayjs/plugin/relativeTime';
import withStyles from '@material-ui/core/styles/withStyles';
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import PublicIcon from '@material-ui/icons/Public';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LockIcon from '@material-ui/icons/Lock';

const styles = (theme) => ({
	...theme.spreadThis,
	card: {
		position: 'relative',
		display: 'flex',
		minHeight: '100px'
	},
	cover: {
		width: 50,
		height: 50,
		objectFit: 'cover'
	},
	content: {
		padding: '10px 15px 0px 15px',
		marginBottom: -20,
		objectFit: 'cover',
		width: '90%'
	},
	cardHandle: {
		fontSize: '1em'
	},
	cardDate: {
		fontSize: '.7em',
		color: '#aaa',
		marginBottom: 10
	},
	noteBody: {
		margin: '2px auto 10px auto',
		width: '100%',
		border: 'none',
		padding: 0,
		fontSize: '1.4rem',
		letterSpacing: '-0.03em',
		lineHeight: '1.3em',
		fontWeight: '300'
	},
	complete: {
		fontSize: '0.8rem',
		margin: '0 2px 0 0'
	},
	public: {
		fontSize: '0.8rem',
		margin: '0 8px 0 0'
	}
});

class NotecardActive extends Component {
	render () {
		dayjs.extend(relativeTime);
		const { classes, note: { body, createdAt, isPublic, isComplete } } = this.props;

		const isCompleteMarkup = isComplete ? (
			<CheckCircleIcon color="primary" className={classes.complete} />
		) : (
			<CheckCircleOutlineIcon style={{ color: '#aaaaaa' }} className={classes.complete} />
		);
		const isPublicMarkup = isPublic ? (
			<PublicIcon color="primary" className={classes.public} />
		) : (
			<LockIcon color="primary" className={classes.public} />
		);

		return (
			// <Card className={classes.card}>
			// <CardContent className={classes.content}>
			<Fragment>
				<Typography variant="body2" className={classes.noteBody}>
					{this.props.body === '' || this.props.body === undefined ? body : this.props.body}
				</Typography>
				<Typography variant="h5" color="primary" className={classes.cardDate}>
					{isCompleteMarkup}
					{isPublicMarkup}
					{dayjs(createdAt).fromNow()}
				</Typography>
				<Typography variant="body2" color="textSecondary" />
			</Fragment>
			// </CardContent>
			// </Card>
		);
	}
}

NotecardActive.propTypes = {
	user: PropTypes.object.isRequired,
	note: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	openDialog: PropTypes.bool
};
const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps, null)(withStyles(styles)(NotecardActive));
