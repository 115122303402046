import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
// import { postNote, clearErrors } from '../redux/actions/dataActions';
import LikeButton from './LikeButton';
import Comments from './Comments';
import CommentForm from './CommentForm';
import { getNote, clearErrors } from '../../redux/actions/dataActions';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import MyButton from '../../util/MyButton';
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import UnfoldMore from '@material-ui/icons/UnfoldMore';
import ChatIcon from '@material-ui/icons/ChatBubbleOutline';

const styles = (theme) => ({
	...theme.spreadThis,
	profileImage: {
		width: 50,
		height: 50,
		marginTop: 10,
		borderRadius: '50%',
		objectFit: 'cover'
	},
	dialogContent: {
		padding: 20
	},
	closeButton: {
		position: 'absolute',
		right: '0px'
	},
	expandButton: {
		position: 'absolute',
		top: '0px',
		right: '-5px'
	},
	spinnerDiv: {
		textAlign: 'center',
		marginTop: 50,
		marginBottom: 50
	},
	commentSection: {
		letterSpacing: '-.03em'
	},
	handle: {
		letterSpacing: '-.03em'
	},
	noteBody: {
		letterSpacing: '-.03em',
		// fontSize: '1.2em',
		lineHeight: '1.6em',
		marginBotton: 50
	},
	comment: {
		letterSpacing: '-.03em'
	}
});

class NoteDialog extends Component {
	constructor (props) {
		super(props);
		this.state = {
			open: false,
			oldPath: '',
			newPath: ''
		};
	}
	componentDidMount () {
		if (this.props.openDialog) {
			this.handleOpen();
		}
	}
	handleOpen = () => {
		let oldPath = window.location.pathname;
		const { userHandle, noteId } = this.props;
		const newPath = `/eol/users/${userHandle}/note/${noteId}`;
		if (oldPath === newPath) oldPath = `/eol/users/${userHandle}`;
		window.history.pushState(null, null, newPath);
		this.props.getNote(noteId);
		this.setState({ open: true, oldPath, newPath });
	};
	handleClose = () => {
		window.history.pushState(null, null, this.state.oldPath);
		this.setState({ open: false });
		this.props.clearErrors();
	};
	render () {
		const {
			classes,
			note: { noteId, body, createdAt, likeCount, commentCount, userImage, userHandle, comments },
			UI: { loading }
		} = this.props;

		const dialogMarkup = loading ? (
			<div className={classes.spinnerDiv}>
				<CircularProgress size={20} />
			</div>
		) : (
			<Fragment>
				<Grid container spacing={2} justify="center" alignItems="center">
					<Grid item sm={2}>
						<img src={userImage} alt="Profile" className={classes.profileImage} />
					</Grid>
					<Grid item sm={10}>
						<Typography
							className={classes.handle}
							component={Link}
							to={`/eol/users/${userHandle}`}
							color="primary"
							variant="h5"
						>
							{userHandle}
						</Typography>
						<Typography variant="body2" color="textSecondary">
							{dayjs(createdAt).format('h:mm a, MMMM DD YYYY')}
						</Typography>
						<hr className={classes.invisibleSeparator} />
					</Grid>
				</Grid>
				<Grid container spacing={2} justify="center" className={classes.commentSection}>
					<Grid item sm={12}>
						<hr className={classes.visibleSeparator} />
						<Typography variant="body1" className={classes.noteBody}>
							{body}
						</Typography>
						<hr className={classes.invisibleSeparator} style={{ marginBottom: '30px' }} />
						<LikeButton noteId={noteId} />
						<span>{likeCount} likes</span>
						<MyButton tip="comments">
							<ChatIcon color="primary" fontSize="small" />
						</MyButton>
						<span>{commentCount} comments</span>
						<CommentForm noteId={noteId} />
						{/* <hr className={classes.visibleSeparator} /> */}

						<Comments comments={comments} />
						{/* <hr className={classes.invisibleSeparator} /> */}
					</Grid>
				</Grid>
			</Fragment>
		);

		return (
			<Fragment>
				<MyButton onClick={this.handleOpen} tip="expand note" tipClassName={classes.expandButton}>
					<UnfoldMore color="primary" />
				</MyButton>
				<Dialog open={this.state.open} onClose={this.handleClose} fullWidth maxWidth="sm">
					<MyButton tip="close" onClick={this.handleClose} tipClassName={classes.closeButton}>
						<CloseIcon />
					</MyButton>
					<DialogContent className={classes.dialogContent}>{dialogMarkup}</DialogContent>
				</Dialog>
			</Fragment>
		);
	}
}

NoteDialog.propTypes = {
	clearErrors: PropTypes.func.isRequired,
	getNote: PropTypes.func.isRequired,
	noteId: PropTypes.string.isRequired,
	note: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	note: state.data.note,
	UI: state.UI
});

const mapActionsToProps = {
	getNote,
	clearErrors
};

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(NoteDialog));
